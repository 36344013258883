import React from 'react';
import cx from 'classnames';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import ScheduleIcon from '@material-ui/icons/Schedule';
import GroupIcon from '@material-ui/icons/Group';
import SettingsIcon from '@material-ui/icons/Settings';
import { withStyles } from '@material-ui/core/styles';
import {
  teamHeaderNull,
  teamHeaderSoccer,
  teamHeaderBasketball,
  teamTitle,
  teamSport,
} from './styles.module.css';
import sharedStyles from 'sharedStyles/styles.module.css';

const styles = theme => {
  return {
    wrapper: {
      zIndex: 2,
      height: '100%',
    },
    teamHeader: {
      padding: 18,
      height: 94,
      [theme.breakpoints.down('sm')]: {
        padding: 12,
        height: 62,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
    navContainer: {
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 0,
      },
    },
    nav: {
      justifyContent: 'space-around',
      maxWidth: 1200,
      padding: '0 20px',
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    navItem: {
      height: 56,
      color: 'rgba(56, 56, 56, 0.54)',
      [theme.breakpoints.down('sm')]: {
        color: 'rgb(189, 189, 189)',
      },
      '&$navItemSelected': {
        color: 'rgb(56, 56, 56)',
        [theme.breakpoints.down('sm')]: {
          color: theme.palette.primary.main,
        },
      },
    },
    navItemSelected: {},
    bottomNav: {
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1199,
      },
    },
    bottomNavHidden: {
      [theme.breakpoints.down('sm')]: {
        bottom: -56,
      },
    },
  };
};

function Team({
  isManager,
  teamName,
  sport,
  gender,
  onRouteChange,
  selectedValue,
  teamInfo,
  isMobileNav,
  classes,
  children,
}) {
  const manageNavItem = isManager ? (
    <BottomNavigationAction
      label="Settings"
      value="manage"
      classes={{
        root: classes.navItem,
        selected: classes.navItemSelected,
      }}
      icon={<SettingsIcon />}
    />
  ) : null;

  const teamSubtitle =
    gender === 'coed'
      ? `coed ${sport.toLowerCase()}`
      : `${sport.toLowerCase()}`;

  let sportHeader = null;

  switch (sport) {
    case 'Soccer':
      sportHeader = teamHeaderSoccer;
      break;
    case 'Basketball':
      sportHeader = teamHeaderBasketball;
      break;
    default:
      sportHeader = teamHeaderNull;
  }

  return (
    <div className={classes.wrapper}>
      <div className={sportHeader}>
        <div className={cx(classes.teamHeader, sharedStyles.contentWrapper)}>
          <h2 className={teamTitle}>{teamName}</h2>
          <p className={teamSport}>{teamSubtitle}</p>
        </div>
      </div>
      <div className={classes.navContainer}>
        <Paper
          className={cx(classes.bottomNav, {
            [classes.bottomNavHidden]: !isMobileNav,
          })}
          elevation={4}
          square
        >
          <BottomNavigation
            classes={{
              root: classes.nav,
            }}
            showLabels
            value={selectedValue}
            onChange={onRouteChange}
          >
            <BottomNavigationAction
              label="Schedule"
              value="schedule"
              classes={{
                root: classes.navItem,
                selected: classes.navItemSelected,
              }}
              icon={<ScheduleIcon />}
            />
            <BottomNavigationAction
              label="Roster"
              value="roster"
              classes={{
                root: classes.navItem,
                selected: classes.navItemSelected,
              }}
              icon={<GroupIcon />}
            />
            {manageNavItem}
          </BottomNavigation>
        </Paper>
      </div>
      {teamInfo ? children : <LinearProgress mode="indeterminate" />}
    </div>
  );
}

export default withStyles(styles)(Team);
