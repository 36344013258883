import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ToolbarHeader from './ToolbarHeader';

function renderOptions(options) {
  return options.map(option => (
    <MenuItem value={option[0]} key={option[0]}>
      {option[1]}
    </MenuItem>
  ));
}

export default function ToolbarPicker({ title, options, selectProps }) {
  return (
    <div>
      {title ? <ToolbarHeader>{title}</ToolbarHeader> : null}
      {options ? (
        <Select {...selectProps}>{renderOptions(options)}</Select>
      ) : null}
    </div>
  );
}
