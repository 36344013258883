import { firebaseAuth, db } from '../config/constants';

function getFbProvider() {
  const provider = new firebaseAuth.FacebookAuthProvider();
  provider.addScope('public_profile');
  provider.addScope('email');
  // provider.addScope('user_friends');
  return provider;
}

export default function auth() {
  const provider = getFbProvider();
  return firebaseAuth().signInWithPopup(provider);
}

export function linkProvider() {
  const provider = getFbProvider();
  return firebaseAuth().currentUser.linkWithRedirect(provider);
}

export function deleteUser() {
  const user = firebaseAuth().currentUser;
  return user.delete();
}

export function authWithCredential(credential) {
  return firebaseAuth().signInWithCredential(credential);
}

export function logout() {
  return firebaseAuth().signOut();
}

export function getUser(uid) {
  return db
    .doc(`users/${uid}`)
    .get()
    .then(doc => {
      if (doc.exists) {
        return doc.data();
      }
    });
}

export function getUserByPhone(phone) {
  return db
    .doc(`phoneLookup/${phone}`)
    .get()
    .then(doc => {
      if (doc.exists) {
        const myData = doc.data();
        return getUser(myData.uid);
      }
      return null;
    });
}

function formatUserInfo(user) {
  let userInfo = {
    uid: user.uid,
    profileComplete: false,
  };
  // if authed from Facebook
  if (user.providerData && user.providerData.length) {
    // userInfo.avatar = user.providerData[0].photoURL
    userInfo.fbId = user.providerData[0].uid;
  }
  // if UID is a phone number
  if (user.uid.substring(0, 2) === '+1' && user.uid.length === 12) {
    userInfo.phone = user.uid;
  }
  return userInfo;
}

export function saveUser(user) {
  const userInfo = formatUserInfo(user);
  return db
    .collection('users')
    .doc(user.uid)
    .set(userInfo, { merge: true })
    .then(() => userInfo);
}

export function savedProfilePic(uid, filePath) {
  return db
    .collection('users')
    .doc(uid)
    .update({
      profilePic: filePath,
    })
    .then(() => filePath);
}

export function saveInvitedUser(user) {
  const userInfo = Object.assign({}, user, {
    profileComplete: false,
    phone: null,
  });
  var batch = db.batch();
  var userRef = db.doc(`users/${user.uid}`);
  batch.set(userRef, userInfo);
  var phoneLookupRef = db.doc(`phoneLookup/${user.phone}`);
  batch.set(phoneLookupRef, { uid: user.phone });
  return batch.commit();
}
