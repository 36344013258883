import { db } from '../config/constants';

export function saveSeason(values) {
  const seasonRef = db.collection(`teams/${values.teamId}/seasons`);
  return seasonRef
    .add({
      ...values,
      created: Date.now(),
    })
    .then(docRef => {
      return {
        ...values,
        seasonId: docRef.id,
      };
    });
}
