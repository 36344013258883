import React from 'react';
import PropTypes from 'prop-types';
import { vsAt, vs, at, opponentText } from './eventOpponent.module.css';

const EventOpponent = ({ isHome, opponent }) => {
  return (
    <div className={opponentText}>
      <span className={`${vsAt} ${isHome ? vs : at}`}>
        {isHome ? 'VS' : '@'}
      </span>
      <span>{opponent}</span>
    </div>
  );
};

EventOpponent.propTypes = {
  isHome: PropTypes.bool.isRequired,
  opponent: PropTypes.string.isRequired,
};

export default EventOpponent;
