import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { normalizeCode } from '../../../helpers/formNormalize';
import { PrimaryButton, TextButton, TextField } from 'components/base';

// TODO: Do we need PropTypes?
const validate = values => {
  const errors = {};
  if (!values.code) {
    errors.code = 'Required';
  }
  if (values.code && values.code.toString().length !== 5) {
    errors.code = 'Must be 5 digits';
  }
  return errors;
};

function VerifyCode({
  handleSubmit,
  submitFailed,
  submitting,
  valid,
  handleAuth,
  onResendCode,
  isResendingCode,
  onEditPhone,
  phone,
}) {
  return (
    <form onSubmit={handleSubmit(handleAuth)}>
      <Field
        name="code"
        component={TextField}
        label="Verification Code"
        disabled={submitting}
        normalize={normalizeCode}
        type={'tel'}
      />
      <br />
      <PrimaryButton
        style={{ marginRight: 20 }}
        type="submit"
        disabled={submitting || !valid}
      >
        Submit
      </PrimaryButton>
      <TextButton
        onClick={onResendCode}
        disabled={isResendingCode || submitting}
      >
        Resend Verification Code
      </TextButton>
      <TextButton onClick={onEditPhone} disabled={submitting}>
        Edit phone number
      </TextButton>
      <br />
      <p>
        Verification Code sucessfully sent to <b>{phone}</b>. Please enter the
        code received.
      </p>
      {isResendingCode && 'Resending verification code...'}
      {submitFailed &&
        '<p>There was an error sending your request. Please refresh and try again.</p>'}
    </form>
  );
}

VerifyCode.propTypes = {
  phone: PropTypes.string.isRequired,
  onEditPhone: PropTypes.func.isRequired,
  isResendingCode: PropTypes.bool.isRequired,
  onResendCode: PropTypes.func.isRequired,
  handleAuth: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const VerifyCodeRedux = reduxForm({
  form: 'phoneAuth',
  destroyOnUnmount: false,
  validate,
})(VerifyCode);

export default VerifyCodeRedux;
