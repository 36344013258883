import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  eventsWrapper: {
    padding: theme.spacing(2),
    paddingTop: 0,
    backgroundColor: theme.palette.grey[100],
  },
  header: {
    height: 64,
    paddingTop: 3,
    display: 'flex',
  },
  progressBar: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  thead: {
    display: 'table-header-group',
  },
  labelColumn: {
    textAlign: 'left',
  },
  filterColumn: {
    textAlign: 'right',
  },
  toggleButtons: {
    marginLeft: theme.spacing(2),
  },
}));

export default useStyles;
