import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ShortTextIcon from '@material-ui/icons/ShortText';
import { PrimaryButton, PopoverMenu } from 'components/base';
import {
  toolbarSeparator,
  toolbarMenuIcon,
  buttonsLeft,
} from './styles.module.css';
import { hiddenXS } from 'sharedStyles/styles.module.css';

export default function ToolbarButtons({
  buttonText,
  buttonProps,
  menuItems,
  alignRight,
}) {
  return (
    <div className={alignRight ? null : buttonsLeft}>
      {buttonText ? (
        <PrimaryButton className={hiddenXS} {...buttonProps}>
          {buttonText}
        </PrimaryButton>
      ) : null}
      {buttonText && menuItems ? (
        <div className={`${toolbarSeparator} ${hiddenXS}`} />
      ) : null}
      {menuItems ? (
        <PopoverMenu menuItems={menuItems}>
          <IconButton className={toolbarMenuIcon}>
            <ShortTextIcon />
          </IconButton>
        </PopoverMenu>
      ) : null}
    </div>
  );
}
