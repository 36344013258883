import React from 'react';
import PropTypes from 'prop-types';
import MUIAvatar from '@material-ui/core/Avatar';

const getFontSize = size => {
  const fs = ((size / 40) * 1.25).toFixed(4);
  return `${fs}rem`;
};

export default function Avatar({ style, size, ...restOfProps }) {
  const styles = {
    ...style,
    height: size,
    width: size,
    fontSize: getFontSize(size),
  };
  return <MUIAvatar style={styles} {...restOfProps} />;
}

Avatar.propTypes = {
  size: PropTypes.number,
  src: PropTypes.string,
};
