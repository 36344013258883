import React from 'react';
import { TeamCreateContainer } from 'containers';
import sharedStyles from 'sharedStyles/styles.module.css';

class CreateTeamRoute extends React.Component {
  render() {
    return (
      <div className={`${sharedStyles.contentWrapper}`}>
        <TeamCreateContainer history={this.props.history} />
      </div>
    );
  }
}

export default CreateTeamRoute;
