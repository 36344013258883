import { getLatLong } from 'helpers/googleMaps';
import { saveUserLocation, getUserLatLong } from 'helpers/geoFire';

const GEOCODING_LOCATION = 'GEOCODING_LOCATION';
const GEOCODING_LOCATION_FAILURE = 'GEOCODING_LOCATION_FAILURE';
const GEOCODING_LOCATION_SUCCESS = 'GEOCODING_LOCATION_SUCCESS';

const FETCHING_USER_LOCATION = 'FETCHING_USER_LOCATION';
const FETCHING_USER_LOCATION_FAILURE = 'FETCHING_USER_LOCATION_FAILURE';
const FETCHING_USER_LOCATION_SUCCESS = 'FETCHING_USER_LOCATION_SUCCESS';

const SETTING_USER_LOCATION = 'SETTING_USER_LOCATION';
const SETTING_USER_LOCATION_FAILURE = 'SETTING_USER_LOCATION_FAILURE';
const SETTING_USER_LOCATION_SUCCESS = 'SETTING_USER_LOCATION_SUCCESS';

function geocodingLocation() {
  return {
    type: GEOCODING_LOCATION,
  };
}

function geocodingLocationFailure(error) {
  return {
    type: GEOCODING_LOCATION_FAILURE,
    error: 'Error geocoding location.',
  };
}

function geocodingLocationSuccess() {
  return {
    type: GEOCODING_LOCATION_SUCCESS,
  };
}

export function geocodeLocation(address) {
  return function(dispatch) {
    dispatch(geocodingLocation());
    return getLatLong(address)
      .then(location => {
        dispatch(geocodingLocationSuccess());
        return location;
      })
      .catch(error => dispatch(geocodingLocationFailure(error)));
  };
}

function fetchingUserLocation() {
  return {
    type: FETCHING_USER_LOCATION,
  };
}

function fetchingUserLocationSuccess({ uid, location }) {
  return {
    type: FETCHING_USER_LOCATION_SUCCESS,
    uid,
    location,
  };
}

export function fetchUserLocation() {
  return function(dispatch) {
    dispatch(fetchingUserLocation());
    return getUserLatLong()
      .then(location => {
        dispatch(fetchingUserLocationSuccess(location));
      })
      .catch(error => fetchingUserLocationSuccess(error));
  };
}

function settingUserLocation() {
  return {
    type: SETTING_USER_LOCATION,
  };
}

function settingUserLocationFailure(error) {
  return {
    type: SETTING_USER_LOCATION_FAILURE,
    error: 'Failed to save location with geoFire.',
  };
}

function settingUserLocationSuccess({ uid, location }) {
  return {
    type: SETTING_USER_LOCATION_SUCCESS,
    uid,
    location,
  };
}

export function setUserLocation(location) {
  return function(dispatch) {
    dispatch(settingUserLocation());
    return saveUserLocation(location)
      .then(response => dispatch(settingUserLocationSuccess(response)))
      .catch(error => dispatch(settingUserLocationFailure(error)));
  };
}

const initialUsersState = {
  isSetting: false,
  isFetching: false,
  error: '',
};

function geoFireUsers(state = initialUsersState, action) {
  switch (action.type) {
    case SETTING_USER_LOCATION:
      return {
        ...state,
        isSetting: true,
      };
    case FETCHING_USER_LOCATION:
      return {
        ...state,
        isFetching: true,
      };
    case SETTING_USER_LOCATION_FAILURE:
    case FETCHING_USER_LOCATION_FAILURE:
      return {
        ...state,
        isSetting: false,
        isFetching: false,
        error: action.error,
      };

    case SETTING_USER_LOCATION_SUCCESS:
    case FETCHING_USER_LOCATION_SUCCESS:
      return {
        ...state,
        isSetting: false,
        isFetching: false,
        [action.uid]: action.location,
      };
    default:
      return state;
  }
}

const initialState = {
  users: initialUsersState,
  isGeocodingLocation: false,
  error: '',
};

export default function geoFire(state = initialState, action) {
  switch (action.type) {
    case GEOCODING_LOCATION:
      return {
        ...state,
        isGeocodingLocation: true,
      };
    case GEOCODING_LOCATION_FAILURE:
      return {
        ...state,
        isGeocodingLocation: false,
        error: action.error,
      };
    case GEOCODING_LOCATION_SUCCESS:
      return {
        ...state,
        isGeocodingLocation: false,
        error: '',
      };
    case SETTING_USER_LOCATION:
    case SETTING_USER_LOCATION_FAILURE:
    case SETTING_USER_LOCATION_SUCCESS:
    case FETCHING_USER_LOCATION:
    case FETCHING_USER_LOCATION_FAILURE:
    case FETCHING_USER_LOCATION_SUCCESS:
      return {
        ...state,
        users: geoFireUsers(state.users, action),
      };
    default:
      return state;
  }
}
