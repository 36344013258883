import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  eventTime: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    minHeight: 50,
    lineHeight: '1.5rem',
    opacity: 0.6,
    fontSize: 15,
  },
  thisWeek: {
    opacity: 1,
  },
});

const EventTime = ({ classes, startMoment, isThisWeek }) => {
  const dateHeadline = startMoment.calendar(null, {
    sameDay: function(now) {
      if (this.isBefore(now)) {
        return 'M/D';
      } else {
        return '[Today]';
      }
    },
    nextDay: '[Tomorrow]',
    nextWeek: 'ddd, M/D',
    lastDay: 'M/D',
    lastWeek: 'M/D',
    sameElse: function(now) {
      if (this.isBefore(now)) {
        return 'M/D';
      } else {
        return 'MMM Do';
      }
    },
  });
  const timeHeadline = startMoment.isBefore()
    ? null
    : startMoment.format('h:mm A');
  return (
    <div
      className={cx(classes.eventTime, {
        [classes.thisWeek]: isThisWeek,
      })}
    >
      <div>{dateHeadline}</div>
      <div>{timeHeadline}</div>
    </div>
  );
};

EventTime.propTypes = {
  startMoment: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventTime);
