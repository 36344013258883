import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';

const PublicRoute = ({ component: Component, isAuthed, ...rest }) => {
  const redirect = get(rest, 'location.state.from');
  return (
    <Route
      {...rest}
      render={props =>
        !isAuthed ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to={redirect ? redirect : '/feed'} />
        )
      }
    />
  );
};

export default connect(({ users: { isAuthed } }, ownProps) => {
  return {
    isAuthed,
  };
})(PublicRoute);
