import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ManageTeam } from 'components';
import { setNavItem } from 'redux/modules/usersTeams';

class ManageTeamContainer extends React.Component {
  constructor(props) {
    super(props);
    const paths = props.pathname.split('/');
    let selectedIndex;
    switch (paths[3]) {
      case 'seasons':
        selectedIndex = 1;
        break;
      case 'roster':
        selectedIndex = 2;
        break;
      case 'delete':
        selectedIndex = 3;
        break;
      default:
        selectedIndex = 0;
    }

    this.state = {
      selectedIndex,
    };

    this.handleSubNavClick = this.handleSubNavClick.bind(this);
  }
  componentDidMount() {
    this.props.setNavItem('manage');
  }
  handleSubNavClick(route, selectedIndex) {
    this.props.history.push(`/${this.props.teamNameStr}/manage${route}`);
    this.setState({ selectedIndex });
  }
  render() {
    return (
      <ManageTeam
        {...this.props}
        onSubNavClick={this.handleSubNavClick}
        selectedIndex={this.state.selectedIndex}
      />
    );
  }
}

ManageTeamContainer.propTypes = {
  teamId: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  teamNameStr: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  setNavItem: PropTypes.func.isRequired,
  isXS: PropTypes.bool.isRequired,
};

function mapStateToProps(
  { users, viewport: { width } },
  { teamId, teamName, teamNameStr, location: { pathname } }
) {
  return {
    teamId,
    teamName,
    teamNameStr,
    pathname,
    uid: users.authedId,
    isXS: width < 667,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setNavItem,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageTeamContainer);
