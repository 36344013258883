import { db, FieldValue } from '../config/constants';

function formatMinMax(gender, players, malePlayers, femalePlayers) {
  if (gender === 'coed') {
    const [minMale, maxMale] = malePlayers.split(',');
    const [minFemale, maxFemale] = femalePlayers.split(',');
    return {
      minMale,
      maxMale,
      minFemale,
      maxFemale,
    };
  } else {
    const [minPlayer, maxPlayer] = players.split(',');
    return {
      minPlayer,
      maxPlayer,
    };
  }
}

const removeOldMinMax = gender =>
  gender === 'coed'
    ? {
        minPlayer: FieldValue.delete(),
        maxPlayer: FieldValue.delete(),
      }
    : {
        minMale: FieldValue.delete(),
        maxMale: FieldValue.delete(),
        minFemale: FieldValue.delete(),
        maxFemale: FieldValue.delete(),
      };

export function fbUpdateTeam(
  { players, femalePlayers, malePlayers, ...values },
  teamId
) {
  const data = {
    ...formatMinMax(values.gender, players, malePlayers, femalePlayers),
    ...values,
  };
  return db
    .collection('teams')
    .doc(teamId)
    .set({ ...data, ...removeOldMinMax(values.gender) }, { merge: true })
    .then(() => data);
}

export const fbUpdateSeason = (teamId, seasonId, values) =>
  db.doc(`teams/${teamId}/seasons/${seasonId}`).update(values);

export const fbDeleteSeason = (teamId, seasonId) =>
  db.doc(`teams/${teamId}/seasons/${seasonId}`).delete();
