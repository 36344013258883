import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const styles = theme => {
  return {
    root: {
      display: 'inline-flex',
      boxShadow: theme.shadows[2],
    },
    button: {
      textTransform: 'none',
      height: 32,
      color: theme.palette.text.secondary,
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
      '&$buttonPrimary': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
      '&$buttonPrimary&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    buttonPrimary: {},
  };
};

const ToggleFields = ({
  classes,
  className,
  options,
  isPrimary,
  ...restOfProps
}) => {
  const toggleOptions = options.map(({ value, label }) => (
    <ToggleButton
      value={value}
      key={value}
      classes={{
        root: classes.button,
        selected: cx({
          [classes.buttonPrimary]: isPrimary,
        }),
      }}
    >
      {label}
    </ToggleButton>
  ));
  return (
    <ToggleButtonGroup className={cx(classes.root, className)} {...restOfProps}>
      {toggleOptions}
    </ToggleButtonGroup>
  );
};

ToggleFields.propTypes = {
  classes: PropTypes.object.isRequired,
};

const toggleFields = withStyles(styles)(ToggleFields);

toggleFields.displayName = 'ToggleField';

export default toggleFields;
