import React from 'react';
import {
  eventUniformColor,
  eventUniformColorText,
  eventUniformColorBlock,
} from './styles.module.css';

const ColorBlock = ({ text, color }) => (
  <div className={eventUniformColor}>
    <span className={eventUniformColorText}>{text}</span>
    <span
      style={{
        backgroundColor: color,
        borderColor: `${color === '#ffffff' && '#999999'}`,
      }}
      className={eventUniformColorBlock}
    ></span>
  </div>
);

export default ColorBlock;
