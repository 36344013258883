import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Authenticate } from 'components';
import * as userActionCreators from 'redux/modules/users';

class AuthenticateContainer extends React.Component {
  handleAuth(e) {
    e.preventDefault();
    this.props.fetchUser();
  }
  render() {
    return (
      <div>
        <Authenticate
          isFetching={this.props.isFetching}
          error={this.props.error}
          onAuth={this.handleAuth.bind(this)}
        />
      </div>
    );
  }
}

AuthenticateContainer.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  fetchUser: PropTypes.func.isRequired,
};

function mapStateToProps({ users }) {
  return {
    isFetching: users.isFetching,
    error: users.error,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(userActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticateContainer);
