import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    marginBottom: 192,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 56,
    },
  },
});

// TODO: Do we need PropTypes?
function Alert({
  open,
  message,
  handleRequestClose,
  hideDuration = 4000,
  anchorVertical = 'bottom',
  anchorHorizontal = 'left',
  ...restOfProps
}) {
  return (
    <Snackbar
      open={open}
      message={message}
      autoHideDuration={hideDuration}
      onClose={handleRequestClose}
      anchorOrigin={{
        vertical: anchorVertical,
        horizontal: anchorHorizontal,
      }}
      {...restOfProps}
    />
  );
}

Alert.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
  handleRequestClose: PropTypes.func,
};

export default withStyles(styles)(Alert);
