import React from 'react';
import { connect } from 'react-redux';
import { filter, get } from 'lodash';
import moment from 'moment';
import { Attendees } from '../../../components';

class AttendeesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 'playing',
      players: props.playing,
    };
  }
  shouldComponentUpdate(nextProps, nextState) {
    const { status, players } = this.state;
    if (nextProps[status] && players.length !== nextProps[status].length) {
      this.setState({
        status: nextState.status,
        players: nextProps[nextState.status],
      });
    }
    return true;
  }
  handleChangeStatus = (_event, status) => {
    if (status === null) {
      return;
    }
    this.setState({
      status,
      players: this.props[status],
    });
  };
  render() {
    if (!this.props.startMoment) {
      return null;
    }
    return (
      <Attendees
        status={this.state.status}
        players={this.state.players}
        isCoed={this.props.isCoed}
        onChangeStatus={this.handleChangeStatus}
        isMobile={this.props.isMobile}
      />
    );
  }
}

function mapStateToProps({ events, rosters, teams, viewport }, ownProps) {
  let active = [];

  const event = events[ownProps.eventId] ? events[ownProps.eventId] : {};

  const { timestamp, RSVPs = [] } = event;

  let startMoment, isUpcoming;
  if (timestamp) {
    startMoment = moment(timestamp);
    const future = new Date();
    future.setDate(future.getDate() + 6);
    isUpcoming = startMoment.isBefore(future);
  }

  if (rosters[ownProps.teamId]) {
    if (rosters[ownProps.teamId].active) {
      active = rosters[ownProps.teamId].active;
    }
  }

  const RSVPed = RSVPs.map(player => player.uid);

  const playing = filter(RSVPs, player => player.rsvp === 1);
  const notPlaying = filter(RSVPs, player => player.rsvp === 0);
  const invited = isUpcoming
    ? filter(active, player => !RSVPed.includes(player.uid))
    : [];

  return {
    startMoment,
    playing,
    notPlaying,
    invited,
    isMobile: viewport.deviceSize === 'xs',
    isCoed: get(teams[ownProps.teamId] || {}, 'gender', null) === 'coed',
  };
}

export default connect(mapStateToProps)(AttendeesContainer);
