import { addPlayerToTeam, deletePlayerFromTeam } from 'helpers/api';
import { updatePlayerType } from 'helpers/apiRoster';

const ADDING_PLAYER = 'ADDING_PLAYER';
const ADDING_PLAYER_FAILURE = 'ADDING_PLAYER_FAILURE';
const ADDING_PLAYER_SUCCESS = 'ADDING_PLAYER_SUCCESS';

const UPDATING_PLAYERS = 'UPDATING_PLAYERS';

const DELETING_PLAYER = 'DELETING_PLAYER';
const DELETING_PLAYER_FAILURE = 'DELETING_PLAYER_FAILURE';
const DELETING_PLAYER_SUCCESS = 'DELETING_PLAYER_SUCCESS';

const UPDATING_PLAYER_STATUS = 'UPDATING_PLAYER_STATUS';
const UPDATING_PLAYER_STATUS_FAILURE = 'UPDATING_PLAYER_STATUS_FAILURE';
const UPDATING_PLAYER_STATUS_SUCCESS = 'UPDATING_PLAYER_STATUS_SUCCESS';

function addingPlayer() {
  return {
    type: ADDING_PLAYER,
  };
}

function addingPlayerFailure(error) {
  return {
    type: ADDING_PLAYER_FAILURE,
    error: 'Error adding player.',
  };
}

function addingPlayerSuccess() {
  return {
    type: ADDING_PLAYER_SUCCESS,
  };
}

function updatingPlayers(teamId, active, reserve, invites, lastUpdated) {
  return {
    type: UPDATING_PLAYERS,
    teamId,
    active,
    invites,
    reserve,
    lastUpdated,
  };
}

function deletingPlayer() {
  return {
    type: DELETING_PLAYER,
  };
}

function deletingPlayerFailure(error) {
  return {
    type: DELETING_PLAYER_FAILURE,
    error: 'Error deleting player.',
  };
}

function deletingPlayerSuccess() {
  return {
    type: DELETING_PLAYER_SUCCESS,
  };
}

export function addPlayer(teamId, player, status, teamData) {
  return function(dispatch) {
    dispatch(addingPlayer());
    return addPlayerToTeam(teamId, player, status, teamData)
      .then(() => {
        dispatch(addingPlayerSuccess());
      })
      .catch(error => {
        console.log('error', error);
        return dispatch(addingPlayerFailure(error));
      });
  };
}

function updatingPlayerStatus() {
  return {
    type: UPDATING_PLAYER_STATUS,
  };
}

function updatingPlayerStatusSuccess() {
  return {
    type: UPDATING_PLAYER_STATUS_SUCCESS,
  };
}

function updatingPlayerStatusFailure(error) {
  return {
    type: UPDATING_PLAYER_STATUS_FAILURE,
    error,
  };
}

export function updatePlayerStatus(teamId, uid, type) {
  return function(dispatch) {
    dispatch(updatingPlayerStatus());
    return updatePlayerType(teamId, uid, type)
      .then(() => dispatch(updatingPlayerStatusSuccess()))
      .catch(err => dispatch(updatingPlayerStatusFailure(err)));
  };
}

export function updatePlayers(teamId, players, lastUpdated) {
  return function(dispatch) {
    var active = [];
    var reserve = [];
    var invites = [];
    players.forEach(player => {
      switch (player.type) {
        case 'active':
          active.push(player);
          break;
        case 'reserve':
          reserve.push(player);
          break;
        case 'invite':
          invites.push(player);
          break;
        default:
          break;
      }
    });
    dispatch(updatingPlayers(teamId, active, reserve, invites, lastUpdated));
  };
}

export function deletePlayer(teamId, uid) {
  return function(dispatch) {
    dispatch(deletingPlayer());
    return deletePlayerFromTeam(teamId, uid)
      .then(() => dispatch(deletingPlayerSuccess()))
      .catch(error => {
        console.log('error', error);
        return dispatch(deletingPlayerFailure(error));
      });
  };
}

const initialRosterState = {
  active: [],
  reserve: [],
  invites: [],
  lastUpdated: 0,
};

function roster(state = initialRosterState, action) {
  switch (action.type) {
    case UPDATING_PLAYERS:
      return {
        ...state,
        active: action.active,
        reserve: action.reserve,
        invites: action.invites,
        lastUpdated: action.lastUpdated,
      };
    default:
      return state;
  }
}

const initialState = {
  error: '',
  addingPlayer: false,
  deletingPlayer: false,
};

export default function rosters(state = initialState, action) {
  switch (action.type) {
    case ADDING_PLAYER:
      return {
        ...state,
        addingPlayer: true,
      };
    case ADDING_PLAYER_FAILURE:
      return {
        ...state,
        error: action.error,
        addingPlayer: false,
      };
    case ADDING_PLAYER_SUCCESS:
      return {
        ...state,
        error: '',
        addingPlayer: false,
      };
    case DELETING_PLAYER:
      return {
        ...state,
        deletingPlayer: true,
      };
    case DELETING_PLAYER_FAILURE:
      return {
        ...state,
        error: action.error,
        deletingPlayer: false,
      };
    case DELETING_PLAYER_SUCCESS:
      return {
        ...state,
        error: '',
        deletingPlayer: false,
      };
    case UPDATING_PLAYERS:
      return {
        ...state,
        [action.teamId]: roster(state[action.teamId], action),
      };
    default:
      return state;
  }
}
