import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import cx from 'classnames';

const styles = theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    paddingBottom: 20,
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  inputLabel: {
    '&$cssFocused': {
      color: theme.palette.primary.main,
    },
    '&$cssError': {
      color: theme.palette.error.main,
    },
  },
  helperText: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
    position: 'absolute',
    bottom: 0,
  },
  cssFocused: {},
  cssError: {},
});

class RadioButtonsGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.meta.initial || props.initialValue,
    };
  }

  handleChange = event => {
    this.setState({ value: event.target.value });
    this.props.input && this.props.input.onChange(event);
  };

  render() {
    const {
      classes,
      label,
      input,
      options,
      disabled,
      className,
      required,
      helperText,
      meta: { touched, error },
    } = this.props;

    const isError = touched && error && error.length > 0;

    const radioButtons = options.map(({ value, label, ...restOfProps }) => (
      <FormControlLabel
        key={value}
        value={value}
        label={label}
        control={<Radio disabled />}
        {...restOfProps}
        disabled={disabled}
      />
    ));

    return (
      <FormControl
        component="fieldset"
        disabled={disabled}
        className={cx(classes.root, className)}
      >
        <FormLabel
          component="legend"
          error={isError}
          classes={{
            root: classes.inputLabel,
            focused: classes.cssFocused,
            error: classes.cssError,
          }}
          required={required}
        >
          {label}
        </FormLabel>
        <RadioGroup
          {...input}
          aria-label={label}
          className={classes.group}
          value={this.state.value || ''}
          onChange={this.handleChange}
        >
          {radioButtons}
        </RadioGroup>
        {helperText || isError ? (
          <FormHelperText error={isError} className={classes.helperText}>
            {isError ? error : helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    );
  }
}

RadioButtonsGroup.propTypes = {
  classes: PropTypes.object.isRequired,
};

const radioButtons = withStyles(styles)(RadioButtonsGroup);

radioButtons.defaultProps = {
  meta: {},
};

radioButtons.propTypes = {
  /** Label shown above radio inputs */
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  initialValue: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired,
      ]),
    })
  ).isRequired,
  input: PropTypes.object,
  meta: PropTypes.object,
};

radioButtons.displayName = 'RadioButtons';
export default radioButtons;
