import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { debounce } from 'lodash';
import { Footer } from 'components';
import {
  NavigationContainer,
  ProfileSetupContainer,
  ProfilePhoneContainer,
  AlertContainer,
  TeamContainer,
  HomeContainer,
} from 'containers';
import * as userActionCreators from 'redux/modules/users';
import { fetchUsersTeams } from 'redux/modules/usersTeams';
import { updatingViewport } from 'redux/modules/viewport';
import { firebaseAuth } from 'config/constants';
import styles from 'sharedStyles/styles.module.css';

import {
  CreateTeamRoute,
  ProfileEditRoute,
  FeedRoute,
  AuthRoute,
  PrivateRoute,
  PublicRoute,
} from 'routes';

class MainContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isReAuthing: false,
    };
  }
  handleProviderInfo() {
    if (this.accessToken && this.props.userFbId) {
      // save access token
      this.props.saveAccessToken(this.accessToken);
      // if profile is incomplete, fetch provider info
      if (!this.props.isProfileComplete) {
        this.props.fetchProviderInfo(this.props.userFbId, this.accessToken);
      }
      // if we don't have a profile pic, fetch it from Facebook
      if (!this.props.profilePic.length) {
        this.props.fetchProfilePicture(
          this.props.userFbId,
          this.accessToken,
          this.props.authedId
        );
      }
    }
  }
  handleReauth() {
    // If FB credentials are in use on another user
    // and we have the users phone, let's merge the
    // phone user into the FB user
    if (this.credential && this.props.userPhone) {
      // save credential to variable and reset this.credential
      // to null, so function doesn't repeat
      const credential = this.credential;
      this.credential = null;
      this.setState({
        isReAuthing: true,
      });
      this.props.handleReauth(credential, this.props.userPhone).then(() => {
        this.setState({
          isReAuthing: false,
        });
      });
    }
  }
  componentDidMount() {
    firebaseAuth()
      .getRedirectResult()
      .then(({ credential, user }) => {
        this.accessToken =
          credential && credential.accessToken ? credential.accessToken : null;
        this.handleProviderInfo();
        return;
      })
      .catch(error => {
        if (error.code === 'auth/credential-already-in-use') {
          this.credential = error.credential;
          this.handleReauth();
        }
      });
    /**
     * When Firebase detects an auth change, it will call
     * this function. We use this to keep the user logged in
     * when the refresh the page or a redirect occurs.
     **/
    firebaseAuth().onAuthStateChanged(user => {
      if (user && user.uid) {
        this.props.handleAuthedUser(user, Date.now()).then(() => {
          this.handleProviderInfo();
          this.handleReauth();
        });
        this.props.fetchUsersTeams(user.uid);
      } else {
        this.props.removeFetchingUser();
      }
    });
    /**
     * Handle window resize for viewport modal
     */
    window.addEventListener(
      'resize',
      debounce(this.props.updatingViewport, 300)
    );
  }
  render() {
    let {
      isAuthed,
      isPhoneSaved,
      isProfileComplete,
      children,
      isFetching,
      screen,
      navItem,
    } = this.props;

    let pathStyles;
    if (
      navItem === 'schedule' ||
      navItem === 'roster' ||
      navItem === 'manage'
    ) {
      pathStyles = styles.greyBackground;
    }
    if (isAuthed && !isPhoneSaved) {
      screen = <ProfilePhoneContainer />;
    } else if (isAuthed && !isProfileComplete) {
      screen = <ProfileSetupContainer />;
    } else {
      screen = (
        <Switch>
          <PublicRoute exact path="/" component={HomeContainer} />
          <PublicRoute exact path="/auth" component={AuthRoute} />
          <PrivateRoute
            exact
            path="/feed"
            component={FeedRoute}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/profileEdit"
            component={ProfileEditRoute}
          />
          <PrivateRoute
            exact
            path="/createTeam"
            component={CreateTeamRoute}
            {...this.props}
          />
          <PrivateRoute
            path="/:teamName"
            component={TeamContainer}
            {...this.props}
          />
        </Switch>
      );
    }
    return isFetching === true || this.state.isReAuthing === true ? null : (
      <div className={`${styles.site} ${pathStyles}`}>
        <Router onUpdate={() => window.scrollTo(0, 0)}>
          <NavigationContainer {...this.props} />
          <div className={styles.siteContent}>
            <Paper className={styles.innerWrapper} square>
              {screen}
              {children}
            </Paper>
          </div>
          <Footer />
          <AlertContainer />
        </Router>
      </div>
    );
  }
}

MainContainer.propTypes = {
  isAuthed: PropTypes.bool.isRequired,
  authedId: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  isProfileComplete: PropTypes.bool,
  profilePic: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  userFbId: PropTypes.string,
  fetchingUserSuccess: PropTypes.func.isRequired,
  removeFetchingUser: PropTypes.func.isRequired,
  fetchProviderInfo: PropTypes.func.isRequired,
  fetchProfilePicture: PropTypes.func.isRequired,
  saveAccessToken: PropTypes.func.isRequired,
  handleAuthedUser: PropTypes.func.isRequired,
  handleReauth: PropTypes.func.isRequired,
  fetchUsersTeams: PropTypes.func.isRequired,
  updatingViewport: PropTypes.func.isRequired,
};

export default connect(
  ({ users, usersTeams }) => ({
    isAuthed: users.isAuthed,
    authedId: users.authedId,
    isFetching: users.isFetching,
    isProfileComplete: users.authedId
      ? users[users.authedId].info.profileComplete
      : null,
    isPhoneSaved: users.authedId ? users[users.authedId].info.phone : null,
    userFbId: users.authedId ? users[users.authedId].info.fbId : null,
    userPhone: users.authedId ? users[users.authedId].info.phone : null,
    profilePic: users.authedId ? users[users.authedId].info.profilePic : false,
    navItem: usersTeams.navItem,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...userActionCreators,
        fetchUsersTeams,
        updatingViewport,
      },
      dispatch
    )
)(MainContainer);
