const validate = values => {
  const errors = {};
  if (!values.opponent) {
    errors.opponent = 'Required';
  }
  if (!values.dateTime) {
    errors.dateTime = 'Required';
  }
  if (!values.location) {
    errors.location = 'Required';
  }
  if (!values.locationId) {
    errors.locationId = 'Required';
  }
  if (!values.isHome) {
    errors.isHome = 'Required';
  }
  if (!values.seasonId) {
    errors.seasonId = 'Required';
  }
  return errors;
};

export default validate;
