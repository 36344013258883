import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import deepOrange from '@material-ui/core/colors/deepOrange';
import orange from '@material-ui/core/colors/orange';
import purple from '@material-ui/core/colors/purple';
import pink from '@material-ui/core/colors/pink';

const styles = {
  orangeAvatar: {
    color: orange[200],
    backgroundColor: pink[400],
  },
  purpleAvatar: {
    color: deepOrange[300],
    backgroundColor: purple[500],
  },
};

function PlayerListItem({ src, firstName, lastName, avatarColor, classes }) {
  return (
    <ListItem button>
      <ListItemAvatar>
        <Avatar
          src={src}
          alt={`${firstName} ${lastName}`}
          className={cx(classes.avatar, {
            [classes.orangeAvatar]: avatarColor === 'orange',
            [classes.purpleAvatar]: avatarColor === 'purple',
          })}
        >
          {!src && `${firstName.charAt(0)}${lastName.charAt(0)}`}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={`${firstName} ${lastName}`} />
    </ListItem>
  );
}

PlayerListItem.propTypes = {
  src: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  avatarColor: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PlayerListItem);
