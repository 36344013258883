import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert } from 'components/base';
import { closeAlert } from 'redux/modules/alerts';

class AlertContainer extends React.Component {
  handleRequestClose() {
    this.props.closeAlert();
  }
  render() {
    return (
      <Alert
        open={this.props.open}
        message={this.props.message}
        handleRequestClose={this.handleRequestClose.bind(this)}
      />
    );
  }
}

AlertContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  closeAlert: PropTypes.func.isRequired,
};

function mapStateToProps({ alerts }) {
  return {
    open: alerts.open,
    message: alerts.message,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeAlert,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertContainer);
