import React from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/AccountCircle';
import { useHistory } from 'react-router';
import { Avatar } from 'components/base';
import { TeamToggleContainer } from 'containers';

import {
  container,
  navContainer,
  homeLink,
  drawerHeader,
  drawerHeaderName,
  navMenuContainer,
  menuIcon,
  closeMenuIcon,
} from './styles.module.css';

export default function AuthedNavigation({
  onLogout,
  onToggle,
  isOpen = false,
  firstName,
  profilePic,
}) {
  let history = useHistory();
  return (
    <Paper className={container}>
      <nav className={navContainer}>
        <Link className={homeLink} to="/feed">
          {'Home'}
        </Link>
        <div className={navMenuContainer}>
          <TeamToggleContainer />
          <IconButton onClick={onToggle}>
            <MenuIcon className={menuIcon} />
          </IconButton>
        </div>
      </nav>
      <Drawer open={isOpen} anchor="right" onClose={() => onToggle()}>
        <div style={{ width: 300 }}>
          <div className={drawerHeader}>
            <div className={drawerHeaderName}>
              {profilePic ? (
                <Avatar
                  src={profilePic}
                  style={{ marginRight: 8, border: '1px solid #fff' }}
                />
              ) : (
                <PersonIcon
                  style={{
                    height: 36,
                    width: 36,
                    marginRight: 8,
                  }}
                />
              )}
              {firstName}
            </div>
            <IconButton onClick={onToggle} className={closeMenuIcon}>
              <CloseIcon style={{ color: '#FFF' }} />
            </IconButton>
          </div>
          <MenuItem
            onClick={() => {
              history.push('/profileEdit');
              onToggle();
            }}
          >
            Edit Profile
          </MenuItem>
          <MenuItem onClick={() => onLogout().then(() => history.push('/'))}>
            Logout
          </MenuItem>
        </div>
      </Drawer>
    </Paper>
  );
}
