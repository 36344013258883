import isEmail from 'sane-email-validation';
import isValidZip from 'is-valid-zip';

// TODO: Swap out is-valid-zip. Package has vulnerability.

const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (!values.lastName) {
    errors.lastName = 'Required';
  }
  if (!values.gender) {
    errors.gender = 'Required';
  }
  if (!values.email) {
    errors.email = 'Required';
  }
  if (values.email && !isEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.zip) {
    errors.zip = 'Required';
  }
  if (values.zip && !isValidZip(values.zip)) {
    errors.zip = 'Invalid Zip Code';
  }
  return errors;
};

export default validate;
