import React from 'react';
import { Title } from 'components/base';
import { InviteFormContainer, InvitedContainer } from 'containers';
import { formWrapper, invitedWrapper } from './invite.module.css';
import sharedStyles from 'sharedStyles/styles.module.css';

export default function Invite({ expanded, handleExpand, teamId, isManager }) {
  const subtitle = isManager
    ? 'Add an active or reserve player.'
    : 'Add a player to the reserves list.';
  const cardMsg = isManager
    ? "Reserve players will be invited to games when there isn't enough active players. Active players will be invited to all games. Invites will be received via SMS."
    : "Reserve players will be invited to games when there isn't enough active players. The team manager can move them to the active list after they've joined the team. Invites will be received via SMS.";
  return (
    <div className={`${sharedStyles.contentWrapper}`}>
      <Title title="Invite Players" subtitle={subtitle} />
      <p>{cardMsg}</p>
      <div className={formWrapper}>
        <InviteFormContainer teamId={teamId} />
        <div className={invitedWrapper}>
          <InvitedContainer teamId={teamId} />
        </div>
      </div>
    </div>
  );
}
