import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import { WhiteButton } from 'components/base';
import { stringToURL } from 'helpers/strings';

export default function TeamCard({ teamId, teamName, sport }) {
  let sportIcon;
  switch (sport.toLowerCase()) {
    case 'kickball':
      sportIcon = styles.teamCardImgKickball;
      break;
    case 'basketball':
      sportIcon = styles.teamCardImgBasketball;
      break;
    case 'soccer':
      sportIcon = styles.teamCardImgSoccer;
      break;
    case 'frisbee':
      sportIcon = styles.teamCardImgFrisbee;
      break;
    case 'football':
    case 'flag football':
      sportIcon = styles.teamCardImgFootball;
      break;
    case 'baseball':
    case 'softball':
      sportIcon = styles.teamCardImgBaseball;
      break;
    case 'volleyball':
      sportIcon = styles.teamCardImgVolleyball;
      break;
    default:
      sportIcon = styles.teamCardImgNone;
  }
  return (
    <WhiteButton
      variant="contained"
      size="large"
      to={{
        pathname: `${stringToURL(teamName)}/schedule`,
        query: { name: teamName },
      }}
      className={styles.teamLink}
    >
      <div className={`${styles.teamCardImg} ${sportIcon}`} />
      {teamName}
    </WhiteButton>
  );
}

TeamCard.propTypes = {
  teamId: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  sport: PropTypes.string.isRequired,
};
