import { getProfileInfo, getPicture } from 'helpers/fbGraph';
import { linkProvider, savedProfilePic } from 'helpers/auth';
import { saveUserPic, convertImgURLToDataURL } from 'helpers/firebaseStorage';
import { updatingProfilePictureSuccess } from './userInfo';
import { fetchFile } from '../authedFiles';

const FETCHING_PROVIDER_INFO = 'FETCHING_PROVIDER_INFO';
const FETCHING_PROVIDER_INFO_FAILURE = 'FETCHING_PROVIDER_INFO_FAILURE';
export const FETCHING_PROVIDER_INFO_SUCCESS = 'FETCHING_PROVIDER_INFO_SUCCESS';
export const CLEAR_PROVIDER_INFO = 'CLEAR_PROVIDER_INFO';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_FIRST_NAME = 'UPDATE_FIRST_NAME';
export const UPDATE_LAST_NAME = 'UPDATE_LAST_NAME';
export const UPDATE_GENDER = 'UPDATE_GENDER';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';

const LINKING_FB = 'LINKING_FB';
const LINKING_FB_FAILURE = 'LINKING_FB_FAILURE';
const LINKING_FB_SUCCESS = 'LINKING_FB_SUCCESS';

function fetchingProviderInfo() {
  return {
    type: FETCHING_PROVIDER_INFO,
  };
}

function fetchingProviderInfoFailure() {
  return {
    type: FETCHING_PROVIDER_INFO_FAILURE,
  };
}

function fetchingProviderInfoSuccess(data) {
  return {
    type: FETCHING_PROVIDER_INFO_SUCCESS,
    data: {
      firstName: data.first_name || '',
      lastName: data.last_name || '',
      gender: data.gender || '',
      email: data.email || '',
      location: data.location && data.location.name ? data.location.name : '',
    },
  };
}

export function clearProviderInfo() {
  return {
    type: CLEAR_PROVIDER_INFO,
  };
}

export function fetchProviderInfo(fbId, token) {
  return function(dispatch) {
    dispatch(fetchingProviderInfo());
    return getProfileInfo(fbId, token)
      .then(({ data }) => dispatch(fetchingProviderInfoSuccess(data)))
      .catch(error => dispatch(fetchingProviderInfoFailure()));
  };
}

export function fetchProfilePicture(fbId, token, userId) {
  return function(dispatch) {
    return getPicture(fbId, token)
      .then(res => convertImgURLToDataURL(res.data.data.url))
      .then(dataUrl => saveUserPic(userId, dataUrl))
      .then(filePath => savedProfilePic(userId, filePath))
      .then(filePath => {
        dispatch(updatingProfilePictureSuccess(userId, filePath));
        dispatch(fetchFile(filePath));
      })
      .catch(err => console.log('err', err));
  };
}

function linkingFB() {
  return {
    type: LINKING_FB,
  };
}

function linkingFBFailure() {
  return {
    type: LINKING_FB_FAILURE,
  };
}

function linkingFBSuccess() {
  return {
    type: LINKING_FB_SUCCESS,
  };
}

export function linkFB() {
  return function(dispatch) {
    dispatch(linkingFB());
    return linkProvider()
      .then(() => dispatch(linkingFBSuccess()))
      .catch(error => dispatch(linkingFBFailure()));
  };
}

export const initialProviderState = {
  email: '',
  firstName: '',
  lastName: '',
  gender: '',
  location: '',
};

export default function providerInfo(state = initialProviderState, action) {
  switch (action.type) {
    case FETCHING_PROVIDER_INFO_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case CLEAR_PROVIDER_INFO:
      return {
        ...initialProviderState,
      };
    default:
      return state;
  }
}
