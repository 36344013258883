const ADD_ALERT = 'ADD_ALERT';
const CLOSE_ALERT = 'CLOSE_ALERT';

function removeAlert() {
  return {
    open: false,
    type: CLOSE_ALERT,
  };
}

export function addAlert(msg) {
  return {
    message: msg,
    open: true,
    type: ADD_ALERT,
  };
}

export function closeAlert() {
  return function(dispatch) {
    dispatch(removeAlert());
  };
}

const initialState = {
  open: false,
  message: '',
};

export default function alerts(state = initialState, action) {
  switch (action.type) {
    case ADD_ALERT:
      return {
        ...state,
        message: action.message,
        open: action.open,
      };
    case CLOSE_ALERT:
      return {
        ...state,
        open: action.open,
      };
    default:
      return state;
  }
}
