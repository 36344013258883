import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isEqual, keys } from 'lodash';
import { TeamEvents, LoadingTable } from 'components';
import { fetchEvents, removeEvent } from 'redux/modules/events';

class TeamEventsContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      data: props.eventsData,
      eventsLoaded: props.eventsLoaded,
      savingData: false,
    };

    this.onRowDelete = this.onRowDelete.bind(this);
  }
  componentDidMount() {
    const { eventsLoaded, teamId, seasonId, fetchEvents } = this.props;
    !eventsLoaded && fetchEvents(teamId, seasonId);
  }
  componentDidUpdate(prevProps) {
    const current = this.state.data.map(({ tableData, ...data }) => data);
    const next = this.props.eventsData.map(({ tableData, ...data }) => data);
    if (
      (!prevProps.eventsLoaded && this.props.eventsLoaded) ||
      !isEqual(current, next)
    ) {
      this.setState({
        eventsLoaded: this.props.eventsLoaded,
        data: this.props.eventsData,
      });
    }
  }
  onRowDelete({ eventStr, eventId }) {
    const { teamId, seasonStr, seasonId } = this.props;
    this.setState({ savingData: this.state.data });
    return this.props
      .removeEvent(teamId, seasonId, eventId, seasonStr, eventStr)
      .then(() => this.setState({ savingData: false }));
  }
  render() {
    const {
      name,
      teamNameStr,
      seasonStr,
      deviceSize,
      inlineCaption,
    } = this.props;
    const { data, savingData, eventsLoaded } = this.state;
    const now = new Date().getTime();
    return eventsLoaded ? (
      <TeamEvents
        data={savingData ? savingData : data}
        name={name}
        teamNameStr={teamNameStr}
        seasonStr={seasonStr}
        now={now}
        editable={{
          isDeletable: data => data.timestamp > now,
          onRowDelete: this.onRowDelete,
        }}
        deviceSize={deviceSize}
        inlineCaption={inlineCaption}
      />
    ) : (
      <LoadingTable header={`${name} Games`} isNested />
    );
  }
}

TeamEventsContainer.propTypes = {
  teamId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  eventsLoaded: PropTypes.bool.isRequired,
  teamNameStr: PropTypes.string.isRequired,
  seasonStr: PropTypes.string.isRequired,
  seasonId: PropTypes.string.isRequired,
  eventsData: PropTypes.arrayOf(
    PropTypes.shape({
      eventStr: PropTypes.string.isRequired,
      eventId: PropTypes.string.isRequired,
      opponent: PropTypes.string.isRequired,
      timestamp: PropTypes.number.isRequired,
      totalRSVPs: PropTypes.number.isRequired,
      shortDate: PropTypes.string.isRequired,
      digitDate: PropTypes.string.isRequired,
      shortTime: PropTypes.string.isRequired,
    })
  ),
  deviceSize: PropTypes.string.isRequired,
  inlineCaption: PropTypes.bool.isRequired,
  fetchEvents: PropTypes.func.isRequired,
  removeEvent: PropTypes.func.isRequired,
};

function mapStateToProps(
  { seasons, events, viewport: { deviceSize, width } },
  { teamId, name, seasonId, seasonStr, teamNameStr }
) {
  const eventsLoaded = get(
    seasons,
    `${teamId}.lookups.${seasonStr}.eventsLoaded`,
    false
  );
  const seasonEvents = get(
    seasons,
    `${teamId}.lookups.${seasonStr}.events`,
    {}
  );
  const eventsData = keys(seasonEvents).map(eventStr => {
    const eventId = seasonEvents[eventStr].eventId;
    const {
      opponent,
      timestamp,
      totalRSVPs,
      shortTime,
      shortDate,
      digitDate,
    } = events[eventId];
    return {
      eventStr,
      eventId,
      opponent,
      timestamp,
      totalRSVPs: totalRSVPs ? totalRSVPs : 0,
      shortTime,
      shortDate,
      digitDate,
    };
  });

  return {
    teamId,
    name,
    eventsLoaded,
    teamNameStr,
    seasonStr,
    seasonId,
    eventsData,
    deviceSize,
    inlineCaption: width > 790,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchEvents, removeEvent }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamEventsContainer);
