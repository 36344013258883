import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useStyles from './styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Title } from 'components/base';

const LoadingTable = ({ header, subtitle, isNested }) => {
  const classes = useStyles();
  return (
    <div
      className={cx({
        [classes.eventsWrapper]: isNested,
      })}
    >
      <div className={classes.header}>
        {subtitle ? (
          <Title title={header} subtitle={subtitle} />
        ) : (
          <h4>{header}</h4>
        )}
      </div>
      <LinearProgress className={classes.progressBar} />
    </div>
  );
};

LoadingTable.propTypes = {
  header: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  isNested: PropTypes.bool,
};

export default LoadingTable;
