import React from 'react';
import PropTypes from 'prop-types';
import { Table, Title } from 'components/base';
import TeamRosterFilters from './TeamRosterFilters';
import { PlayerAvatarContainer } from 'containers';

const statusMap = {
  1: 'Active',
  2: 'Reserve',
  0: 'Invited',
};

const TeamRoster = ({
  data,
  name,
  isCoed,
  editable,
  filterProps,
  isFiltered,
  isSmall,
  isXS,
  inlineCaption,
}) => {
  return (
    <Table
      title={
        <Title title="Roster" subtitle={`Update the roster for ${name}`} />
      }
      columns={[
        {
          title: '',
          field: 'avatar',
          render: data =>
            data && data.avatar ? (
              <PlayerAvatarContainer profilePic={data.avatar} />
            ) : (
              undefined
            ),
          cellStyle: {
            width: 51,
            padding: '0 8px',
          },
          editable: 'never',
          sorting: false,
          hidden: isSmall,
        },
        {
          title: 'Name',
          field: 'name',
          editable: 'onAdd',
        },
        {
          title: 'Gender',
          field: 'gender',
          lookup: { 0: 'Male', 1: 'Female' },
          editable: 'onAdd',
          cellStyle: { width: 100 },
          hidden: !isCoed || isXS,
        },
        {
          title: 'Phone',
          field: 'phone',
          editable: 'onAdd',
          type: 'numeric',
          cellStyle: { width: 160, textAlign: 'left' },
          headerStyle: { textAlign: 'left', flexDirection: 'row' },
          hidden: isXS,
        },
        {
          title: 'Status',
          field: 'status',
          lookup: { 1: 'Active', 2: 'Reserve' },
          cellStyle: { width: 120 },
          render: rowData => statusMap[rowData.status],
        },
      ]}
      caption={`List of players on the roster for the team ${name}`}
      pageSize={10}
      data={data}
      editable={isSmall ? undefined : editable}
      localization={{
        body: {
          editRow: {
            deleteText:
              'Are you sure you want to delete this player? They will no longer receive game invites.',
          },
          emptyDataSourceMessage: 'No players to display',
        },
      }}
      Filters={isFiltered && !isXS ? TeamRosterFilters : undefined}
      filterProps={isFiltered && !isXS ? filterProps : undefined}
      paging={isFiltered}
      search={!isXS}
      inlineCaption={inlineCaption}
    />
  );
};

TeamRoster.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      status: PropTypes.number.isRequired,
      avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      phone: PropTypes.string.isRequired,
      gender: PropTypes.number.isRequired,
      uid: PropTypes.string.isRequired,
    })
  ),
  name: PropTypes.string.isRequired,
  isCoed: PropTypes.bool.isRequired,
  editable: PropTypes.shape({
    isEditable: PropTypes.func.isRequired,
    isDeletable: PropTypes.func.isRequired,
    onRowUpdate: PropTypes.func.isRequired,
    onRowAdd: PropTypes.func.isRequired,
    onRowDelete: PropTypes.func.isRequired,
  }),
  filterProps: PropTypes.shape({
    onStatusFilter: PropTypes.func.isRequired,
    onGenderFilter: PropTypes.func.isRequired,
    statusFilters: PropTypes.array.isRequired,
    genderFilters: PropTypes.array.isRequired,
    isCoed: PropTypes.bool.isRequired,
  }),
  isFiltered: PropTypes.bool.isRequired,
};

export default TeamRoster;
