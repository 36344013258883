import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ProfileForm from './ProfileForm';
import validate from '../../helpers/formValidate/profile';

// TODO: Do we need React here?

let ProfileSetup = reduxForm({
  form: 'profileSetup',
  validate,
  enableReinitialize: true,
})(ProfileForm);

ProfileSetup = connect(({ users }) => {
  const providerInfo = users.providerInfo;
  const userInfo = users[users.authedId].info;
  return {
    initialValues: {
      firstName: providerInfo.firstName
        ? providerInfo.firstName
        : userInfo.firstName,
      lastName: providerInfo.lastName
        ? providerInfo.lastName
        : userInfo.lastName,
      gender: providerInfo.gender ? providerInfo.gender : userInfo.gender,
      email: providerInfo.email ? providerInfo.email : userInfo.email,
      location: providerInfo.location,
    },
  };
})(ProfileSetup);

export default ProfileSetup;
