const UPDATE_VIEWPORT = 'UPDATE_VIEWPORT';

const getDeviceSize = width => {
  if (width < 600) {
    return 'xs';
  } else if (width < 768) {
    return 'sm';
  } else if (width < 992) {
    return 'md';
  } else if (width < 1200) {
    return 'lg';
  } else {
    return 'xl';
  }
};

const iOS = () => {
  var iDevices = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ];

  if (!!navigator.platform) {
    while (iDevices.length) {
      if (navigator.platform === iDevices.pop()) {
        return true;
      }
    }
  }

  return false;
};

var innerWidth = window.innerWidth;

const initialState = {
  width: innerWidth,
  deviceSize: getDeviceSize(innerWidth),
  iOS: iOS(),
};

function updateViewport(width) {
  return {
    type: UPDATE_VIEWPORT,
    width,
    deviceSize: getDeviceSize(width),
  };
}

export function updatingViewport() {
  return function(dispatch) {
    var innerWidth = window.innerWidth;
    return dispatch(updateViewport(innerWidth));
  };
}

export default function viewport(state = initialState, action) {
  switch (action.type) {
    case UPDATE_VIEWPORT:
      return {
        ...state,
        width: action.width,
        deviceSize: action.deviceSize,
      };
    default:
      return state;
  }
}
