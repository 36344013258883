import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import cx from 'classnames';
import { PrimaryButton, TextField, RadioButtons } from 'components/base';
import { normalizePhone } from 'helpers/formNormalize';
import validate from 'helpers/formValidate/invite';
import styles from 'sharedStyles/styles.module.css';

import {
  radioOptions,
  genderField,
  inputsWrapper,
  fieldsWrapper,
  actionsWrapper,
} from './styles.module.css';

export function renderInviteFields(submitting, isManager, isCoed) {
  return (
    <div className={fieldsWrapper}>
      <div className={inputsWrapper}>
        <Field
          name="firstName"
          component={TextField}
          label="First Name"
          disabled={submitting}
        />
        <Field
          name="lastName"
          component={TextField}
          label="Last Name"
          disabled={submitting}
        />
        <Field
          name="phone"
          component={TextField}
          label="Phone"
          disabled={submitting}
          normalize={normalizePhone}
        />
      </div>
      <div className={radioOptions}>
        {isCoed ? (
          <Field
            name="gender"
            component={RadioButtons}
            className={genderField}
            label="Gender"
            options={[
              {
                value: 'female',
                label: 'Female',
              },
              {
                value: 'male',
                label: 'Male',
              },
            ]}
            disabled={submitting}
          />
        ) : null}
        <Field
          name="status"
          component={RadioButtons}
          label="Status"
          options={[
            {
              value: 'active',
              label: 'Active',
            },
            {
              value: 'reserve',
              label: 'Reserve',
            },
          ]}
          disabled={submitting}
          className={!isManager ? styles.hiddenField : null}
        />
      </div>
    </div>
  );
}

function InviteForm({ handleSubmit, submitting, onInvite, isCoed, isManager }) {
  return (
    <form onSubmit={handleSubmit(onInvite)}>
      {renderInviteFields(submitting, isManager, isCoed)}
      <div className={cx(styles.formActions, actionsWrapper)}>
        <PrimaryButton type="submit" disabled={submitting}>
          Invite
        </PrimaryButton>
      </div>
    </form>
  );
}

let InitialInviteForm = reduxForm({
  form: 'inviteForm',
  destroyOnUnmount: false,
  validate,
})(InviteForm);

InitialInviteForm = connect(({ users, teams }, ownProps) => {
  return {
    initialValues: {
      isCoed: ownProps.isCoed,
      status: users.authedId !== teams[ownProps.teamId].uid ? 'reserve' : '',
    },
    enableReinitialize: true,
  };
})(InitialInviteForm);

export default InitialInviteForm;
