import axios from 'axios';
import { ref } from '../config/constants';
const lambdaURL = 'https://us-central1-coach-cheetah-b6dcf.cloudfunctions.net';

export function getLocation(key) {
  return ref
    .child(`locations/${key}`)
    .once('value')
    .then(snapshot => {
      const data = snapshot.val();
      return {
        uid: data.uid,
        formattedAddress: data.formattedAddress,
      };
    });
}

export const getLocations = locationIds =>
  axios.post(`${lambdaURL}/getLocations`, {
    locationIds,
  });
