import React from 'react';
import cx from 'classnames';
import {
  headcountTotal,
  genderCounts,
  eventHeadcount,
  eventSoon,
} from './eventHeadcount.module.css';

// TODO: Add proptypes

export default function EventHeadcount({
  totalRSVPs,
  maleRSVPs,
  femaleRSVPs,
  teamGender,
  isThisWeek,
  className,
}) {
  if (!totalRSVPs && !isThisWeek) {
    return null;
  }
  const rsvpCount = totalRSVPs ? totalRSVPs : 'No';
  const totalCount = rsvpCount === 1 ? '1 RSVP' : `${rsvpCount} RSVPs`;
  let genderCount = null;
  if (teamGender === 'coed') {
    const femaleCount =
      femaleRSVPs === 1 ? '1 female' : `${femaleRSVPs} females`;
    const maleCount = maleRSVPs === 1 ? '1 male' : `${maleRSVPs} males`;
    genderCount = `${femaleCount} / ${maleCount}`;
  }
  return (
    <div
      className={cx(
        eventHeadcount,
        {
          [eventSoon]: isThisWeek,
        },
        className
      )}
    >
      <span className={headcountTotal}>{totalCount}</span>
      {genderCount ? <span className={genderCounts}>{genderCount}</span> : null}
    </div>
  );
}

EventHeadcount.defaultProps = {
  maleRSVPs: 0,
  femaleRSVPs: 0,
  totalRSVPs: 0,
};
