import { AUTH_USER } from '../users';
import {
  saveAccessToken as setAccessToken,
  updateUserProfile as saveProfileData,
  savePhone,
  savePhoneLookup,
} from 'helpers/api';
import { saveUserPic } from 'helpers/firebaseStorage';
import { savedProfilePic } from 'helpers/auth';
import { fetchFile } from '../authedFiles';

const SAVING_ACCESS_TOKEN = 'SAVING_ACCESS_TOKEN';
const SAVING_ACCESS_TOKEN_FAILURE = 'SAVING_ACCESS_TOKEN_FAILURE';
export const SAVING_ACCESS_TOKEN_SUCCESS = 'SAVING_ACCESS_TOKEN_SUCCESS';

const UPDATING_USER_PROFILE = 'UPDATING_USER_PROFILE';
const UPDATING_USER_PROFILE_FAILURE = 'UPDATING_USER_PROFILE_FAILURE';
export const UPDATING_USER_PROFILE_SUCCESS = 'UPDATING_USER_PROFILE_SUCCESS';

const UPDATING_USER_PHONE = 'UPDATING_USER_PHONE';
const UPDATING_USER_PHONE_FAILURE = 'UPDATING_USER_PHONE_FAILURE';
export const UPDATING_USER_PHONE_SUCCESS = 'UPDATING_USER_PHONE_SUCCESS';

export const UPDATING_PROFILE_PICTURE = 'UPDATING_PROFILE_PICTURE';
export const UPDATING_PROFILE_PICTURE_FAILURE =
  'UPDATING_PROFILE_PICTURE_FAILURE';
export const UPDATING_PROFILE_PICTURE_SUCCESS =
  'UPDATING_PROFILE_PICTURE_SUCCESS';
export const UPDATED_PROFILE_PICTURE = 'UPDATED_PROFILE_PICTURE';

function savingAccessToken() {
  return {
    type: SAVING_ACCESS_TOKEN,
  };
}

function savingAccessTokenFailure() {
  return {
    type: SAVING_ACCESS_TOKEN_FAILURE,
  };
}

function savingAccessTokenSuccess(uid, accessToken) {
  return {
    type: SAVING_ACCESS_TOKEN_SUCCESS,
    uid,
    accessToken,
  };
}

export function saveAccessToken(token) {
  return function(dispatch) {
    dispatch(savingAccessToken());
    setAccessToken(token)
      .then(({ uid, accessToken }) =>
        dispatch(savingAccessTokenSuccess(uid, accessToken))
      )
      .catch(error => dispatch(savingAccessTokenFailure(error)));
  };
}

function updatingUserPhone() {
  return {
    type: UPDATING_USER_PHONE,
  };
}

function updatingUserPhoneFailure() {
  return {
    type: UPDATING_USER_PHONE_FAILURE,
  };
}

function updatingUserPhoneSuccess(uid, phone) {
  return {
    type: UPDATING_USER_PHONE_SUCCESS,
    uid,
    phone,
  };
}

export function updateUserPhone(uid, phone) {
  return function(dispatch) {
    dispatch(updatingUserPhone());
    return savePhone(phone)
      .then(() => savePhoneLookup(phone))
      .then(() => dispatch(updatingUserPhoneSuccess(uid, phone)))
      .catch(error => dispatch(updatingUserPhoneFailure()));
  };
}

function updatingUserProfile() {
  return {
    type: UPDATING_USER_PROFILE,
  };
}

function updatingUserProfileFailure() {
  return {
    type: UPDATING_USER_PROFILE_FAILURE,
  };
}

function updatingUserProfileSuccess(userUpdates) {
  return {
    type: UPDATING_USER_PROFILE_SUCCESS,
    uid: userUpdates.uid,
    info: userUpdates,
  };
}

function updatingProfilePicture() {
  return {
    type: UPDATING_PROFILE_PICTURE,
  };
}

function updatingProfilePictureFailure() {
  return {
    type: UPDATING_PROFILE_PICTURE_FAILURE,
  };
}

export function updatingProfilePictureSuccess(uid, filePath) {
  return {
    type: UPDATING_PROFILE_PICTURE_SUCCESS,
    uid,
    filePath,
  };
}

export function updateProfilePicture(uid, dataURL) {
  return function(dispatch) {
    dispatch(updatingProfilePicture);
    return saveUserPic(uid, dataURL)
      .then(filePath => savedProfilePic(uid, filePath))
      .then(filePath => {
        dispatch(updatingProfilePictureSuccess(uid, filePath));
        dispatch(fetchFile(filePath));
      })
      .catch(error => dispatch(updatingProfilePictureFailure(error)));
  };
}

export function updateUserProfile(
  { firstName, lastName, gender, zip, email },
  phone
) {
  return function(dispatch) {
    dispatch(updatingUserProfile());
    return saveProfileData(firstName, lastName, gender, zip, email, phone)
      .then(response => dispatch(updatingUserProfileSuccess(response)))
      .catch(error => dispatch(updatingUserProfileFailure()));
  };
}

export const initialUserInfo = {
  firstName: '',
  lastName: '',
  gender: '',
  zip: '',
  email: '',
  phone: '',
  fbId: '',
  accessToken: '',
  profileComplete: false,
  uid: '',
  avatar: '',
  profilePic: false,
  updatingProfilePic: false,
};

export default function userInfo(state = initialUserInfo, action) {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        ...action.user,
      };
    case SAVING_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        accessToken: action.accessToken,
      };
    case UPDATING_USER_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.info,
      };
    case UPDATING_USER_PHONE_SUCCESS:
      return {
        ...state,
        phone: action.phone,
      };
    case UPDATING_PROFILE_PICTURE:
      return {
        ...state,
        updatingProfilePic: true,
      };
    case UPDATING_PROFILE_PICTURE_FAILURE:
      return {
        ...state,
        updatingProfilePic: false,
      };
    case UPDATING_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        profilePic: action.filePath,
      };
    default:
      return state;
  }
}
