import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';

const mapDisplayUrl =
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyDNp-auiU8STm2i7rLGc6R-ftuvXa_cK9U&v=3.exp&libraries=geometry,drawing,places';

const MapComponent = withScriptjs(
  withGoogleMap(props => {
    return (
      <GoogleMap
        defaultZoom={props.zoom}
        defaultCenter={{ lat: props.lat, lng: props.lng }}
        options={{
          disableDefaultUI: true,
        }}
      >
        <Marker position={{ lat: props.lat, lng: props.lng }} />
      </GoogleMap>
    );
  })
);

export default function Map(props) {
  return <MapComponent {...props} googleMapURL={mapDisplayUrl} />;
}
