import React from 'react';
import { container, heroContentWrapper } from './styles.module.css';

export default function Home() {
  return (
    <div className={container}>
      <div className={heroContentWrapper}>
        <h2>Manage your sports team with ease.</h2>
        <p>
          Add your team members and events, and we will take care of texting
          your teammates to make sure they RSVP.
        </p>
      </div>
    </div>
  );
}
