import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { bindActionCreators } from 'redux';
import { get, debounce } from 'lodash';
import { placesAutocomplete } from '../../helpers/googleMaps';
import { getLocations } from '../../helpers/geoFire';
import { LocationFields } from '../../components';
import { fetchUserLocation } from '../../redux/modules/geoFire';
import { addLocations, addGooglePlace } from '../../redux/modules/locations';

class LocationFieldsContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.fetchGooglePlaces = debounce(
      this.fetchGooglePlaces.bind(this),
      props.debounce
    );
    this.handleLocationSelect = this.handleLocationSelect.bind(this);
  }
  UNSAFE_componentWillMount() {
    if (!this.props.lat || !this.props.lng) {
      this.props.fetchUserLocation().then(() => this.prefetchLocations());
    } else {
      this.prefetchLocations();
    }
  }
  componentWillUnmount() {
    this.geoFire && this.geoFire.cancel();
  }
  componentDidUpdate(prevProps) {
    const { locationQuery } = this.props;
    if (locationQuery && locationQuery !== prevProps.locationQuery) {
      this.fetchGooglePlaces();
    }
  }
  prefetchLocations() {
    const locationKeys = [];
    this.geoFire = getLocations([this.props.lat, this.props.lng]);
    this.geoFire.on('key_entered', (key, location, distance) => {
      if (!this.props.keys.includes(key)) {
        locationKeys.push(key);
      }
    });

    this.geoFire.on('ready', () => {
      this.props.addLocations(locationKeys);
      this.geoFire.cancel();
    });
  }
  fetchGooglePlaces() {
    const { locationQuery, searchTerms, lat, lng } = this.props;
    if (!searchTerms.includes(locationQuery)) {
      placesAutocomplete(locationQuery, lat, lng).then(data => {
        this.props.addGooglePlace(data, locationQuery);
      });
    }
  }
  handleLocationSelect({ value }) {
    this.props.updateLocationId(this.props.form, value);
  }
  render() {
    return (
      <LocationFields
        submitting={this.props.submitting}
        onUpdateInput={this.handleUpdateInput}
        dataSource={[].concat(
          this.props.dataSource,
          this.props.googleLocations
        )}
        onLocationSelect={this.handleLocationSelect}
        required={this.props.required}
      />
    );
  }
}

LocationFieldsContainer.defaultProps = {
  debounce: 300,
};

LocationFieldsContainer.propTypes = {
  submitting: PropTypes.bool.isRequired,
  fetchUserLocation: PropTypes.func.isRequired,
  addLocations: PropTypes.func.isRequired,
  isPrefetched: PropTypes.bool.isRequired,
  lat: PropTypes.number,
  lng: PropTypes.number,
  debounce: PropTypes.number,
};

function mapStateToProps({ geoFire, locations, users, form }, ownProps) {
  let latitude = null;
  if (geoFire.users[users.authedId] && geoFire.users[users.authedId].lat) {
    latitude = geoFire.users[users.authedId].lat;
  }
  let longitude = null;
  if (geoFire.users[users.authedId] && geoFire.users[users.authedId].lng) {
    longitude = geoFire.users[users.authedId].lng;
  }
  return {
    lat: latitude,
    lng: longitude,
    isPrefetched: locations.isPrefetched,
    dataSource: locations.locations,
    keys: locations.keys,
    searchTerms: locations.searchTerms,
    googleLocations: locations.googleLocations,
    locationQuery: get(
      form,
      'eventCreate.values.location',
      get(form, 'eventEdit.values.location', '')
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchUserLocation,
      addLocations,
      addGooglePlace,
      updateLocationId: (formName, id) =>
        dispatch(change(formName, 'locationId', id)),
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationFieldsContainer);
