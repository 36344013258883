import React from 'react';
import PropTypes from 'prop-types';
import { Map } from '../../components';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import TimeIcon from '@material-ui/icons/AccessTime';
import PeopleIcon from '@material-ui/icons/People';
import LocationIcon from '@material-ui/icons/LocationOn';
import PlayingIcon from '@material-ui/icons/Check';
import NotPlayingIcon from '@material-ui/icons/Close';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton } from 'components/base';

import {
  ToggleFields,
  EventDate,
  EventAttribute,
  ColorBlock,
} from 'components/base';
import styles from './style.module.css';

const ToggleButtonLabel = ({ Icon, label }) => (
  <span className={styles.toggleLabelWrapper}>
    <Icon className={styles.toggleLabelIcon} />
    <span>{label}</span>
  </span>
);

const InlineTextBreak = () => (
  <span className={styles.inlineTextBreak}>{' · '}</span>
);

const EventEdit = ({ pathname }) => (
  <div className={styles.eventHeaderEdit}>
    <IconButton
      Icon={EditIcon}
      aria-label="edit"
      to={{ pathname: `${pathname}/edit` }}
    />
  </div>
);

export default function EventDetail({
  opponent,
  isHome,
  startMoment,
  isUpcoming,
  address,
  weather,
  showMap,
  toggleShowMap,
  inviteStatus,
  inviteSubtitle,
  status,
  lat,
  lng,
  location,
  directions,
  rsvpStatus,
  handleRSVP,
  uniformColor,
  isManager,
  pathname,
}) {
  const isLatLng = lat && lng;
  const mapAdornment = showMap ? 'hide map' : 'show map';
  const locationTitle = location ? `${location}, ${address}` : address;
  return (
    <div className={styles.eventInfoWrapper}>
      <Paper elevation={1} className={styles.eventInfo}>
        <div className={styles.headerSection}>
          <EventDate startMoment={startMoment} />
          <div className={styles.eventHeaderSection}>
            <h1 className={styles.eventHeader}>
              <span className={styles.isHome}>{isHome ? 'vs.' : 'at'}</span>
              {` ${opponent}`}
            </h1>
            <div className={styles.eventSubtitle}>{status}</div>
          </div>
          {isManager && <EventEdit pathname={pathname} />}
        </div>
        <Divider className={styles.divider} />
        <EventAttribute
          Icon={TimeIcon}
          title={
            startMoment &&
            startMoment.calendar(null, {
              sameElse: 'dddd, MMMM D, YYYY [at] h:mm A',
            })
          }
          subtitle={
            isUpcoming ? (
              <React.Fragment>
                <span>{startMoment.fromNow()}</span>
                {weather ? (
                  <React.Fragment>
                    <InlineTextBreak />
                    <span>{weather}</span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            ) : null
          }
        />
        <EventAttribute
          Icon={LocationIcon}
          title={locationTitle}
          subtitle={directions}
          adornment={isLatLng ? mapAdornment : undefined}
          onClick={isLatLng ? toggleShowMap : undefined}
          expanded={showMap}
        >
          {lat && lng && (
            <Map
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `200px`, margin: 12 }} />}
              mapElement={<div style={{ height: `100%` }} />}
              lat={lat}
              lng={lng}
              zoom={13}
            />
          )}
        </EventAttribute>
        {uniformColor && (
          <EventAttribute
            Icon={EmojiPeopleIcon}
            title={
              <ColorBlock
                color={uniformColor}
                text={isHome ? 'Home Uniform' : 'Away Uniform'}
              />
            }
          />
        )}

        <EventAttribute
          Icon={PeopleIcon}
          title={inviteStatus}
          subtitle={inviteSubtitle}
        />
        <Divider className={styles.divider} />
        <ToggleFields
          className={styles.toggleRSVP}
          value={rsvpStatus}
          onChange={handleRSVP}
          exclusive
          options={[
            {
              value: 1,
              label: <ToggleButtonLabel Icon={PlayingIcon} label="Playing" />,
            },
            {
              value: 0,
              label: (
                <ToggleButtonLabel Icon={NotPlayingIcon} label="Not Playing" />
              ),
            },
          ]}
          isPrimary
        />
      </Paper>
    </div>
  );
}

EventDetail.propTypes = {
  opponent: PropTypes.string.isRequired,
  isHome: PropTypes.bool.isRequired,
  startMoment: PropTypes.object.isRequired,
  isUpcoming: PropTypes.bool.isRequired,
  address: PropTypes.string.isRequired,
  weather: PropTypes.string,
  showMap: PropTypes.bool.isRequired,
  toggleShowMap: PropTypes.func.isRequired,
  inviteStatus: PropTypes.string.isRequired,
  inviteSubtitle: PropTypes.string,
  status: PropTypes.string.isRequired,
  lat: PropTypes.number,
  lng: PropTypes.number,
  rsvpStatus: PropTypes.number,
  handleRSVP: PropTypes.func.isRequired,
  uniformColor: PropTypes.string,
};
