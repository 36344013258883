import axios from 'axios';
import { database } from '../config/constants';
const GoogleMapsAPIKey = 'AIzaSyDNp-auiU8STm2i7rLGc6R-ftuvXa_cK9U';
const GoogleMapsURL = 'https://maps.googleapis.com/maps/api/geocode/json';
const functionsURL =
  'https://us-central1-coach-cheetah-b6dcf.cloudfunctions.net';
const autocompleteURL =
  'https://us-central1-coach-cheetah-b6dcf.cloudfunctions.net/placesAutocomplete';

export function getLatLong(zip) {
  return axios
    .get(GoogleMapsURL, {
      params: {
        address: zip,
        key: GoogleMapsAPIKey,
      },
    })
    .then(({ data }) => {
      return data.results[0].geometry.location;
    });
}

export function placesAutocomplete(search, latitude, longitude) {
  return axios
    .get(autocompleteURL, {
      params: {
        input: search,
        lat: latitude,
        lng: longitude,
      },
    })
    .then(({ data }) => {
      if (data && data.predictions) {
        return data.predictions;
      } else {
        return null;
      }
    });
}

export function getPlaceDetails(id, startTime) {
  return axios.get(`${functionsURL}/getPlaceDetails`, {
    params: {
      placeId: id,
      timestamp: startTime,
    },
  });
}

export function getLocation(locationId) {
  const locationRef = database.ref(`locations/${locationId}`);
  return locationRef.once('value').then(snapshot => {
    const location = snapshot.val();
    return location;
  });
}
