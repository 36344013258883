import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PeopleIcon from '@material-ui/icons/People';
import DeleteIcon from '@material-ui/icons/Delete';
import ListIcon from '@material-ui/icons/List';

import {
  TeamDeleteContainer,
  TeamInfoContainer,
  TeamSeasonsContainer,
  TeamRosterContainer,
} from 'containers';

import sharedStyles from 'sharedStyles/styles.module.css';

const useStyles = makeStyles(theme => ({
  settings: {
    flex: 1,
    marginLeft: theme.spacing(8),
  },
  settingsPaper: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  manageWrapper: {
    paddingTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectedListItem: {
    backgroundColor: `${theme.palette.primary.light} !important`,
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

const StyledListItem = ({ Icon, selected, onClick, label }) => {
  const classes = useStyles();
  return (
    <ListItem
      button
      selected={selected}
      classes={{ selected: classes.selectedListItem }}
      onClick={onClick}
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
};

const ManageTeam = ({
  onSubNavClick,
  selectedIndex,
  updateSelectedIndex,
  isXS,
  ...props
}) => {
  const classes = useStyles();
  return (
    <div className={sharedStyles.eventDetailWrapper}>
      <div className={sharedStyles.contentWrapper}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Paper>
              <List component="nav" aria-labelledby="nested-list-subheader">
                <StyledListItem
                  selected={selectedIndex === 0}
                  onClick={() => onSubNavClick('', 0)}
                  Icon={ListIcon}
                  label="Info"
                />
                <StyledListItem
                  selected={selectedIndex === 1}
                  onClick={() => onSubNavClick('/seasons', 1)}
                  Icon={DateRangeIcon}
                  label="Seasons"
                />
                <StyledListItem
                  selected={selectedIndex === 2}
                  onClick={() => onSubNavClick('/roster', 2)}
                  Icon={PeopleIcon}
                  label="Roster"
                />
                <StyledListItem
                  selected={selectedIndex === 3}
                  onClick={() => onSubNavClick('/delete', 3)}
                  Icon={DeleteIcon}
                  label="Delete"
                />
              </List>
            </Paper>
          </Grid>
          {isXS && (
            <Grid item xs={12}>
              <Alert severity="info">
                Rotate screen to landscape to see more info and actions.
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} md={9} className={classes.content}>
            <Paper elevation={1} className={classes.settingsPaper}>
              <Switch>
                <Route
                  exact
                  path="/:teamName/manage"
                  render={routeProps => (
                    <TeamInfoContainer {...routeProps} {...props} />
                  )}
                />
                <Route
                  exact
                  path="/:teamName/manage/seasons"
                  render={routeProps => (
                    <TeamSeasonsContainer {...routeProps} {...props} />
                  )}
                />
                <Route
                  exact
                  path="/:teamName/manage/roster"
                  render={routeProps => (
                    <TeamRosterContainer {...routeProps} {...props} />
                  )}
                />
                <Route
                  exact
                  path="/:teamName/manage/delete"
                  render={routeProps => (
                    <TeamDeleteContainer {...routeProps} {...props} />
                  )}
                />
              </Switch>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

ManageTeam.propTypes = {
  teamName: PropTypes.string.isRequired,
};

export default ManageTeam;
