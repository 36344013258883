import { saveTeam, getTeam, removeTeam } from 'helpers/api';
import { fbUpdateTeam } from 'helpers/apiTeam';
import {
  addSingleUsersTeam,
  removeSingleUsersTeam,
  updateSingleUsersTeam,
} from './usersTeams';

const FETCHING_TEAM = 'FETCHING_TEAM';
const FETCHING_TEAM_FAILURE = 'FETCHING_TEAM_FAILURE';
const FETCHING_TEAM_SUCCESS = 'FETCHING_TEAM_SUCCESS';

const SETTING_TEAM = 'SETTING_TEAM';
const SETTING_TEAM_FAILURE = 'SETTING_TEAM_FAILURE';
const SETTING_TEAM_SUCCESS = 'SETTING_TEAM_SUCCESS';

const DELETING_TEAM = 'DELETING_TEAM';
const DELETING_TEAM_FAILURE = 'DELETING_TEAM_FAILURE';
const DELETING_TEAM_SUCCESS = 'DELETING_TEAM_SUCCESS';

const UPDATING_TEAM = 'UPDATING_TEAM';
const UPDATING_TEAM_FAILURE = 'UPDATING_TEAM_FAILURE';
const UPDATING_TEAM_SUCCESS = 'UPDATING_TEAM_SUCCESS';

function fetchingTeam() {
  return {
    type: FETCHING_TEAM,
  };
}

function fetchingTeamFailure(error) {
  return {
    type: FETCHING_TEAM_FAILURE,
    error: 'Unable to fetch team.',
  };
}

function fetchingTeamSuccess({
  name,
  sport,
  gender,
  teamHomeColor,
  teamAwayColor,
  uid,
  teamId,
  minPlayer,
  minMale,
  minFemale,
  maxPlayer,
  maxMale,
  maxFemale,
}) {
  return {
    type: FETCHING_TEAM_SUCCESS,
    name,
    sport,
    gender,
    teamHomeColor,
    teamAwayColor,
    uid,
    teamId,
    minPlayer,
    minMale,
    minFemale,
    maxPlayer,
    maxMale,
    maxFemale,
  };
}

function settingTeam() {
  return {
    type: SETTING_TEAM,
  };
}

function settingTeamFailure() {
  return {
    type: SETTING_TEAM_FAILURE,
    error: 'Team not created',
  };
}

function settingTeamSuccess({
  name,
  sport,
  gender,
  teamHomeColor,
  teamAwayColor,
  uid,
  teamId,
  minMale,
  minFemale,
  minPlayer,
  maxMale,
  maxFemale,
  maxPlayer,
}) {
  return {
    type: SETTING_TEAM_SUCCESS,
    name,
    sport,
    gender,
    teamHomeColor,
    teamAwayColor,
    uid,
    teamId,
    minPlayer,
    minFemale,
    minMale,
    maxMale,
    maxFemale,
    maxPlayer,
  };
}

function updatingTeam() {
  return {
    type: UPDATING_TEAM,
  };
}

function updatingTeamFailure() {
  return {
    type: UPDATING_TEAM_FAILURE,
    error: 'Team not updated',
  };
}

function updatingTeamSuccess(data, teamId) {
  return {
    type: UPDATING_TEAM_SUCCESS,
    teamId,
    ...data,
  };
}

function deletingTeam() {
  return {
    type: DELETING_TEAM,
  };
}

function deletingTeamFailure() {
  return {
    type: DELETING_TEAM_FAILURE,
    error: 'Team not deleted.',
  };
}

function deletingTeamSuccess(teamId) {
  return {
    type: DELETING_TEAM_SUCCESS,
    teamId,
  };
}

export function updateTeam(values, teamId, uid) {
  return function(dispatch) {
    dispatch(updatingTeam());
    return fbUpdateTeam(values, teamId)
      .then(data => dispatch(updatingTeamSuccess(data, teamId)))
      .then(() => dispatch(updateSingleUsersTeam(uid, values, teamId)))
      .catch(err => dispatch(updatingTeamFailure(err)));
  };
}

export function setTeam(values) {
  return function(dispatch) {
    dispatch(settingTeam());
    return saveTeam(values)
      .then(response => {
        dispatch(settingTeamSuccess(response));
        dispatch(
          addSingleUsersTeam(
            response.uid,
            response.teamId,
            response.name,
            response.sport,
            Date.now()
          )
        );
        return response.teamId;
      })
      .catch(error => {
        console.log('error', error);
        dispatch(settingTeamFailure(error));
      });
  };
}

export function fetchTeam(teamId) {
  return function(dispatch) {
    dispatch(fetchingTeam());
    return getTeam(teamId)
      .then(team => {
        return dispatch(fetchingTeamSuccess(team));
      })
      .catch(error => {
        dispatch(fetchingTeamFailure(error));
      });
  };
}

export function deleteTeam(teamId, uid) {
  return function(dispatch) {
    dispatch(deletingTeam());
    return removeTeam(teamId)
      .then(() => {
        dispatch(deletingTeamSuccess(teamId));
        dispatch(removeSingleUsersTeam(uid, teamId, Date.now()));
      })
      .catch(error => {
        console.log('error', error);
        dispatch(deletingTeamFailure(error));
      });
  };
}

const initialState = {
  errorSetting: '',
  errorFetching: '',
  errorDeleting: '',
  isSettingTeam: false,
  isFetchingTeam: false,
  isDeletingTeam: false,
};

export default function teams(state = initialState, action) {
  switch (action.type) {
    case FETCHING_TEAM:
      return {
        ...state,
        isFetchingTeam: true,
      };
    case FETCHING_TEAM_FAILURE:
      return {
        ...state,
        isFetchingTeam: false,
        errorFetching: action.error,
      };
    case SETTING_TEAM:
      return {
        ...state,
        isSettingTeam: true,
      };
    case SETTING_TEAM_FAILURE:
      return {
        ...state,
        isSettingTeam: false,
        errorSetting: action.error,
      };
    case SETTING_TEAM_SUCCESS:
    case FETCHING_TEAM_SUCCESS:
      return {
        ...state,
        errorSetting: '',
        errorFetching: '',
        isSettingTeam: false,
        isFetchingTeam: false,
        [action.teamId]: {
          teamId: action.teamId,
          name: action.name,
          sport: action.sport,
          gender: action.gender,
          teamHomeColor: action.teamHomeColor,
          teamAwayColor: action.teamAwayColor,
          uid: action.uid,
          minPlayer: action.minPlayer,
          minMale: action.minMale,
          minFemale: action.minFemale,
          maxPlayer: action.maxPlayer,
          maxMale: action.maxMale,
          maxFemale: action.maxFemale,
        },
      };
    case UPDATING_TEAM_SUCCESS:
      const { type, ...data } = action;
      return {
        ...state,
        [action.teamId]: {
          ...state[action.teamId],
          ...data,
        },
      };
    case DELETING_TEAM:
      return {
        ...state,
        isDeletingTeam: true,
      };
    case DELETING_TEAM_FAILURE:
      return {
        ...state,
        isDeletingTeam: false,
      };
    case DELETING_TEAM_SUCCESS:
      let newState = Object.assign({}, state);
      delete newState[action.teamId];
      return newState;
    default:
      return state;
  }
}
