import React from 'react';
import LocationIcon from '@material-ui/icons/LocationOn';
import {
  location,
  locationIcon,
  locationLink,
} from './eventLocation.module.css';

export default function EventLocation({ address, placeId }) {
  let formattedAddress = address;
  if (address && address.includes(',')) {
    formattedAddress = address.slice(0, address.indexOf(','));
  }
  return (
    <div className={location}>
      <div className={locationLink}>
        <LocationIcon className={locationIcon} />
        {formattedAddress}
      </div>
    </div>
  );
}
