import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { keys } from 'lodash';
import { Seasons } from 'components';
import {
  submitAddSeasonForm,
  updateSelectedSeason,
} from 'redux/modules/seasons';

class SeasonsContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleChangeSeason = this.handleChangeSeason.bind(this);
    this.handleAddSeason = this.handleAddSeason.bind(this);
  }
  handleChangeSeason(ev, key) {
    const value = key.props.value;
    if (value === 1) {
      this.handleAddSeason();
    } else {
      this.props.updateSelectedSeason(value, this.props.teamId);
    }
  }
  handleAddSeason() {
    this.props.history.push(`/${this.props.teamNameStr}/addSeason`);
  }
  render() {
    return (
      <Seasons
        teamId={this.props.teamId}
        teamNameStr={this.props.teamNameStr}
        isManager={this.props.isManager}
        seasons={this.props.seasons}
        selectedSeason={this.props.selectedSeason}
        selectedSeasonStr={this.props.selectedSeasonStr}
        onChangeSeason={this.handleChangeSeason}
        isSeasons={this.props.isSeasons}
        showPastGames={this.props.showPastGames}
        onTogglePast={this.props.onTogglePast}
        hasPastGames={this.props.hasPastGames}
        onAddSeason={this.handleAddSeason}
      />
    );
  }
}

SeasonsContainer.propTypes = {
  teamName: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  teamNameStr: PropTypes.string.isRequired,
  isManager: PropTypes.bool.isRequired,
  submitAddSeasonForm: PropTypes.func.isRequired,
  seasons: PropTypes.array.isRequired,
  isSeasons: PropTypes.bool.isRequired,
  selectedSeason: PropTypes.string,
  selectedSeasonStr: PropTypes.string,
  updateSelectedSeason: PropTypes.func.isRequired,
  onTogglePast: PropTypes.func.isRequired,
  showPastGames: PropTypes.bool.isRequired,
};

function mapStateToProps({ seasons, users, teams }, ownProps) {
  const teamId = ownProps.teamId;
  const teamInfo = teams[teamId];
  return {
    teamName: teamInfo && teamInfo.name ? teamInfo.name : '',
    teamId: teamId,
    isManager:
      teamInfo && teamInfo.uid ? users.authedId === teamInfo.uid : false,
    seasons: seasons[teamId] ? seasons[teamId].seasons : [],
    isSeasons: keys(seasons).includes(teamId),
    selectedSeason: seasons[teamId] ? seasons[teamId].selectedSeason : null,
    selectedSeasonStr: seasons[teamId]
      ? seasons[teamId].selectedSeasonStr
      : null,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitAddSeasonForm,
      updateSelectedSeason,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SeasonsContainer);
