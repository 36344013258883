import React from 'react';
import { Field } from 'redux-form';
import { AutoComplete } from 'components/base';

export default function LocationFields({
  submitting,
  dataSource,
  onLocationSelect,
  required,
}) {
  const suggestions = dataSource.map(({ description, id }) => ({
    label: description,
    value: id,
  }));

  return (
    <Field
      name="location"
      component={AutoComplete}
      label="Location"
      disabled={submitting}
      placeholder={`Genesee Park, 123 Home St., Seattle, WA, 98115`}
      fullWidth
      required={required}
      isRestricted
      suggestions={suggestions}
      onSelect={onLocationSelect}
    />
  );
}
