import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1,
    color: 'rgba(56, 56, 56, 0.87)',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(14),
    color: 'rgba(56, 56, 56, 0.54)',
    marginTop: 3,
  },
  expansionPanelDetail: {
    flexWrap: 'wrap',
  },
});

const ExpansionPanelSummary = withStyles({
  root: {},
  content: {
    margin: '20px 0',
  },
  expanded: {},
})(props => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

function ExpansionPanel(props) {
  const { classes, header, subheader, children } = props;
  return (
    <div className={classes.root}>
      <MuiExpansionPanel elevation={2}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <div>
            <Typography className={classes.heading}>{header}</Typography>
            {subheader && (
              <Typography className={classes.subheading}>
                {subheader}
              </Typography>
            )}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionPanelDetail}>
          {children}
        </ExpansionPanelDetails>
      </MuiExpansionPanel>
    </div>
  );
}

ExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExpansionPanel);
