import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(-1.5),
      marginRight: theme.spacing(-1.5),
    },
    rootLink: {
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    innerWrapper: {
      display: 'flex',
      padding: theme.spacing(1.5),
    },
    innerWrapperLink: {
      cursor: 'pointer',
      '&:hover $link': {
        textDecoration: 'underline',
      },
    },
    iconWrapper: {
      width: theme.spacing(4.5),
      marginRight: theme.spacing(1.5),
      display: 'flex',
      flexShrink: 0,
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      fontSize: 17,
      opacity: 0.4,
    },
    description: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    title: {
      color: theme.palette.text.primary,
      fontSize: 14,
      lineHeight: 1.3,
    },
    subtitle: {
      color: theme.palette.text.secondary,
      fontSize: 12,
      lineHeight: 1.5,
    },
    adornment: {
      fontSize: 12,
      alignItems: 'center',
      display: 'flex',
      flexShrink: 0,
      minWidth: 67,
      flexDirection: 'row-reverse',
    },
    link: {
      color: theme.palette.primary.main,
    },
    hideExpanded: {
      height: 0,
      overflow: 'hidden',
    },
  };
};

const EventAttribute = ({
  classes,
  Icon,
  title,
  subtitle,
  adornment,
  onClick,
  expanded,
  children,
}) => (
  <div
    className={cx(classes.root, {
      [classes.rootLink]: onClick,
    })}
  >
    <div
      className={cx(classes.innerWrapper, {
        [classes.innerWrapperLink]: onClick,
      })}
      onClick={onClick}
    >
      <div className={classes.iconWrapper}>
        <Icon className={classes.icon} />
      </div>
      <div className={classes.description}>
        <span className={classes.title}>{title}</span>
        {subtitle ? <span className={classes.subtitle}>{subtitle}</span> : null}
      </div>
      {adornment ? (
        <span
          className={cx(classes.adornment, {
            [classes.link]: onClick,
          })}
        >
          {adornment}
        </span>
      ) : null}
    </div>
    <div
      className={cx({
        [classes.hideExpanded]: !expanded,
      })}
    >
      {children}
    </div>
  </div>
);

EventAttribute.propTypes = {
  classes: PropTypes.object.isRequired,
};

const eventAttribute = withStyles(styles)(EventAttribute);
eventAttribute.displayName = 'EventAttribute';

export default eventAttribute;
