import React from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { TextButton } from 'components/base';

import { container, navContainer, homeLink } from './styles.module.css';

export default function UnauthedNavigation() {
  return (
    <Paper className={container}>
      <nav className={navContainer}>
        <Link className={homeLink} to="/">
          {'Home'}
        </Link>
        <TextButton color="primary" to="/auth">
          Login
        </TextButton>
      </nav>
    </Paper>
  );
}
