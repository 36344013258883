import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, findKey } from 'lodash';
import LinearProgress from '@material-ui/core/LinearProgress';
import { updateSelectedSeason } from 'redux/modules/seasons';
import sharedStyles from 'sharedStyles/styles.module.css';

class EventRedirectContainer extends React.Component {
  componentDidMount() {
    this.handleRedirect(this.props);
  }
  componentDidUpdate() {
    this.handleRedirect(this.props);
  }
  handleRedirect({
    teamId,
    seasonId,
    selectedSeasonId,
    selectedSeasonStr,
    teamNameStr,
    eventStr,
    history,
    updateSelectedSeason,
  }) {
    /*
     * If seasonId from the route and selected seasonId in
     * state differ, updated selected seasonId in state so
     * we fetch events for that seasson
     */
    if (seasonId && selectedSeasonId && seasonId !== selectedSeasonId) {
      updateSelectedSeason(seasonId, teamId);
      /*
       * Else if we have the url strings form teamId,
       * seasonId, and eventId, replace route with
       * event detail route
       */
    } else if (teamNameStr && selectedSeasonStr && eventStr) {
      history.replace(`/${teamNameStr}/${selectedSeasonStr}/${eventStr}`);
    }
  }
  render() {
    return (
      <div className={`${sharedStyles.contentWrapper}`}>
        <LinearProgress variant="indeterminate" style={{ marginTop: 20 }} />
      </div>
    );
  }
}

EventRedirectContainer.propTypes = {
  seasonId: PropTypes.string.isRequired,
  selectedSeasonId: PropTypes.string.isRequired,
  selectedSeasonStr: PropTypes.string.isRequired,
  eventStr: PropTypes.string,
  history: PropTypes.object.isRequired,
  updateSelectedSeason: PropTypes.func.isRequired,
};

function mapStateToProps(
  { seasons },
  { teamId, teamNameStr, location: { pathname } }
) {
  const paths = pathname.split('/');
  const seasonId = paths[3];
  const eventId = paths[4];
  const selectedSeasonId = get(seasons, `${teamId}.selectedSeason`, '');
  const selectedSeasonStr = get(seasons, `${teamId}.selectedSeasonStr`, '');
  const eventsLookup = get(
    seasons,
    `${teamId}.lookups.${selectedSeasonStr}.events`,
    {}
  );
  const eventStr = findKey(eventsLookup, o => o.eventId === eventId);
  return {
    seasonId,
    selectedSeasonId,
    teamNameStr,
    selectedSeasonStr,
    eventStr,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSelectedSeason,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventRedirectContainer);
