import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import cx from 'classnames';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
  formControl: {
    minWidth: 120,
    maxWidth: 160,
    minHeight: 68,
    marginBottom: 8,
  },
  inputRoot: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  inputUnderline: {
    '&:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '&:before': {
      borderBottomColor: theme.palette.secondary.main,
    },
    '&:hover:before': {
      borderBottomColor: theme.palette.secondary.main,
    },
  },
  inputLabel: {
    '&$cssFocused': {
      color: theme.palette.primary.main,
    },
    '&$cssError': {
      color: theme.palette.error.main,
    },
  },
  cssFocused: {},
  cssError: {},
});

class SelectField extends React.Component {
  constructor(props) {
    super(props);

    const { initial } = props.meta;

    this.state = {
      value: initial || '',
    };
  }

  handleChange = event => {
    const { value } = event.target;

    this.props.input.onChange(value);

    this.setState({
      value,
    });
  };

  render() {
    const {
      children,
      classes,
      label,
      className,
      options,
      disabled,
    } = this.props;
    const selectOptions = options
      ? options.map(({ value, label }) => (
          <MenuItem value={value} key={value}>
            {label}
          </MenuItem>
        ))
      : children;
    return (
      <FormControl
        className={cx(classes.formControl, className)}
        disabled={disabled}
      >
        {label ? (
          <InputLabel
            classes={{
              root: classes.inputLabel,
              focused: classes.cssFocused,
              error: classes.cssError,
            }}
          >
            {label}
          </InputLabel>
        ) : null}
        <Select
          value={this.state.value}
          onChange={this.handleChange}
          input={
            <Input
              classes={{
                underline: classes.inputUnderline,
                input: classes.inputRoot,
              }}
            />
          }
        >
          {selectOptions}
        </Select>
      </FormControl>
    );
  }
}

SelectField.propTypes = {
  classes: PropTypes.object.isRequired,
};

const selectField = withStyles(styles)(SelectField);
selectField.displayName = 'SelectField';

selectField.defaultProps = {
  input: {
    onChange: () => true,
  },
  meta: {},
};

selectField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ),
};

export default selectField;
