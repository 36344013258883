import React from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { PlayerCardContainer } from 'containers';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import { ToggleFields } from 'components/base';

// TODO: Do we need PropTypes?

const styles = theme => ({
  wrapper: {
    width: '100%',
    maxWidth: 295,
    paddingLeft: 20,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
      paddingLeft: 0,
      marginTop: 20,
      marginBottom: 80,
    },
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
  },
  inline: {
    display: 'inline',
  },
  toggleWrapper: {
    display: 'flex',
    margin: 10,
    justifyContent: 'center',
  },
  header: {
    margin: 0,
    padding: '16px 16px 6px',
    fontSize: 20,
    fontWeight: 300,
  },
});

const renderPlayers = players =>
  players.map(player => (
    <PlayerCardContainer player={player} key={player.uid} isListItem />
  ));

const coedPlayers = players => {
  const males = filter(players, player => player.gender === 'male');
  const females = filter(players, player => player.gender === 'female');
  return (
    <React.Fragment>
      {females.length ? (
        <React.Fragment>
          <ListSubheader>Females ({females.length})</ListSubheader>
          {renderPlayers(females)}
        </React.Fragment>
      ) : null}
      {males.length ? (
        <React.Fragment>
          <ListSubheader>Males ({males.length})</ListSubheader>
          {renderPlayers(males)}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

const Attendees = ({ classes, players, status, onChangeStatus, isCoed }) => (
  <div className={classes.wrapper}>
    <Paper elevation={1}>
      <h4 className={classes.header}>Roster</h4>
      <div className={classes.toggleWrapper}>
        <ToggleFields
          exclusive
          value={status}
          onChange={onChangeStatus}
          options={[
            {
              value: 'playing',
              label: 'Playing',
            },
            {
              value: 'invited',
              label: 'Invited',
            },
            {
              value: 'notPlaying',
              label: 'Not Playing',
            },
          ]}
        />
      </div>
      <List className={classes.root}>
        {isCoed ? coedPlayers(players) : renderPlayers(players)}
        {!players.length ? <ListSubheader>No players</ListSubheader> : null}
      </List>
    </Paper>
  </div>
);

Attendees.propTypes = {
  classes: PropTypes.object,
  players: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      profilePic: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    })
  ),
};

export default withStyles(styles)(Attendees);
