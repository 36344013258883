import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { SeasonsContainer, EventPreviewContainer } from 'containers';
import { TextButton } from 'components/base';
import styles from 'sharedStyles/styles.module.css';

function formatEvents({ events, teamId, teamNameStr, selectedSeasonStr }) {
  return events.map(event => (
    <EventPreviewContainer
      key={event.eventId}
      event={event}
      teamId={teamId}
      teamNameStr={teamNameStr}
      selectedSeasonStr={selectedSeasonStr}
    />
  ));
}

function noUpcomingEvents({
  isManager,
  events,
  isLoaded,
  numberOfEvents,
  onTogglePast,
  teamNameStr,
  selectedSeason,
  selectedSeasonStr,
}) {
  if (isLoaded && !events.length) {
    const subtitle = isManager
      ? 'You may want to change the season above. Click Add Games above to create an event.'
      : 'You may want to change the season above.';
    return (
      <Card className={`${styles.eventContainer} ${styles.cardContainer}`}>
        <CardContent>
          <Typography variant="h6" component="h2">
            No upcoming games for this season.
          </Typography>
          <Typography component="p">{subtitle}</Typography>
        </CardContent>
        <CardActions>
          {numberOfEvents > 0 && (
            <TextButton onClick={onTogglePast}>Show Past Games</TextButton>
          )}
          {isManager && selectedSeason && (
            <TextButton
              to={{ pathname: `/${teamNameStr}/${selectedSeasonStr}/addEvent` }}
            >
              Add Game
            </TextButton>
          )}
        </CardActions>
      </Card>
    );
  }
}

export default function Schedule(props) {
  return (
    <div className={`${styles.cardsWrapper} ${styles.contentWrapper}`}>
      <SeasonsContainer
        teamId={props.teamId}
        teamNameStr={props.teamNameStr}
        showPastGames={props.showPastGames}
        onTogglePast={props.onTogglePast}
        hasPastGames={props.hasPastGames}
        history={props.history}
      />
      {formatEvents(props)}
      {noUpcomingEvents(props)}
    </div>
  );
}
