import React from 'react';
import PropTypes from 'prop-types';

import { ExpansionPanel, PlayerCondensed } from 'components/base';

export default function Invited(props) {
  if (!props.invites.length) {
    return null;
  }
  return (
    <ExpansionPanel
      header="Invited Players"
      subheader="A list of players invited to join the team."
    >
      {props.invites.map(({ uid, firstName, lastName }) => (
        <PlayerCondensed key={uid} firstName={firstName} lastName={lastName} />
      ))}
    </ExpansionPanel>
  );
}

Invited.propTypes = {
  invites: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    })
  ),
};
