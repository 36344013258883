import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { map, get, isEqual } from 'lodash';
import { format } from 'date-fns';
import { TeamSeasons } from 'components';
import { updateSeason, deleteSeason, addSeason } from 'redux/modules/seasons';

class TeamSeasonsContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      data: props.seasonsData,
      isFetching: props.isFetching,
      savingData: false,
    };

    this.onRowAdd = this.onRowAdd.bind(this);
    this.onRowUpdate = this.onRowUpdate.bind(this);
    this.onRowDelete = this.onRowDelete.bind(this);
  }
  componentDidUpdate(prevProps) {
    const current = this.state.data.map(({ tableData, ...data }) => data);
    const next = this.props.seasonsData.map(({ tableData, ...data }) => data);
    if (
      (prevProps.isFetching && !this.props.isFetching) ||
      !isEqual(current, next)
    ) {
      this.setState({
        isFetching: this.props.isFetching,
        data: this.props.seasonsData,
      });
    }
  }
  onRowAdd({ name }) {
    /*
     * Set savingData to our data without the new season
     * so the table doesn't update while in the edit UI
     */
    this.setState({ savingData: this.state.data });
    return (
      this.props
        .addSeason({ name, teamId: this.props.teamId })
        // remove savingData so we use the updated data
        .then(() => this.setState({ savingData: false }))
    );
  }
  onRowUpdate({ seasonId, name }) {
    this.setState({ savingData: this.state.data });
    return this.props
      .updateSeason(this.props.teamId, seasonId, { name })
      .then(() => this.setState({ savingData: false }));
  }
  onRowDelete({ seasonId }) {
    this.setState({ savingData: this.state.data });
    return this.props
      .deleteSeason(this.props.teamId, seasonId)
      .then(() => this.setState({ savingData: false }));
  }
  render() {
    const { name, deviceSize, inlineCaption } = this.props;
    const { data, savingData, isFetching } = this.state;
    return isFetching ? null : (
      <TeamSeasons
        data={savingData ? savingData : data}
        name={name}
        editable={{
          onRowAdd: this.onRowAdd,
          onRowUpdate: this.onRowUpdate,
          onRowDelete: this.onRowDelete,
        }}
        deviceSize={deviceSize}
        inlineCaption={inlineCaption}
      />
    );
  }
}

TeamSeasonsContainer.propTypes = {
  teamId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  seasonsData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      created: PropTypes.number.isRequired,
      seasonId: PropTypes.string.isRequired,
      seasonStr: PropTypes.string.isRequired,
      teamNameStr: PropTypes.string.isRequired,
      teamId: PropTypes.string.isRequired,
    })
  ),
  deviceSize: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  updateSeason: PropTypes.func.isRequired,
  deleteSeason: PropTypes.func.isRequired,
  addSeason: PropTypes.func.isRequired,
  inlineCaption: PropTypes.bool.isRequired,
};

function mapStateToProps(
  { seasons, viewport: { deviceSize, width } },
  { teamId, teamName, teamNameStr }
) {
  const dateFormat = deviceSize === 'xs' ? 'M/d/yy' : 'MMMM do, yyyy';
  const seasonsData = map(
    get(seasons, `${teamId}.lookups`, {}),
    ({ name, created, seasonId, seasonStr }) => ({
      name,
      created,
      seasonId,
      seasonStr,
      teamNameStr,
      teamId,
      date: format(new Date(created), dateFormat),
    })
  ).reverse();
  return {
    teamId,
    seasonsData,
    isFetching: seasons.isFetching,
    name: teamName,
    deviceSize,
    inlineCaption: width > 790,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSeason,
      deleteSeason,
      addSeason,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamSeasonsContainer);
