import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { get } from 'lodash';
import ProfileForm from './ProfileForm';
import validate from '../../helpers/formValidate/profile';

// TODO: Do we need React?

let ProfileEdit = reduxForm({
  form: 'profileEdit',
  validate,
  enableReinitialize: true,
})(ProfileForm);

ProfileEdit = connect(({ users, authedFiles: { files }, form }) => {
  const userInfo = users[users.authedId].info;
  const profilePic = get(files, userInfo.profilePic, false);

  return {
    initialValues: {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      gender: userInfo.gender,
      email: userInfo.email,
      zip: userInfo.zip,
      profilePic: {
        dataURL: false,
        imageURL: profilePic,
      },
    },
  };
})(ProfileEdit);

export default ProfileEdit;
