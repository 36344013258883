import axios from 'axios';
import { firebaseAuth, db } from '../config/constants';
const lambdaURL = 'https://us-central1-coach-cheetah-b6dcf.cloudfunctions.net';

export default function phoneAuth(token) {
  return firebaseAuth().signInWithCustomToken(token);
}

export function sendVerificationCodeRequest(phone) {
  return axios.post(`${lambdaURL}/sendVerificationCode`, {
    phone: phone,
  });
}

export function confirmVerificationCodeRequest(phone, code) {
  return axios.post(`${lambdaURL}/confirmVerificationCode`, {
    phone: phone,
    code: code,
  });
}

export function getPhoneUser(phone) {
  return db
    .doc(`users/${phone}`)
    .get()
    .then(doc => doc.data());
}

export function deletePhoneUser(phone) {
  return db.doc(`users/${phone}`).delete();
}

function syncRosterData(teamId, phone, authId, batch) {
  var oldRosterRef = db.doc(`teams/${teamId}/roster/${phone}`);
  return oldRosterRef.get().then(player => {
    var rosterData = player.data();
    rosterData.uid = authId;
    var newRosterRef = db.doc(`teams/${teamId}/roster/${authId}`);
    var batch = db.batch();
    batch.set(newRosterRef, rosterData);
    batch.delete(oldRosterRef);
    return batch.commit();
  });
}

export function syncInviteData(phone, user, authId) {
  return db
    .collection(`users/${phone}/invites`)
    .get()
    .then(querySnapshot => {
      var batch = db.batch();
      querySnapshot.forEach(invite => {
        var inviteData = invite.data();
        inviteData.uid = authId;
        var oldInviteRef = db.doc(`users/${phone}/invites/${invite.id}`);
        var newInviteRef = db.doc(`users/${authId}/invites/${invite.id}`);
        batch.set(newInviteRef, inviteData);
        batch.delete(oldInviteRef);
        syncRosterData(invite.id, phone, authId, batch);
      });
      return batch.commit();
    });
}

export function syncTeamData(phone, user, authId) {
  // sync users teams if needed
  return db
    .collection(`users/${phone}/teams`)
    .get()
    .then(querySnapshot => {
      var batch = db.batch();
      querySnapshot.forEach(team => {
        var oldTeamRef = db.doc(`users/${phone}/teams/${team.id}`);
        var newTeamRef = db.doc(`users/${authId}/teams/${team.id}`);
        batch.set(newTeamRef, team.data());
        batch.delete(oldTeamRef);
        syncRosterData(team.id, phone, authId, batch);
      });
      return batch.commit();
    });
}
