import React from 'react';
import { PlayerCardContainer } from 'containers';
import {
  Title,
  PrimaryButton,
  ExpansionPanel,
  PlayerCondensed,
} from 'components/base';
import {
  rosterHeader,
  playersWrapper,
  reserveWrapper,
  headerLink,
  inviteLink,
} from './styles.module.css';

function renderPlayers(players, isReserved) {
  const playerCards = players.map((player, index) => (
    <PlayerCardContainer key={index} player={player} isGrayed={isReserved} />
  ));
  return playerCards;
}

function renderReservePlayers(players) {
  return players.map(player => (
    <PlayerCondensed
      key={player.uid}
      firstName={player.firstName}
      lastName={player.lastName}
    />
  ));
}

export default function Roster(props) {
  return (
    <div>
      <div className={rosterHeader}>
        <Title
          title="Active Roster"
          subtitle="List of active players on your team"
        />
        <PrimaryButton
          to={`/${props.teamNameStr}/invite`}
          className={headerLink}
        >
          Invite Players
        </PrimaryButton>
      </div>
      <div className={playersWrapper}>{renderPlayers(props.active, false)}</div>
      <PrimaryButton to={`/${props.teamNameStr}/invite`} className={inviteLink}>
        Invite Player
      </PrimaryButton>
      {props.reserve.length ? (
        <ExpansionPanel
          header="Reserve Players"
          subheader="Players available for when we need subs."
        >
          <div className={reserveWrapper}>
            {renderReservePlayers(props.reserve)}
          </div>
        </ExpansionPanel>
      ) : null}
    </div>
  );
}
