import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Invited } from 'components';
import { updateInvites, submitInviteEditForm } from 'redux/modules/invites';
import { deleteInvite } from 'helpers/api';

class InvitedContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      deleteOpen: false,
      editOpen: false,
      invite: null,
      isUpdating: false,
    };

    this.handleMenuTouch = this.handleMenuTouch.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }
  handleMenuTouch(event, child) {
    const action = child.props['data-action'];
    const invite = child.props['data-invite'];
    switch (action) {
      case 'delete':
        return this.setState({
          deleteOpen: true,
          invite: invite,
        });
      case 'edit':
        return this.setState({
          editOpen: true,
          invite: invite,
        });
      default:
        return;
    }
  }
  handleModalClose() {
    return this.setState({
      deleteOpen: false,
      editOpen: false,
      invite: null,
      isUpdating: false,
    });
  }
  handleDelete() {
    this.setState({ isUpdating: true });
    deleteInvite(this.state.invite)
      .then(() => this.handleModalClose())
      .catch(error => {
        console.log('error', error);
      });
  }
  handleEdit(values) {
    this.props.submitInviteEditForm();
    this.setState({ editOpen: false });
  }
  render() {
    return (
      <Invited
        {...this.state}
        invites={this.props.invites}
        teamId={this.props.teamId}
        uid={this.props.uid}
        handleMenuTouch={this.handleMenuTouch}
        handleModalClose={this.handleModalClose}
        handleDelete={this.handleDelete}
        handleEdit={this.handleEdit}
      />
    );
  }
}

InvitedContainer.propTypes = {
  teamId: PropTypes.string.isRequired,
  updateInvites: PropTypes.func.isRequired,
  submitInviteEditForm: PropTypes.func.isRequired,
  invites: PropTypes.array.isRequired,
  uid: PropTypes.string.isRequired,
};

function mapStateToProps({ users, rosters }, ownProps) {
  let invited = [];
  if (rosters[ownProps.teamId]) {
    if (rosters[ownProps.teamId].invites) {
      invited = rosters[ownProps.teamId].invites;
    }
  }
  return {
    teamId: ownProps.teamId,
    invites: invited,
    uid: users.authedId,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateInvites,
      submitInviteEditForm,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(InvitedContainer);
