import { reduxForm } from 'redux-form';
import TeamInfoForm from '../Form/TeamInfoForm';
import validate from 'helpers/formValidate/team';

// TODO: Do we need React?

let TeamInfo = reduxForm({
  form: 'teamInfo',
  validate,
  enableReinitialize: true,
})(TeamInfoForm);

export default TeamInfo;
