import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => {
  return {
    button: {
      margin: '0 4px',
    },
    buttonIconLeft: {
      paddingRight: theme.spacing(2),
    },
    buttonIconRight: {
      paddingLeft: theme.spacing(2),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
  };
};

const StyledButton = ({
  children,
  classes,
  className,
  to,
  icon,
  iconLeft,
  ...restOfProps
}) => {
  return (
    <Button
      className={cx(
        classes.button,
        {
          [classes.buttonIconLeft]: icon && iconLeft,
          [classes.buttonIconRight]: icon && !iconLeft,
        },
        className
      )}
      component={to ? Link : 'button'}
      to={to}
      {...restOfProps}
    >
      {icon && iconLeft
        ? React.cloneElement(icon, { className: classes.leftIcon })
        : null}
      {children}
      {icon && !iconLeft
        ? React.cloneElement(icon, { className: classes.rightIcon })
        : null}
    </Button>
  );
};

const TextButton = withStyles(styles)(StyledButton);
TextButton.displayName = 'TextButton';

TextButton.defaultProps = {
  iconLeft: true,
};

TextButton.propTypes = {
  iconLeft: PropTypes.bool,
  icon: PropTypes.node,
};

export default TextButton;
