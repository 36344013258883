import { destroy, reset, submit, change } from 'redux-form';

const UPDATING_INVITES = 'UPDATING_INVITES';

export function destroyInviteForm() {
  return function(dispatch) {
    dispatch(destroy('inviteForm'));
  };
}

export function resetInviteForm() {
  return function(dispatch) {
    dispatch(change('inviteForm', 'status', ''));
    dispatch(reset('inviteForm'));
  };
}

export function submitInviteEditForm() {
  return function(dispatch) {
    dispatch(submit('inviteEdit'));
  };
}

function updatingInvites(teamId, invites, lastUpdated) {
  return {
    type: UPDATING_INVITES,
    teamId,
    invites,
    lastUpdated,
  };
}

export function updateInvites(teamId, invites, lastUpdated) {
  return function(dispatch) {
    var invitesArray = [];
    if (invites) {
      for (let prop in invites) {
        if (invites.hasOwnProperty(prop)) {
          invitesArray.push(invites[prop]);
        }
      }
    }
    dispatch(updatingInvites(teamId, invitesArray, lastUpdated));
  };
}

const teamInitialState = {
  invites: [],
  lastUpdated: 0,
};

function teamInvites(state = teamInitialState, action) {
  switch (action.type) {
    case UPDATING_INVITES:
      return {
        ...state,
        invites: action.invites,
      };
    default:
      return state;
  }
}

const initialState = {
  error: '',
};

export default function invites(state = initialState, action) {
  switch (action.type) {
    case UPDATING_INVITES:
      return {
        ...state,
        [action.teamId]: teamInvites(state[action.teamId], action),
      };
    default:
      return state;
  }
}
