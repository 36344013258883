import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Roster } from 'components';
import { addPlayer, deletePlayer } from 'redux/modules/rosters';
import { setNavItem } from 'redux/modules/usersTeams';
import sharedStyles from 'sharedStyles/styles.module.css';

class RosterContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      deleteOpen: false,
      statusOpen: false,
      player: null,
      actionStatus: null,
      actionFirstName: '',
      actionLastName: '',
      isUpdating: false,
    };

    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSwap = this.handleSwap.bind(this);
    this.handleMenuTouch = this.handleMenuTouch.bind(this);
  }
  componentDidMount() {
    this.props.setNavItem('roster');
  }
  handleModalClose() {
    this.setState({
      deleteOpen: false,
      statusOpen: false,
      player: null,
      actionStatus: null,
      actionFirstName: '',
      actionLastName: '',
      isUpdating: false,
    });
  }
  handleDelete() {
    this.setState({ isUpdating: true });
    return this.props
      .deletePlayer(
        this.props.teamId,
        this.state.player.uid,
        this.state.actionStatus
      )
      .then(() => this.handleModalClose());
  }
  handleSwap() {
    this.setState({ isUpdating: true });
    const status = this.state.actionStatus ? 'reserve' : 'active';
    return this.props
      .deletePlayer(
        this.props.teamId,
        this.state.player.uid,
        this.state.actionStatus
      )
      .then(() =>
        this.props.addPlayer(this.props.teamId, this.state.player, status, {
          gender: this.props.gender,
          name: this.props.teamName,
          sport: this.props.sport,
          teamId: this.props.teamId,
          status: !this.state.actionStatus ? 'active' : 'reserve',
          uid: this.state.player.uid,
        })
      )
      .then(() => this.handleModalClose());
  }
  handleMenuTouch(event, child) {
    const uid = child.props['data-uid'];
    const active = child.props['data-active'];
    const players = active ? this.props.active : this.props.reserve;
    let player = null;
    for (let i = 0; i < players.length; i++) {
      if (players[i].uid === uid) {
        player = players[i];
        break;
      }
    }
    switch (child.props['data-action']) {
      case 'swap':
        return this.setState({
          statusOpen: true,
          player: player,
          actionStatus: active,
          actionFirstName: player.firstName,
          actionLastName: player.lastName,
        });
      case 'delete':
        return this.setState({
          deleteOpen: true,
          player: player,
          actionStatus: active,
          actionFirstName: player.firstName,
          actionLastName: player.lastName,
        });
      default:
        return;
    }
  }
  render() {
    return (
      <div
        className={`${sharedStyles.contentWrapper} ${sharedStyles.rosterWrapper}`}
      >
        <Roster
          {...this.props}
          {...this.state}
          handleDelete={this.handleDelete}
          handleModalClose={this.handleModalClose}
          handleMenuTouch={this.handleMenuTouch}
          handleSwap={this.handleSwap}
        />
      </div>
    );
  }
}

RosterContainer.propTypes = {
  teamId: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  teamNameStr: PropTypes.string.isRequired,
  gender: PropTypes.string.isRequired,
  isManager: PropTypes.bool.isRequired,
  sport: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  managerId: PropTypes.string.isRequired,
  active: PropTypes.array.isRequired,
  reserve: PropTypes.array.isRequired,
  addPlayer: PropTypes.func.isRequired,
  deletePlayer: PropTypes.func.isRequired,
  setNavItem: PropTypes.func.isRequired,
};

function mapStateToProps(
  { teams, users, rosters },
  { teamId, isManager, teamName, gender, sport }
) {
  let active = [];
  let reserve = [];
  if (rosters[teamId]) {
    if (rosters[teamId].active) {
      active = rosters[teamId].active;
    }
    if (rosters[teamId].reserve) {
      reserve = rosters[teamId].reserve;
    }
  }
  return {
    teamId,
    teamName,
    isManager,
    gender,
    sport,
    uid: users.authedId,
    managerId: teams[teamId].uid,
    active: active,
    reserve: reserve,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addPlayer,
      deletePlayer,
      setNavItem,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RosterContainer);
