import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Invite } from 'components';

class InviteContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      expanded: false,
    };

    this.handleExpandChange = this.handleExpandChange.bind(this);
  }
  handleExpandChange(expanded) {
    this.setState({ expanded: expanded });
  }
  render() {
    return (
      <Invite
        expanded={this.state.expanded}
        handleExpand={this.handleExpandChange}
        teamId={this.props.teamId}
        gender={this.props.gender}
        isManager={this.props.isManager}
      />
    );
  }
}

InviteContainer.propTypes = {
  teamId: PropTypes.string.isRequired,
  isManager: PropTypes.bool.isRequired,
  gender: PropTypes.string.isRequired,
};

function mapStateToProps({ teams, users }, { teamId, gender }) {
  return {
    teamId,
    isManager: users.authedId === teams[teamId].uid,
    gender,
  };
}

export default connect(mapStateToProps, null)(InviteContainer);
