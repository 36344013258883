import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { LocationFieldsContainer } from '../../containers';
import {
  Title,
  TextField,
  SelectField,
  RadioButtons,
  DateTimePicker,
  PrimaryButton,
  TextButton,
  ColorBlock,
} from 'components/base';

import styles from 'sharedStyles/styles.module.css';

export default function EventForm({
  handleSubmit,
  submitting,
  onUpdateEvent,
  onCancelUpdate,
  seasons,
  teamName,
  teamHomeColor,
  teamAwayColor,
  valid,
  form,
  isEdit,
}) {
  return (
    <form onSubmit={handleSubmit(onUpdateEvent)}>
      <Title
        title={isEdit ? 'Edit Game' : 'Add Game'}
        subtitle={
          isEdit
            ? `Edit event for ${teamName}`
            : `Add an event for ${teamName}.`
        }
      />
      <Field
        name="oldSeasonId"
        component={TextField}
        fullWidth
        label="Old Season ID"
        className={styles.hiddenField}
      />
      <Field
        name="opponent"
        required
        component={TextField}
        label="Opponent"
        disabled={submitting}
      />
      <Field
        name="isHome"
        required
        label="Uniform"
        options={[
          {
            value: 'away',
            label: <ColorBlock color={teamAwayColor} text={'Away'} />,
            disabled: submitting,
          },
          {
            value: 'home',
            label: <ColorBlock color={teamHomeColor} text={'Home'} />,
            disabled: submitting,
          },
        ]}
        component={RadioButtons}
        disabled={submitting}
      />
      <Field
        name="dateTime"
        required
        label="Start Date & Time"
        component={DateTimePicker}
        disabled={submitting}
      />
      <div>
        <LocationFieldsContainer submitting={submitting} form={form} required />
        <Field
          name="directions"
          component={TextField}
          label={'Additional Directions'}
          helperText="i.e. Field #7, Use back entrance"
          disabled={submitting}
          inputProps={{
            maxLength: 20,
          }}
        />
      </div>
      <Field
        name="seasonId"
        component={SelectField}
        label="Season"
        options={seasons}
        disabled={submitting}
      />
      <div className={styles.formActions}>
        <PrimaryButton type="submit" disabled={!valid || submitting}>
          {isEdit ? 'Update Game' : 'Add Game'}
        </PrimaryButton>
        {isEdit && (
          <TextButton onClick={onCancelUpdate}>Cancel Update</TextButton>
        )}
      </div>
    </form>
  );
}

EventForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  onUpdateEvent: PropTypes.func.isRequired,
  onCancelUpdate: PropTypes.func,
  teamName: PropTypes.string.isRequired,
  teamHomeColor: PropTypes.string,
  teamAwayColor: PropTypes.string,
  valid: PropTypes.bool.isRequired,
};
