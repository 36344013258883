import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import GroupIcon from '@material-ui/icons/Group';
import { useHistory } from 'react-router';
import { stringToURL } from 'helpers/strings';
import { Avatar, PopoverMenu } from 'components/base';
import { teamToggleContainer, menuActiveTeam } from './styles.module.css';

export default function TeamToggle({
  teams,
  letter,
  activeTeamId,
  onNavToTeam,
  onNavToCreate,
}) {
  let history = useHistory();

  const teamLinks = teams.map(team => {
    const teamId = team[0];
    const teamName = team[1];
    return (
      <MenuItem
        onClick={() => history.push(`/${stringToURL(teamName)}/schedule`)}
        key={teamId}
      >
        <span className={teamId === activeTeamId ? menuActiveTeam : null}>
          {teamName}
        </span>
      </MenuItem>
    );
  });

  return (
    <div className={teamToggleContainer}>
      <PopoverMenu
        menuItems={
          <React.Fragment>
            {teamLinks}
            {teams.length && <Divider />}
            <MenuItem onClick={() => history.push('/createTeam')}>
              Create Team
            </MenuItem>
          </React.Fragment>
        }
        menuMargin="-17px 0 0 0"
      >
        <IconButton>
          <Avatar
            size={30}
            style={{
              backgroundColor: letter
                ? 'rgb(238, 169, 4)'
                : 'rgb(153, 153, 153)',
            }}
          >
            {letter ? letter : <GroupIcon style={{ fontSize: 20 }} />}
          </Avatar>
        </IconButton>
      </PopoverMenu>
    </div>
  );
}

TeamToggle.defaultProps = {
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'center',
  },
  targetOrigin: {
    horizontal: 'right',
    vertical: 'top',
  },
};

TeamToggle.protoTypes = {
  teams: PropTypes.array,
  letter: PropTypes.string,
  activeTeamId: PropTypes.string.isRequired,
  onNavToTeam: PropTypes.func.isRequired,
  onNavToCreate: PropTypes.func.isRequired,
};
