import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableHeader, MTableToolbar } from 'material-table';
import cx from 'classnames';

import { forwardRef } from 'react';

import AddIcon from '@material-ui/icons/Add';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddIcon {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const styles = theme => ({
  root: {
    paddingLeft: 0,
  },
  caption: {
    position: 'absolute',
    bottom: 1,
  },
  captionDense: {
    position: 'absolute',
    bottom: -15,
  },
  captionNested: {
    padding: `${theme.spacing(2)}px ${theme.spacing(0.5)}px !important`,
  },
});

class Table extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      emptyRowsWhenPaging: props.data.length > props.pageSize || props.Filters,
      pageSize: props.pageSize,
    };

    this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this);
    this.getMinHeight = this.getMinHeight.bind(this);
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (
      isEqual(this.props.data, nextProps.data) &&
      isEqual(this.state, nextState) &&
      isEqual(this.props.filterProps, nextProps.filterProps) &&
      this.props.inlineCaption === nextProps.inlineCaption
    ) {
      return false;
    }
    return true;
  }
  onChangeRowsPerPage(pageSize) {
    this.setState({
      emptyRowsWhenPaging: this.props.data.length > pageSize,
      pageSize,
    });
  }
  getMinHeight() {
    const rowHeight = this.props.padding === 'default' ? 53 : 36;
    const filterHeight = this.props.Filters ? 34 : 0;
    if (this.state.emptyRowsWhenPaging) {
      return rowHeight * (this.state.pageSize + 1) + 2 + filterHeight;
    } else {
      return undefined;
    }
  }
  render() {
    const {
      title,
      columns,
      data,
      caption,
      Container,
      paging,
      search,
      editable,
      localization,
      classes,
      pageSize,
      padding,
      actions,
      Filters,
      filterProps,
      detailPanel,
      inlineCaption,
      showFirstLastPageButtons,
    } = this.props;
    const shouldPage =
      typeof paging === 'boolean' ? paging : data.length > pageSize;
    return (
      <MaterialTable
        title={title}
        columns={columns}
        data={data}
        icons={tableIcons}
        components={{
          Container,
          Header: props => (
            <React.Fragment>
              <caption
                className={cx({
                  [classes.caption]:
                    shouldPage && inlineCaption && padding === 'default',
                  [classes.captionDense]:
                    shouldPage && inlineCaption && padding === 'dense',
                  [classes.captionNested]: padding === 'dense',
                })}
              >
                {caption}
              </caption>
              {Filters && <Filters {...filterProps} />}
              <MTableHeader {...props} />
            </React.Fragment>
          ),
          Toolbar: props => (
            <MTableToolbar
              {...props}
              classes={{
                root: classes.root,
              }}
            />
          ),
        }}
        options={{
          search: typeof search === 'boolean' ? search : shouldPage,
          emptyRowsWhenPaging: this.state.emptyRowsWhenPaging,
          paging: shouldPage,
          pageSize: this.state.pageSize,
          minBodyHeight: this.getMinHeight(),
          draggable: false,
          padding,
          showFirstLastPageButtons,
        }}
        editable={editable}
        localization={localization}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        detailPanel={detailPanel}
        actions={actions}
      />
    );
  }
}

Table.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  padding: PropTypes.oneOf(['default', 'dense']),
  caption: PropTypes.string.isRequired,
  Container: PropTypes.func,
  search: PropTypes.bool,
  paging: PropTypes.bool,
  emptyRowsWhenPaging: PropTypes.bool,
  editable: PropTypes.shape({
    onRowAdd: PropTypes.func,
    onRowUpdate: PropTypes.func,
    onRowDelete: PropTypes.func,
  }),
  pageSize: PropTypes.number,
  inlineCaption: PropTypes.bool,
  showFirstLastPageButtons: PropTypes.bool,
};

Table.defaultProps = {
  Container: props => <div {...props} />,
  emptyRowsWhenPaging: false,
  pageSize: 5,
  padding: 'default',
  filterProps: {},
  inlineCaption: true,
  showFirstLastPageButtons: false,
};

export default withStyles(styles)(Table);
