import React from 'react';
import { PhoneAuthContainer } from '../../containers';

export default function ProfilePhone({ phone, onScreenChange, fbId, linkFB }) {
  return (
    <div>
      <PhoneAuthContainer handleAuth={false} />
    </div>
  );
}
