import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { get, find } from 'lodash';
import { Team } from 'components';
import { db } from 'config/constants';
import { fetchTeam } from 'redux/modules/teams';
import { updatePlayers } from 'redux/modules/rosters';
import {
  updateSeasons,
  updateSelectedSeason,
  addEventsToSeason,
} from 'redux/modules/seasons';
import { updateEvents } from 'redux/modules/events';
import {
  ScheduleContainer,
  EventContainer,
  RosterContainer,
  ManageTeamContainer,
  EventFormContainer,
  InviteContainer,
  AddSeasonContainer,
  EventRedirectContainer,
} from 'containers';

class TeamContainer extends React.Component {
  static getDerivedStateFromProps(props) {
    if (!props.teamInfo && props.teamId) {
      props.fetchTeam(props.teamId);
    }
    return null;
  }
  constructor(props, context) {
    super(props, context);

    this.state = {
      isMobileNav: true,
      scrollY: 0,
      innerHeight: 0,
      navItem: null,
    };

    this.rosterSubscribe = this.rosterSubscribe.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleRouteChange = this.handleRouteChange.bind(this);
  }
  componentDidMount() {
    if (this.props.iOS) {
      this.setState({
        innerHeight: window.innerHeight,
      });
      window.addEventListener('resize', this.handleResize);
    } else {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
    if (this.props.teamId && (!this.seasonsRef || !this.rosterRef)) {
      this.rosterSubscribe(this.props.teamId);
      this.seasonsSubscribe(this.props.teamId);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.teamId !== this.props.teamId ||
      !this.rosterSubscribe ||
      !this.seasonsSubscribe
    ) {
      this.rosterRef && this.rosterRef();
      this.rosterSubscribe(this.props.teamId);
      this.seasonsRef && this.seasonsRef();
      this.seasonsSubscribe(this.props.teamId);
    }
    if (
      this.props.teamId &&
      this.props.selectedSeason &&
      (prevProps.selectedSeason !== this.props.selectedSeason ||
        !this.eventsRef)
    ) {
      this.eventsRef && this.eventsRef();
      this.eventsSubscribe(this.props.teamId, this.props.selectedSeason);
    }
  }
  rosterSubscribe(id) {
    this.rosterRef = db
      .collection(`teams/${id}/roster`)
      .onSnapshot(querySnapshot => {
        let players = [];
        querySnapshot.forEach(doc => {
          if (doc.exists) {
            players.push(doc.data());
          }
        });
        this.props.updatePlayers(this.props.teamId, players, Date.now());
      });
  }
  seasonsSubscribe(teamId) {
    this.seasonsRef = db
      .collection(`teams/${teamId}/seasons`)
      .orderBy('created')
      .onSnapshot(querySnapshot => {
        var seasonsArray = [];
        querySnapshot.forEach(function(doc) {
          let data = doc.data();
          data.seasonId = doc.id;
          seasonsArray.push(data);
        });
        this.props.updateSeasons(teamId, seasonsArray, Date.now());
        const selectedSeason = seasonsArray[seasonsArray.length - 1]
          ? seasonsArray[seasonsArray.length - 1].seasonId
          : 0;
        if (selectedSeason) {
          this.props.updateSelectedSeason(selectedSeason, teamId);
        }
        this.setState({ selectedSeason });
      });
  }
  eventsSubscribe(teamId, seasonId) {
    this.eventsRef = db
      .collection(`teams/${teamId}/seasons/${seasonId}/events`)
      .orderBy('timestamp')
      .onSnapshot(querySnapshot => {
        let eventsArray = [];
        const timestamp = new Date().getTime();
        let hasPastGames = false;
        querySnapshot.forEach(doc => {
          const eventData = doc.data();
          eventsArray.push({
            ...eventData,
            eventId: doc.id,
          });
          if (eventData.timestamp < timestamp) {
            hasPastGames = true;
          }
        });
        this.props.updateEvents(eventsArray);
        this.props.addEventsToSeason(
          teamId,
          seasonId,
          eventsArray,
          hasPastGames
        );
      });
  }
  componentWillUnmount() {
    if (this.props.iOS) {
      window.removeEventListener('resize', this.handleResize);
    } else {
      window.removeEventListener('scroll', this.handleScroll);
    }
    this.rosterRef && this.rosterRef();
    this.seasonsRef && this.seasonsRef();
  }
  handleScroll(ev) {
    const scrollY = window.scrollY;
    // if scroll is less than 75 pixels and
    // we are hiding the mobile nav
    if (scrollY < 75 && !this.state.isMobileNav) {
      this.setState({
        isMobileNav: true,
      });
    } else if (scrollY > 75 && Math.abs(this.state.scrollY - scrollY) > 40) {
      const isMobileNav = scrollY < this.state.scrollY;
      this.setState({
        scrollY,
        isMobileNav,
      });
    }
  }
  handleResize(ev) {
    const innerHeight = window.innerHeight;
    const isMobileNav = innerHeight < this.state.innerHeight;
    this.setState({
      innerHeight,
      isMobileNav,
    });
  }
  handleRouteChange(event, route) {
    this.props.history.push(`/${this.props.teamNameStr}/${route}`);
  }
  render() {
    return (
      <Team
        onRouteChange={this.handleRouteChange}
        teamName={this.props.teamName}
        sport={this.props.sport}
        gender={this.props.gender}
        isManager={this.props.isManager}
        selectedValue={this.props.navItem}
        teamInfo={this.props.teamInfo}
        isMobileNav={this.state.isMobileNav}
      >
        <Switch>
          <Route
            exact
            path="/:teamName/schedule"
            render={props => <ScheduleContainer {...props} {...this.props} />}
          />
          <Route
            exact
            path="/:teamName/roster"
            render={props => <RosterContainer {...props} {...this.props} />}
          />
          <Route
            path="/:teamName/manage"
            render={props => <ManageTeamContainer {...props} {...this.props} />}
          />
          <Route
            exact
            path="/:teamName/invite"
            render={props => <InviteContainer {...props} {...this.props} />}
          />
          <Route
            exact
            path="/:teamName/addSeason"
            render={props => <AddSeasonContainer {...props} {...this.props} />}
          />
          <Route
            exact
            path="/:teamName/:seasonName/addEvent"
            render={props => <EventFormContainer {...props} {...this.props} />}
          />
          <Route
            exact
            path="/:teamName/:seasonName/:eventName"
            render={props => <EventContainer {...props} {...this.props} />}
          />
          <Route
            exact
            path="/:teamName/:seasonName/:eventName/edit"
            render={props => <EventFormContainer {...props} {...this.props} />}
          />
          <Route
            exact
            path="/:teamName/rd/:seasonId/:eventId"
            render={props => (
              <EventRedirectContainer {...props} {...this.props} />
            )}
          />
        </Switch>
      </Team>
    );
  }
}

TeamContainer.propTypes = {
  teamId: PropTypes.string,
  path: PropTypes.string.isRequired,
  teamName: PropTypes.string,
  teamInfo: PropTypes.bool.isRequired,
  sport: PropTypes.string,
  gender: PropTypes.string,
  isManager: PropTypes.bool.isRequired,
  fetchTeam: PropTypes.func.isRequired,
  updatePlayers: PropTypes.func.isRequired,
  updateSeasons: PropTypes.func.isRequired,
  updateSelectedSeason: PropTypes.func.isRequired,
  updateEvents: PropTypes.func.isRequired,
  addEventsToSeason: PropTypes.func.isRequired,
};

TeamContainer.contextTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

function mapStateToProps(
  { teams, users, usersTeams, viewport, seasons },
  ownProps
) {
  const teamNameStr = ownProps.match.params.teamName;
  const teamId = get(
    usersTeams,
    `${users.authedId}.lookups[${teamNameStr}].id`,
    null
  );
  const teamInfo = teams[teamId];
  var myTeams = get(usersTeams, `${users.authedId}.teams`, []);
  var team = find(myTeams, o => o[0] === teamId);

  return {
    teamId,
    selectedIndex: ownProps && ownProps.path,
    teamInfo: teamInfo ? true : false,
    teamName: teamInfo && teamInfo.name ? teamInfo.name : '',
    teamNameStr,
    sport: teamInfo && teamInfo.sport ? teamInfo.sport : '',
    gender: teamInfo && teamInfo.gender ? teamInfo.gender : '',
    isManager: team ? team[2] : false,
    deviceSize: viewport.deviceSize,
    iOS: viewport.iOS,
    pathname: ownProps.location.pathname,
    selectedSeason: seasons[teamId] ? seasons[teamId].selectedSeason : null,
    navItem: usersTeams.navItem,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchTeam,
      updatePlayers,
      updateSeasons,
      updateSelectedSeason,
      updateEvents,
      addEventsToSeason,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamContainer);
