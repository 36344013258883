import React from 'react';
import CameraIcon from '@material-ui/icons/CameraAlt';
import sharedStyles from '../sharedStyles/styles.module.css';

const ProfileImage = ({ src }) => {
  return (
    <div
      style={{ background: src ? `url(${src})` : '#383838' }}
      className={sharedStyles.profilePic}
    >
      <CameraIcon className={sharedStyles.profilePic_icon} />
    </div>
  );
};

const handleChange = handler => ({ target: { files } }) => {
  var reader = new FileReader();
  const file = files[0];

  reader.onloadend = () => {
    var img = new Image();
    img.onload = function() {
      const { width, height } = this;
      const imgWidth =
        width < height ? 200 : Math.round((200 / height) * width);
      const imgHeight =
        height < width ? 200 : Math.round((200 / width) * height);
      const canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

      canvas.width = imgWidth;
      canvas.height = imgHeight;

      ctx.drawImage(img, 0, 0, imgWidth, imgHeight);
      const newDataURL = canvas.toDataURL();
      handler({ dataURL: newDataURL });
    };
    img.src = reader.result;
  };

  if (file) {
    reader.readAsDataURL(file);
  }
};

export const renderFileInputField = ({
  input: { onChange, onBlur, value, ...inputProps },
  meta: omitMeta,
  view,
  ...props
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <ProfileImage src={value.dataURL ? value.dataURL : value.imageURL} />
      <input
        type="file"
        onChange={handleChange(onChange)}
        onBlur={handleChange(onBlur)}
        {...inputProps}
        {...props}
        style={Object.assign(
          {
            cursor: 'pointer',
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0,
            zIndex: 9999,
          },
          props.style
        )}
      />
    </div>
  );
};
