import React from 'react';
import { ProfileEditContainer } from 'containers';
import styles from 'sharedStyles/styles.module.css';

class ProfileEditRoute extends React.Component {
  render() {
    return (
      <div className={styles.contentWrapper}>
        <ProfileEditContainer />
      </div>
    );
  }
}

export default ProfileEditRoute;
