import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import find from 'lodash/find';
import { TeamToggle } from '../../components';

class TeamToggleContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      anchorEl: null,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
  }
  handleOpen(ev) {
    ev.preventDefault();
    this.setState({
      anchorEl: ev.currentTarget,
    });
  }
  handleRequestClose() {
    this.setState({
      anchorEl: null,
    });
  }
  render() {
    return (
      <TeamToggle
        anchorEl={this.state.anchorEl}
        onOpen={this.handleOpen}
        onRequestClose={this.handleRequestClose}
        teams={this.props.teams}
        activeTeamId={this.props.activeTeamId}
        letter={this.props.letter}
      />
    );
  }
}

TeamToggleContainer.propTypes = {
  teams: PropTypes.array.isRequired,
  activeTeamId: PropTypes.string,
};

function mapStateToProps({ users, usersTeams, routing }) {
  var teams = usersTeams[users.authedId]
    ? usersTeams[users.authedId].teams
    : [];
  var letter = null;
  var activeTeamId = null;
  /*
   * This check finds the second path variable
   * (i.e. /team/W3J0DMHzk3LZTWjSBNsW/schedule)
   * using RegEx. Once we upgrade React Roter to
   * version 4, this will be unneeded.
   */
  if (
    teams.length &&
    routing &&
    routing.locationBeforeTransitions &&
    routing.locationBeforeTransitions.pathname &&
    routing.locationBeforeTransitions.pathname.includes('/team/')
  ) {
    var path = routing.locationBeforeTransitions.pathname;
    activeTeamId = path.match('^/[^/]+/([^/]+)/')[1];
    var team = find(teams, o => o[0] === activeTeamId);
    letter = team[1][0];
  }

  return {
    teams,
    letter,
    activeTeamId,
  };
}

export default connect(mapStateToProps)(TeamToggleContainer);
