/**
 * A reducer for phoneAuth
 * @namespace users
 */
import { destroy as destroyForm } from 'redux-form';
import auth, {
  logout,
  saveUser,
  getUser,
  authWithCredential,
  deleteUser,
} from 'helpers/auth';
import { savePhone, savePhoneLookup } from 'helpers/api';
import { deletePhoneUser } from 'helpers/phoneAuth';
import { unauthPhoneUser } from './phoneAuth';
import providerInfo, {
  clearProviderInfo,
  initialProviderState,
  CLEAR_PROVIDER_INFO,
  FETCHING_PROVIDER_INFO_SUCCESS,
  UPDATE_FIRST_NAME,
  UPDATE_LAST_NAME,
  UPDATE_GENDER,
  UPDATE_EMAIL,
  UPDATE_LOCATION,
} from './users/providerInfo';
import userInfo, {
  initialUserInfo,
  SAVING_ACCESS_TOKEN_SUCCESS,
  UPDATING_USER_PROFILE_SUCCESS,
  UPDATING_USER_PHONE_SUCCESS,
  UPDATING_PROFILE_PICTURE,
  UPDATING_PROFILE_PICTURE_FAILURE,
  UPDATING_PROFILE_PICTURE_SUCCESS,
} from './users/userInfo';
import { fetchFile } from './authedFiles';

export { fetchProviderInfo, fetchProfilePicture } from './users/providerInfo';
export { saveAccessToken } from './users/userInfo';

export const AUTH_USER = 'AUTH_USER';
const UNAUTH_USER = 'UNAUTH_USER';
const FETCHING_USER = 'FETCHING_USER';
const FETCHING_USER_FAILURE = 'FETCHING_USER_FAILURE';
const FETCHING_USER_SUCCESS = 'FETCHING_USER_SUCCESS';
const REMOVE_FETCHING_USER = 'REMOVE_FETCHING_USER';

const REAUTHING_USER = 'REAUTHING_USER';
const REAUTHING_USER_FAILURE = 'REAUTHING_USER_FAILURE';
const REAUTHING_USER_SUCCESS = 'REAUTHING_USER_SUCCESS';

export function authUser(user, timestamp) {
  return {
    type: AUTH_USER,
    user,
    timestamp,
  };
}

function unauthUser() {
  return {
    type: UNAUTH_USER,
  };
}

export function fetchingUser() {
  return {
    type: FETCHING_USER,
  };
}

export function fetchingUserFailure(error) {
  return {
    type: FETCHING_USER_FAILURE,
    error: 'Error fetching user.',
  };
}

export function fetchingUserSuccess() {
  return {
    type: FETCHING_USER_SUCCESS,
  };
}

export function handleAuthedUser(user, timestamp, token) {
  return function(dispatch) {
    return (
      getUser(user.uid)
        .then(savedUser => {
          // if we have a saved user and
          // if we don't have provider data to save
          if (
            savedUser &&
            ((savedUser.fbId && user.providerData.length) ||
              (!savedUser.fbId && !user.providerData.length))
          ) {
            return savedUser;
          }
          return saveUser(user).then(user => {
            if (user.phone) {
              savePhoneLookup(user.phone);
            }
            return user;
          });
        })
        .then(user => {
          if (user.profilePic) {
            dispatch(fetchFile(user.profilePic));
          }
          return dispatch(authUser(user, timestamp));
        })
        .then(() => dispatch(fetchingUserSuccess()))
        // .then(() => dispatch(replace('/feed')))
        .catch(error => {
          console.log('error', error);
        })
    );
  };
}

function reauthingUser() {
  return {
    type: REAUTHING_USER,
  };
}

function reauthingUserFailure() {
  return {
    type: REAUTHING_USER_FAILURE,
  };
}

function reauthingUserSuccess() {
  return {
    type: REAUTHING_USER_SUCCESS,
  };
}

export function handleReauth(credential, phone) {
  return function(dispatch) {
    dispatch(reauthingUser());
    dispatch(fetchingUser());
    return deleteUser()
      .then(() => dispatch(unauthUser()))
      .then(() => authWithCredential(credential))
      .then(() => savePhone(phone))
      .then(() => savePhoneLookup(phone))
      .then(() => deletePhoneUser(phone))
      .then(() => dispatch(reauthingUserSuccess()))
      .then(() => dispatch(fetchingUserSuccess()))
      .catch(error => {
        console.log('err', error);
        return dispatch(reauthingUserFailure());
      });
  };
}

export function fetchUser() {
  return function(dispatch) {
    dispatch(fetchingUser());
    return auth()
      .then(() => dispatch(fetchingUserSuccess()))
      .catch(error => dispatch(fetchingUserFailure(error)));
  };
}

export function logoutAndUnauth() {
  return function(dispatch) {
    dispatch(unauthUser());
    dispatch(unauthPhoneUser());
    dispatch(destroyForm('phoneAuth'));
    dispatch(clearProviderInfo());
    return logout();
  };
}

export function removeFetchingUser() {
  return {
    type: REMOVE_FETCHING_USER,
  };
}

const initialUserState = {
  lastUpdated: 0,
  info: initialUserInfo,
};

function user(state = initialUserState, action) {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        info: userInfo(state.info, action),
        lastUpdated: action.timestamp,
      };
    case SAVING_ACCESS_TOKEN_SUCCESS:
    case UPDATING_USER_PROFILE_SUCCESS:
    case UPDATING_USER_PHONE_SUCCESS:
    case UPDATING_PROFILE_PICTURE:
    case UPDATING_PROFILE_PICTURE_FAILURE:
    case UPDATING_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        info: userInfo(state.info, action),
      };
    default:
      return state;
  }
}

const initialState = {
  isFetching: true,
  error: '',
  isAuthed: false,
  authedId: '',
  providerInfo: initialProviderState,
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        isFetching: false,
        isAuthed: true,
        authedId: action.user.uid,
        [action.user.uid]: user(state[action.uid], action),
      };
    case UNAUTH_USER:
      return {
        ...initialState,
        isFetching: false,
      };
    case FETCHING_USER:
      return {
        ...state,
        isFetching: true,
      };
    case FETCHING_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case FETCHING_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: '',
      };
    case FETCHING_PROVIDER_INFO_SUCCESS:
    case CLEAR_PROVIDER_INFO:
    case UPDATE_FIRST_NAME:
    case UPDATE_LAST_NAME:
    case UPDATE_GENDER:
    case UPDATE_EMAIL:
    case UPDATE_LOCATION:
      return {
        ...state,
        providerInfo: providerInfo(state.providerInfo, action),
      };
    case SAVING_ACCESS_TOKEN_SUCCESS:
    case UPDATING_USER_PROFILE_SUCCESS:
    case UPDATING_USER_PHONE_SUCCESS:
    case UPDATING_PROFILE_PICTURE:
    case UPDATING_PROFILE_PICTURE_FAILURE:
    case UPDATING_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        [action.uid]: user(state[action.uid], action),
      };
    case REMOVE_FETCHING_USER:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}
