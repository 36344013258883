import React from 'react';
import PropTypes from 'prop-types';
import TeamNav from './TeamNav';

export default function Feed({ teams, isIOS }) {
  return (
    <div>
      <TeamNav teams={teams} isIOS={isIOS} />
    </div>
  );
}

Feed.propTypes = {
  teams: PropTypes.array,
  isIOS: PropTypes.bool.isRequired,
};
