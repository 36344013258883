import React from 'react';
import PropTypes from 'prop-types';
import { deburr, get } from 'lodash';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Fuse from 'fuse.js';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from 'components/base';

import { suggestionWrapper } from './autoComplete.module.css';
/*
 * Taken from https://goo.gl/xm6vqs
 */
function getDifference(a, b) {
  var i = 0;
  var j = 0;
  var result = '';

  while (j < b.length) {
    if (a[i] !== b[j] || i === a.length) result += b[j];
    else i++;
    j++;
  }
  return result;
}

const InputEndAdorment = ({ onClearSelected }) => (
  <InputAdornment position="end">
    <IconButton
      aria-label="Remove location for event"
      onClick={onClearSelected}
    >
      <Close />
    </IconButton>
  </InputAdornment>
);

function renderInputComponent(inputProps) {
  const {
    classes,
    inputRef = () => {},
    ref,
    shrinkLabel,
    endAdornment,
    meta,
    ...other
  } = inputProps;
  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        endAdornment,
      }}
      InputLabelProps={{
        shrink: shrinkLabel || undefined,
      }}
      meta={{
        ...meta,
        initial: undefined,
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div className={suggestionWrapper}>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          )
        )}
      </div>
    </MenuItem>
  );
}

const getSuggestionValue = suggestion => suggestion.label;

const styles = theme => ({
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    top: 48,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
});

class IntegrationAutosuggest extends React.Component {
  constructor(props) {
    super(props);

    const value = get(props, 'meta.initial', '');

    this.state = {
      value,
      selected: value,
      suggestions: this.props.suggestions.slice(0, props.maxResults),
      shrinkLabel: !!value,
      lastValueSelected: !!value,
    };

    this.fuse = new Fuse(this.props.suggestions, {
      keys: this.props.keys,
    });
  }

  componentDidUpdate(prevProps) {
    const { suggestions } = this.props;
    if (suggestions !== prevProps.suggestions) {
      this.fuse.setCollection(suggestions);
      const { value, selected } = this.state;
      if (value !== selected) {
        this.handleSuggestionsFetchRequested({
          value,
        });
      }
    }
  }

  getSuggestions(value) {
    const { suggestions, maxResults } = this.props;
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;

    let filteredSuggestions = inputLength
      ? this.fuse.search(value)
      : suggestions;
    const newResults = filteredSuggestions.slice(0, maxResults);
    return newResults;
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
      shrinkLabel: value && value.length > 0,
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
      shrinkLabel: false,
    });
  };

  handleClearSelected = () => {
    const { suggestions, maxResults, onSelect, input } = this.props;
    onSelect({ value: '' });
    /*
     * Focus and blur to register mark field
     * as touched so error labels appear.
     */
    input.onFocus();
    input.onBlur();
    input.onChange('');
    this.setState({
      suggestions: suggestions.slice(0, maxResults),
      lastValueSelected: false,
      shrinkLabel: false,
      value: '',
      selected: '',
    });
  };

  handleShouldRenderSuggestions = value => {
    return value.length >= this.props.minChar;
  };

  handleSuggestionSelected = (event, { suggestion, suggestionValue }) => {
    this.props.onSelect(suggestion);
    this.setState({
      selected: suggestionValue,
      lastValueSelected: true,
    });
  };

  handleBlur = () => event => {
    this.props.input.onBlur(event);
    if (this.props.isRestricted) {
      const { value, selected } = this.state;
      if (value.length !== selected.length) {
        this.setState({
          value: selected,
          lastValueSelected: true,
        });
        this.props.input.onChange(selected);
      }
    }
  };

  handleChange = () => (event, { newValue, method }) => {
    const { isRestricted, onSelect } = this.props;
    let { selected, lastValueSelected } = this.state;
    let value = newValue;
    if (isRestricted && lastValueSelected && method === 'type') {
      const isNewQuery = newValue.length > selected.length;
      value = isNewQuery ? getDifference(selected, newValue) : '';
      selected = isNewQuery ? selected : '';
      lastValueSelected = false;
      /*
       * If unselecting value and have onSelect callback,
       * set the value to an empty string.
       */
      !isNewQuery && onSelect({ value: '' });
    }
    this.setState({
      value,
      selected,
      lastValueSelected,
      shrinkLabel: false,
    });
    this.props.input.onChange(newValue);
  };

  render() {
    const {
      classes,
      meta,
      input,
      /*
       * Next six props are not used. But
       * instead picked off to not include
       * with inputProps
       */
      minChar,
      maxResults,
      isRestricted,
      keys,
      suggestions,
      onSelect,
      ...inputProps
    } = this.props;

    const { active } = meta;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      shouldRenderSuggestions: this.handleShouldRenderSuggestions,
      onSuggestionSelected: this.handleSuggestionSelected,
      getSuggestionValue,
      renderSuggestion,
    };

    return (
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          ...input,
          classes,
          meta,
          ...inputProps,
          shrinkLabel: this.state.shrinkLabel,
          value:
            isRestricted && !active ? this.state.selected : this.state.value,
          onChange: this.handleChange(),
          onBlur: this.handleBlur(),
          endAdornment:
            isRestricted && this.state.selected ? (
              <InputEndAdorment onClearSelected={this.handleClearSelected} />
            ) : null,
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    );
  }
}

IntegrationAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired,
};

const AutoCompleteText = withStyles(styles)(IntegrationAutosuggest);
AutoCompleteText.displayName = 'AutoCompleteText';

AutoCompleteText.defaultProps = {
  minChar: 1,
  keys: ['label'],
  maxResults: 4,
  isRestricted: false,
  meta: {},
  onSelect: () => true,
  input: {
    onChange: () => true,
    onBlur: () => true,
  },
};

AutoCompleteText.propTypes = {
  minChar: PropTypes.number,
  maxResults: PropTypes.number,
  keys: PropTypes.arrayOf(PropTypes.string),
  isRestricted: PropTypes.bool,
  meta: PropTypes.object,
  input: PropTypes.object,
  onSelect: PropTypes.func,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

export default AutoCompleteText;
