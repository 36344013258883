import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import { Table } from 'components/base';

const TeamEvents = ({
  data,
  name,
  editable,
  teamNameStr,
  seasonStr,
  now,
  deviceSize,
  inlineCaption,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const isSmall = deviceSize === 'xs';
  return (
    <div className={classes.eventsWrapper}>
      <Table
        title={<h4>{`${name} Games (${data.length})`}</h4>}
        columns={[
          {
            title: 'Opponent',
            field: 'opponent',
          },
          {
            title: isSmall ? 'Date/Time' : 'Date',
            field: 'timestamp',
            render: rowData =>
              isSmall
                ? `${rowData.digitDate} ${rowData.shortTime}`
                : rowData.shortDate,
            cellStyle: { width: 150 },
          },
          {
            title: 'Time',
            field: 'timestamp',
            render: rowData => rowData.shortTime,
            cellStyle: { width: 110 },
            hidden: isSmall,
          },
          {
            title: 'RSVPs',
            field: 'totalRSVPs',
            cellStyle: { width: 75 },
            hidden: isSmall,
          },
        ]}
        caption={`Games created for the ${name} season`}
        pageSize={5}
        data={data}
        localization={{
          body: {
            editRow: {
              deleteText:
                'Are you sure you want to delete this game? All RSVPs will be lost.',
            },
            emptyDataSourceMessage: 'No games to display',
          },
        }}
        padding="dense"
        search={false}
        editable={isSmall ? undefined : editable}
        actions={
          isSmall
            ? undefined
            : [
                {
                  icon: AddIcon,
                  tooltip: 'Add Game',
                  isFreeAction: true,
                  onClick: () =>
                    history.push(
                      `/${teamNameStr}/${seasonStr}/addEvent?rd=/${teamNameStr}/manage/seasons`
                    ),
                },
                rowData => {
                  return {
                    icon: Edit,
                    tooltip: rowData.timestamp < now ? undefined : 'Edit',
                    onClick: () =>
                      history.push(
                        `/${teamNameStr}/${seasonStr}/${rowData.eventStr}/edit?rd=/${teamNameStr}/manage/seasons`
                      ),
                    disabled: rowData.timestamp < now,
                  };
                },
              ]
        }
        inlineCaption={inlineCaption}
      />
    </div>
  );
};

TeamEvents.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      opponent: PropTypes.string.isRequired,
      timestamp: PropTypes.number.isRequired,
      shortDate: PropTypes.string.isRequired,
      shortTime: PropTypes.string.isRequired,
      eventStr: PropTypes.string.isRequired,
      totalRSVPs: PropTypes.number,
    })
  ),
  editable: PropTypes.shape({
    isDeletable: PropTypes.func.isRequired,
    onRowDelete: PropTypes.func.isRequired,
  }),
};

export default TeamEvents;
