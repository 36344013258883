import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AddSeason } from '../../../components';
import { submitAddSeasonForm } from 'redux/modules/seasons';

class AddSeasonContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleSaveSeason = this.handleSaveSeason.bind(this);
  }
  handleSaveSeason(values) {
    this.props
      .submitAddSeasonForm(values)
      .then(() =>
        this.props.history.push(`/${this.props.teamNameStr}/schedule`)
      );
  }
  render() {
    return (
      <AddSeason
        teamId={this.props.teamId}
        onSubmit={this.handleSaveSeason}
        teamName={this.props.teamName}
        teamNameStr={this.props.teamNameStr}
      />
    );
  }
}

AddSeasonContainer.propTypes = {
  teamId: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  teamNameStr: PropTypes.string.isRequired,
};

function mapStateToProps({ teams }, ownProps) {
  const teamId = ownProps.teamId;
  const teamInfo = teams[teamId];
  return {
    teamId,
    teamName: teamInfo && teamInfo.name ? teamInfo.name : null,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitAddSeasonForm,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSeasonContainer);
