import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { renderFileInputField } from 'helpers/form';
import { normalizeZip } from '../../helpers/formNormalize';
import { Title, TextField, RadioButtons, PrimaryButton } from 'components/base';
import styles from 'sharedStyles/styles.module.css';
import { profilePic, fieldsWrapper } from './styles.module.css';

export default function ProfileForm({
  handleSubmit,
  submitting,
  onUpdateProfile,
  valid,
}) {
  return (
    <form
      onSubmit={handleSubmit(onUpdateProfile)}
      className={styles.formWrapper}
    >
      <Title
        title="Edit Profile"
        subtitle="Edit your profile which others may see. We use your zip code to help find games in your area."
      />
      <Field
        name="profilePic"
        component={renderFileInputField}
        className={profilePic}
      />
      <div className={fieldsWrapper}>
        <Field
          name="firstName"
          fullWidth
          component={TextField}
          label="First Name"
          disabled={submitting}
        />
        <Field
          name="lastName"
          fullWidth
          component={TextField}
          label="Last Name"
          disabled={submitting}
        />
        <Field
          name="gender"
          label="Gender"
          options={[
            {
              value: 'female',
              label: 'Female',
            },
            {
              value: 'male',
              label: 'Male',
            },
          ]}
          disabled={submitting}
          component={RadioButtons}
        />
        <Field
          name="email"
          fullWidth
          component={TextField}
          label="Email"
          helperText="We will never spam you."
          disabled={submitting}
        />
        <Field
          name="zip"
          component={TextField}
          label="Zip Code"
          disabled={submitting}
          type="tel"
          fullWidth
          normalize={normalizeZip}
        />
      </div>
      <div className={styles.formActions}>
        <PrimaryButton type="submit" disabled={!valid || submitting}>
          Submit
        </PrimaryButton>
      </div>
    </form>
  );
}

ProfileForm.propTypes = {
  valid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onUpdateProfile: PropTypes.func.isRequired,
};
