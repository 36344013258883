import React from 'react';
import cx from 'classnames';
import { Title, PrimaryButton } from 'components/base';
import InfoFields from './InfoFields';
import RosterFields from './RosterFields';
import styles from 'sharedStyles/styles.module.css';
import { teamForm } from './teamForm.module.css';

export default function TeamForm({
  handleSubmit,
  submitting,
  isCoed,
  onTeamCreate,
  pristine,
  valid,
}) {
  return (
    <form
      onSubmit={handleSubmit(onTeamCreate)}
      className={cx(styles.formWrapper, teamForm)}
    >
      <Title
        title="Create a Team"
        subtitle="Create a team that you can manage RSVPs and send notifications to."
      />
      <InfoFields submitting={submitting} />
      <RosterFields submitting={submitting} isCoed={isCoed} />
      <div className={styles.formActions}>
        <PrimaryButton type="submit" disabled={submitting}>
          Create Team
        </PrimaryButton>
      </div>
    </form>
  );
}
