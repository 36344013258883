import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cx from 'classnames';
import { get } from 'lodash';
import { EventDetailContainer, AttendeesContainer } from 'containers';
import { db } from 'config/constants';
import { updateEvent, updateEventRSVPs } from 'redux/modules/events';
import { updateSelectedSeason } from 'redux/modules/seasons';

import sharedStyles from 'sharedStyles/styles.module.css';

class EventContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.rsvpSubscribe = this.rsvpSubscribe.bind(this);
  }
  componentDidMount() {
    const { teamId, eventId, seasonId } = this.props;
    if (teamId && eventId && seasonId) {
      this.rsvpSubscribe();
    }
  }
  componentDidUpdate(prevProps) {
    const { teamId, eventId, seasonId, selectedSeason } = this.props;
    /*
     * If we have teamId, eventId, and seasonId
     * let's see if we need to get RSVP info
     */
    if (teamId && eventId && seasonId) {
      const isNewIDs =
        prevProps.teamId !== teamId ||
        prevProps.seasonId !== seasonId ||
        prevProps.eventId !== eventId;
      if (isNewIDs || !this.rsvpRef) {
        this.rsvpRef && this.rsvpRef();
        this.rsvpSubscribe();
      }
    }
    /*
     * If the selected season is not the event's season
     * then let's update selected season to the event's season
     * so we fetch events in the team container
     */
    if (teamId && seasonId && selectedSeason && seasonId !== selectedSeason) {
      this.props.updateSelectedSeason(seasonId, teamId);
    }
  }
  componentWillUnmount() {
    this.rsvpRef && this.rsvpRef();
  }
  rsvpSubscribe() {
    this.rsvpRef = db
      .collection(
        `teams/${this.props.teamId}/seasons/${this.props.seasonId}/events/${this.props.eventId}/RSVPs`
      )
      .onSnapshot(querySnapshot => {
        let RSVPs = [];
        querySnapshot.forEach(doc => {
          if (doc.exists) {
            RSVPs.push(doc.data());
          }
        });
        this.props.updateEventRSVPs(this.props.eventId, RSVPs);
      });
  }
  render() {
    const { teamId, eventId, seasonId, pathname } = this.props;
    return (
      <div className={sharedStyles.eventDetailWrapper}>
        <div
          className={cx(
            sharedStyles.contentWrapper,
            sharedStyles.eventInnerWrapper
          )}
        >
          {teamId && eventId && seasonId && (
            <React.Fragment>
              <EventDetailContainer
                teamId={teamId}
                eventId={eventId}
                seasonId={seasonId}
                pathname={pathname}
              />
              <AttendeesContainer
                teamId={teamId}
                eventId={eventId}
                seasonId={seasonId}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

EventContainer.propTypes = {
  teamId: PropTypes.string,
  eventId: PropTypes.string,
  seasonId: PropTypes.string,
  updateEvent: PropTypes.func.isRequired,
  updateEventRSVPs: PropTypes.func.isRequired,
  updateSelectedSeason: PropTypes.func.isRequired,
};

function mapStateToProps(
  { users: { authedId }, usersTeams, seasons },
  { pathname }
) {
  const paths = pathname.split('/');
  const teamId = get(usersTeams, `${authedId}.lookups.${paths[1]}.id`);
  const season = get(seasons, `${teamId}.lookups.${paths[2]}`, {});
  const seasonId = season.seasonId;
  const eventId = get(season, `events.${paths[3]}.eventId`);
  const selectedSeason = get(seasons, `${teamId}.selectedSeason`);
  return {
    teamId,
    eventId,
    seasonId,
    selectedSeason,
    pathname,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEvent,
      updateEventRSVPs,
      updateSelectedSeason,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EventContainer);
