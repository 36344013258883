import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { TextButton, Title } from 'components/base';

const useStyles = makeStyles(theme => ({
  progressBar: {
    width: '100%',
    margin: '16px 24px',
  },
  actionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
}));

const TeamDelete = ({
  teamName,
  isDeleting,
  isModalOpen,
  onOpenModal,
  onCloseModal,
  onDeleteTeam,
}) => {
  const classes = useStyles();

  const actions = isDeleting ? (
    <LinearProgress className={classes.progressBar} />
  ) : (
    <React.Fragment>
      <TextButton onClick={onCloseModal} color="primary">
        Cancel
      </TextButton>
      <TextButton onClick={onDeleteTeam} color="primary" autoFocus>
        Delete
      </TextButton>
    </React.Fragment>
  );
  return (
    <div>
      <Title title="Delete" subtitle={`Delete the team ${teamName}`} />
      <Divider />
      <p>
        This action is irreversible. This will delete the {teamName} team and
        all the data related to the team. This data includes past and upcoming
        events, roster info, and any media (i.e. images, videos) associated with
        the team. You and other players on the team will no longer see the team{' '}
        {teamName} when logging into Coach Cheetah.
      </p>
      <div className={classes.actionWrapper}>
        <TextButton onClick={onOpenModal}>{`Delete ${teamName}`}</TextButton>
      </div>
      <Dialog
        open={isModalOpen}
        onClose={onCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Delete ${teamName}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the team {teamName}? All data and
            registered users will be lost.
          </DialogContentText>
        </DialogContent>

        <DialogActions>{actions}</DialogActions>
      </Dialog>
    </div>
  );
};

TeamDelete.propTypes = {
  teamName: PropTypes.string.isRequired,
  onDeleteTeam: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
};

export default TeamDelete;
