import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PlayerCard, PlayerListItem } from 'components/base';
import { fetchFile } from 'redux/modules/authedFiles';

class PlayerCardContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isFetchingImg: false,
    };
  }
  UNSAFE_componentWillMount() {
    if (this.props.player.profilePic && !this.props.img) {
      this.props.fetchFile(this.props.player.profilePic);
    }
  }
  render() {
    const { player, isListItem, img } = this.props;

    const Card = isListItem ? PlayerListItem : PlayerCard;

    return (
      <Card
        firstName={player.firstName}
        lastName={player.lastName}
        src={img}
        avatarColor={player.gender === 'male' ? 'orange' : 'purple'}
      />
    );
  }
}

PlayerCardContainer.defaultProps = {
  isGrayed: false,
  size: 36,
  isListItem: false,
};

PlayerCardContainer.propTypes = {
  isGrayed: PropTypes.bool.isRequired,
  player: PropTypes.shape({
    gender: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    profilePic: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }),
  size: PropTypes.number.isRequired,
  fetchFile: PropTypes.func.isRequired,
  img: PropTypes.string,
  isListItem: PropTypes.bool,
};

function mapStateToProps({ viewport, authedFiles: { files } }, ownProps) {
  return {
    isMobile: viewport.deviceSize === 'xs',
    img: files[ownProps.player.profilePic],
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchFile,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerCardContainer);
