import React from 'react';

export default function ProfileLinkFb({ phone, onLinkFB, onNonProviderInfo }) {
  return (
    <div>
      <h4>
        Thanks! The number {phone} has been verified. Now lets confirm your
        Facebook information.
      </h4>
      <button onClick={onLinkFB}>Get Profile Data from Facebook</button>
      <button onClick={onNonProviderInfo}>Enter Profile Data yourself</button>
    </div>
  );
}
