import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { get } from 'lodash';
import { EventPreview } from 'components/base';
import { updateRSVP, addRSVP } from 'helpers/apiEvents';

class EventPreviewContainer extends React.Component {
  constructor(props) {
    super(props);

    const { isRSVPedYes, isRSVPedNo } = this.props;

    this.state = {
      isRSVPed: isRSVPedYes || isRSVPedNo,
      isRSVPedYes: isRSVPedYes,
      isRSVPedNo: isRSVPedNo,
      isUpdatingStatus: false,
    };

    this.handleToggleRSVP = this.handleToggleRSVP.bind(this);
  }
  handleToggleRSVP(status) {
    const { isRSVPed, isRSVPedYes, isRSVPedNo } = this.state;
    // check if new RSVP, or changing yes status, or changing no status
    const statusChanged =
      !isRSVPed ||
      (isRSVPedYes && status === 0) ||
      (isRSVPedNo && status === 1);
    // if no status changed, exit
    if (!statusChanged) {
      return;
    }
    // make optimistic update
    this.setState({
      isRSVPed: true,
      isRSVPedYes: status === 1,
      isRSVPedNo: status === 0,
      isUpdatingStatus: true,
    });
    // choose handler based on adding new rsvp or updating one
    const handler = isRSVPed ? updateRSVP : addRSVP;
    return handler(
      this.props.teamId,
      this.props.seasonId,
      this.props.eventId,
      this.props.uid,
      status,
      this.props.userInfo
      // if update made successfully
    )
      .then(() =>
        this.setState({
          isUpdatingStatus: false,
          // if update failed, revert status
          // TODO: show alert
        })
      )
      .catch(() =>
        this.setState({
          isRSVPed,
          isRSVPedYes: isRSVPed ? status === 0 : false,
          isRSVPedNo: isRSVPed ? status === 1 : false,
          isUpdatingStatus: false,
        })
      );
  }
  render() {
    return (
      <EventPreview
        {...this.props}
        onToggleRSVP={this.handleToggleRSVP}
        isRSVPedYes={this.state.isRSVPedYes}
        isRSVPedNo={this.state.isRSVPedNo}
      />
    );
  }
}

/* TODO: Add more validation */
EventPreviewContainer.propTypes = {
  teamId: PropTypes.string.isRequired,
  seasonId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  location: PropTypes.string,
  userInfo: PropTypes.shape({
    avatar: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
  }),
};

function mapStateToProps(
  { users, events },
  { event: { eventId, name }, teamNameStr, selectedSeasonStr, teamId }
) {
  const uid = users.authedId;
  const userInfo = users[uid].info;
  const userPhone = get(users[uid], 'info.phone', 'undefined');
  const event = events[eventId];
  return {
    eventId,
    teamId,
    eventName: name,
    teamNameStr,
    selectedSeasonStr,
    seasonId: event.seasonId,
    uid,
    startMoment: moment(event.timestamp),
    isHome: event.isHome,
    opponent: event.opponent,
    address: event.address,
    locationId: event.locationId,
    isRSVPedYes: event.RSVPsYes
      ? event.RSVPsYes.indexOf(userPhone) !== -1
      : false,
    isRSVPedNo: event.RSVPsNo ? event.RSVPsNo.indexOf(userPhone) !== -1 : false,
    teamGender: event.teamGender,
    totalRSVPs: event.totalRSVPs,
    maleRSVPs: event.maleRSVPs,
    femaleRSVPs: event.femaleRSVPs,
    location: event.location,
    userInfo: {
      avatar: userInfo.avatar,
      firstName: userInfo.firstName,
      gender: userInfo.gender,
      lastName: userInfo.lastName,
      phone: userInfo.phone,
      uid: uid,
    },
  };
}

export default connect(mapStateToProps)(EventPreviewContainer);
