import { push } from 'react-router-redux';
import { format, utcToZonedTime } from 'date-fns-tz';
import { saveEvent, getEvents, deleteEvent } from 'helpers/apiEvents';
import { addEventsToSeason, removeSeasonEvent } from 'redux/modules/seasons';
import { addAlert } from 'redux/modules/alerts';

const CREATING_EVENT = 'CREATING_EVENT';
const CREATING_EVENT_FAILURE = 'CREATING_EVENT_FAILURE';
const CREATING_EVENT_SUCCESS = 'CREATING_EVENT_SUCCESS';

const UPDATING_EVENT_SUCCESS = 'UPDATING_EVENT_SUCCESS';
const UPDATING_EVENT_LOCATION_SUCCESS = 'UPDATING_EVENT_LOCATION_SUCCESS';

const UPDATING_EVENT_RSVPS_SUCCESS = 'UPDATING_EVENT_RSVPS_SUCCESS';

const UPDATING_EVENTS = 'UPDATING_EVENTS';
const UPDATING_EVENTS_SUCCESS = 'UPDATING_EVENTS_SUCCESS';
const UPDATING_EVENTS_FAILURE = 'UPDATING_EVENTS_FAILURE';

const DELETING_EVENT = 'DELETING_EVENT';
const DELETING_EVENT_SUCCESS = 'DELETING_EVENT_SUCCESS';
const DELETING_EVENT_FAILURE = 'DELETING_EVENT_FAILURE';

function creatingEvent() {
  return {
    type: CREATING_EVENT,
  };
}

function creatingEventFailure() {
  return {
    type: CREATING_EVENT_FAILURE,
  };
}

function creatingEventSuccess() {
  return {
    type: CREATING_EVENT_SUCCESS,
  };
}

function updatingEventSuccess(event) {
  return {
    type: UPDATING_EVENT_SUCCESS,
    ...event,
  };
}

function updatingEvents() {
  return {
    type: UPDATING_EVENTS,
  };
}

function updatingEventsSuccess(events) {
  return {
    type: UPDATING_EVENTS_SUCCESS,
    events: events,
  };
}

function updatingEventsFailure(error) {
  return {
    type: UPDATING_EVENTS_FAILURE,
    error,
  };
}

function updatingEventRSVPsSuccess({ eventId, RSVPs }) {
  return {
    type: UPDATING_EVENT_RSVPS_SUCCESS,
    eventId,
    RSVPs,
  };
}

function updatingEventLocationSuccess(eventId, lat, lng) {
  return {
    type: UPDATING_EVENT_LOCATION_SUCCESS,
    eventId,
    lat,
    lng,
  };
}

function deletingEvent() {
  return {
    type: DELETING_EVENT,
  };
}

function deletingEventFailure(error) {
  return {
    type: DELETING_EVENT_FAILURE,
    error,
  };
}

function deletingEventSuccess() {
  return {
    type: DELETING_EVENT_SUCCESS,
  };
}

export function savingEvent(values, teamId, selectedSeason) {
  return function(dispatch) {
    dispatch(creatingEvent());
    return saveEvent(values)
      .then(response => {
        dispatch(creatingEventSuccess(response));
        const { seasonId, oldSeasonId } = values;
        // if event is part of the selected season, it will automatically update
        if (seasonId !== selectedSeason) {
          dispatch(fetchEvents(teamId, seasonId));
        }
        /*
         * If removing from a season that is not the selected,
         * need to update the season collection the event was removed from
         */
        if (oldSeasonId !== seasonId && oldSeasonId !== selectedSeason) {
          dispatch(fetchEvents(teamId, oldSeasonId));
        }
        return response;
      })
      .catch(error => {
        console.log('error', error);
        dispatch(creatingEventFailure(error));
      });
  };
}

export function updateEvent(event, eventId, lat, lng) {
  return function(dispatch) {
    return dispatch(
      updatingEventSuccess({
        ...event,
        eventId,
        lat,
        lng,
      })
    );
  };
}

export function updateEventLocation(eventId, lat, lng) {
  return function(dispatch) {
    return dispatch(updatingEventLocationSuccess(eventId, lat, lng));
  };
}

export function updateEvents(events) {
  return function(dispatch) {
    return dispatch(updatingEventsSuccess(events));
  };
}

export function fetchEvents(teamId, seasonId) {
  return function(dispatch) {
    dispatch(updatingEvents());
    getEvents(teamId, seasonId)
      .then(events => {
        let eventsArray = [];
        const timestamp = new Date().getTime();
        let hasPastGames = false;
        events.forEach(event => {
          const eventData = event.data();
          eventsArray.push({
            ...eventData,
            eventId: event.id,
          });
          if (eventData.timestamp < timestamp) {
            hasPastGames = true;
          }
        });
        dispatch(updatingEventsSuccess(eventsArray));
        dispatch(
          addEventsToSeason(teamId, seasonId, eventsArray, hasPastGames)
        );
      })
      .catch(err => dispatch(updatingEventsFailure(err)));
  };
}

export function updateEventRSVPs(eventId, RSVPs) {
  return function(dispatch) {
    return dispatch(
      updatingEventRSVPsSuccess({
        eventId,
        RSVPs,
      })
    );
  };
}

export function navToEventEdit(eventId, teamId, seasonId) {
  return function(dispatch) {
    return dispatch(
      push(`/team/${teamId}/event/${eventId}/edit?season=${seasonId}`)
    );
  };
}

export function removeEvent(teamId, seasonId, eventId, seasonStr, eventStr) {
  return function(dispatch) {
    dispatch(deletingEvent());
    return deleteEvent(teamId, seasonId, eventId)
      .then(() => {
        dispatch(deletingEventSuccess(eventId));
        dispatch(removeSeasonEvent(teamId, seasonStr, eventStr));
        dispatch(addAlert('Event deleted.'));
      })
      .catch(err => {
        dispatch(deletingEventFailure(err));
        dispatch(addAlert('Unable to delete event.'));
      });
  };
}

const initialEventState = {
  opponent: '',
  isHome: null,
  seasonId: '',
  startTime: null,
};

function event(state = initialEventState, action) {
  switch (action.type) {
    case UPDATING_EVENT_SUCCESS:
    case UPDATING_EVENTS_SUCCESS:
      const eventDate = utcToZonedTime(
        new Date(action.timestamp),
        action.timeZoneId
      );
      return {
        ...state,
        opponent: action.opponent,
        isHome: action.isHome,
        seasonId: action.seasonId,
        startTime: action.startTime,
        shortDate: format(eventDate, 'MMM do, yyyy', {
          timeZone: action.timeZoneId,
        }),
        shortTime: format(eventDate, 'h:mm a', {
          timeZone: action.timeZoneId,
        }),
        digitDate: format(eventDate, 'M/d/yy', {
          timeZone: action.timeZoneId,
        }),
        timestamp: action.timestamp,
        timeZoneId: action.timeZoneId,
        address: action.address,
        locationId: action.locationId,
        totalRSVPs: action.rsvpPlayer,
        maleRSVPs: action.rsvpMale,
        femaleRSVPs: action.rsvpFemale,
        teamGender: action.teamGender,
        minPlayer: action.minPlayer,
        minMale: action.minMale,
        minFemale: action.minFemale,
        maxPlayer: action.maxPlayer,
        maxMale: action.maxMale,
        maxFemale: action.maxFemale,
        weather: action.weather,
        weatherTimestamp: action.weatherTimestamp,
        msgStatus: action.msgStatus,
        msgTimestamp: action.msgTimestamp,
        lat: action.lat,
        lng: action.lng,
        location: action.location,
        directions: action.directions,
      };
    case UPDATING_EVENT_RSVPS_SUCCESS:
      return {
        ...state,
        RSVPs: action.RSVPs,
      };
    case UPDATING_EVENT_LOCATION_SUCCESS:
      return {
        ...state,
        lat: action.lat,
        lng: action.lng,
      };
    default:
      return state;
  }
}

const initialState = {
  isFetching: false,
};

export default function events(state = initialState, action) {
  switch (action.type) {
    case CREATING_EVENT:
      return {
        ...state,
        isFetching: true,
      };
    case CREATING_EVENT_SUCCESS:
    case UPDATING_EVENT_SUCCESS:
    case UPDATING_EVENT_RSVPS_SUCCESS:
    case UPDATING_EVENT_LOCATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        [action.eventId]: event(state[action.eventId], action),
      };
    case UPDATING_EVENTS_SUCCESS:
      let events = {};
      action.events.forEach(ev => {
        events[ev.eventId] = event(state[ev.eventId], {
          ...ev,
          type: action.type,
        });
      });
      return {
        ...state,
        ...events,
        isFetching: false,
      };
    case DELETING_EVENT_SUCCESS:
      const { [action.eventId]: removedEvent, ...rest } = state;
      return {
        ...rest,
        isFetching: false,
      };
    default:
      return state;
  }
}
