import { find, get } from 'lodash';
import { format } from 'date-fns';
import { saveSeason } from 'helpers/apiSchedule';
import { fbUpdateSeason, fbDeleteSeason } from 'helpers/apiTeam';
import { stringToURL } from 'helpers/strings';
import { addAlert } from 'redux/modules/alerts';

const UPDATING_SEASONS = 'UPDATING_SEASONS';
const UPDATING_SELECTED_SEASON = 'UPDATING_SELECTED_SEASON';
const ADD_SEASON_EVENTS = 'ADD_SEASON_EVENTS';
const REMOVE_SEASON_EVENTS = 'REMOVE_SEASON_EVENTS';
const REMOVE_SEASON_EVENT = 'REMOVE_SEASON_EVENT';

const UPDATING_SEASON = 'UPDATING_SEASON';
const UPDATING_SEASON_FAILURE = 'UPDATING_SEASON_FAILURE';
const UPDATING_SEASON_SUCCESS = 'UPDATING_SEASON_SUCCESS';

const DELETING_SEASON = 'DELETING_SEASON';
const DELETING_SEASON_FAILURE = 'DELETING_SEASON_FAILURE';
const DELETING_SEASON_SUCCESS = 'DELETING_SEASON_SUCCESS';

const ADDING_SEASON = 'ADDING_SEASON';
const ADDING_SEASON_FAILURE = 'ADDING_SEASON_FAILURE';
const ADDING_SEASON_SUCCESS = 'ADDING_SEASON_SUCCESS';

function updatingSeasons(teamId, seasons, lastUpdated) {
  return {
    type: UPDATING_SEASONS,
    teamId,
    seasons,
    lastUpdated,
  };
}

function updatingSelectedSeason(seasonId, teamId) {
  return {
    type: UPDATING_SELECTED_SEASON,
    seasonId,
    teamId,
  };
}

function addSeasonEvents(teamId, seasonId, events, hasPastGames) {
  return {
    type: ADD_SEASON_EVENTS,
    teamId,
    seasonId,
    events,
    hasPastGames,
  };
}

function removeSeasonEvents(teamId, seasonStr) {
  return {
    type: REMOVE_SEASON_EVENTS,
    teamId,
    seasonStr,
  };
}

export function removeSeasonEvent(teamId, seasonStr, eventStr) {
  return {
    type: REMOVE_SEASON_EVENT,
    teamId,
    seasonStr,
    eventStr,
  };
}

function updatingSeason() {
  return {
    type: UPDATING_SEASON,
  };
}

function updatingSeasonFailure() {
  return {
    type: UPDATING_SEASON_FAILURE,
  };
}

function updatingSeasonSuccess() {
  return {
    type: UPDATING_SEASON_SUCCESS,
  };
}

function deletingSeason() {
  return {
    type: DELETING_SEASON,
  };
}

function deletingSeasonFailure() {
  return {
    type: DELETING_SEASON_FAILURE,
  };
}

function deletingSeasonSuccess() {
  return {
    type: DELETING_SEASON_SUCCESS,
  };
}

function addingSeason() {
  return {
    type: ADDING_SEASON,
  };
}

function addingSeasonFailure() {
  return {
    type: ADDING_SEASON_FAILURE,
  };
}

function addingSeasonSuccess() {
  return {
    type: ADDING_SEASON_SUCCESS,
  };
}

export function updateSeason(teamId, seasonId, values) {
  return function(dispatch) {
    dispatch(updatingSeason());
    return fbUpdateSeason(teamId, seasonId, values)
      .then(() => {
        dispatch(updatingSeasonSuccess());
        dispatch(addAlert('Season updated.'));
      })
      .catch(err => {
        dispatch(updatingSeasonFailure(err));
        dispatch(addAlert('Unable to update season.'));
      });
  };
}

export function deleteSeason(teamId, seasonId) {
  return function(dispatch) {
    dispatch(deletingSeason());
    return fbDeleteSeason(teamId, seasonId)
      .then(() => {
        dispatch(deletingSeasonSuccess());
        dispatch(addAlert('Season deleted.'));
      })
      .catch(err => {
        dispatch(deletingSeasonFailure(err));
        dispatch(addAlert('Unable to delete season.'));
      });
  };
}

export function submitAddSeasonForm(values) {
  console.log('values', values);
  return function(dispatch) {
    return saveSeason(values).catch(error => {
      // TODO: Handle error
      console.log('error', error);
    });
  };
}

export function addSeason(values) {
  return function(dispatch) {
    dispatch(addingSeason());
    if (!values.name) {
      dispatch(addingSeasonFailure());
      dispatch(addAlert('Must provide a season name.'));
      return Promise.reject();
    }
    return saveSeason(values)
      .then(() => {
        dispatch(addingSeasonSuccess());
        dispatch(addAlert(`${values.name} season added.`));
        return Promise.resolve();
      })
      .catch(err => {
        dispatch(addingSeasonFailure(err));
        dispatch(addAlert(`Unable to add season.`));
        return Promise.reject();
      });
  };
}

export function updateSelectedSeason(seasonId, teamId) {
  return function(dispatch) {
    dispatch(updatingSelectedSeason(seasonId, teamId));
  };
}

export function updateSeasons(teamId, seasonsArray, lastUpdated) {
  return function(dispatch) {
    dispatch(updatingSeasons(teamId, seasonsArray, lastUpdated));
  };
}

function getUniqueDay(day, days) {
  let formattedDay = day;
  while (days.includes(formattedDay)) {
    const splitDay = formattedDay.split('-');
    if (splitDay.length > 2) {
      const number = parseInt(splitDay[2]) + 1;
      formattedDay = `${splitDay[0]}-${splitDay[1]}-${number}`;
    } else {
      formattedDay = formattedDay + '-1';
    }
  }
  return formattedDay;
}

function formatEvents(eventsArray) {
  let events = {};
  let eventDays = [];
  eventsArray.forEach(event => {
    const day = getUniqueDay(
      format(new Date(event.timestamp), 'MMM-d'),
      eventDays
    );
    eventDays.push(day);
    events[day] = {
      timestamp: event.timestamp,
      eventId: event.eventId,
    };
  });
  return events;
}

export function addEventsToSeason(teamId, seasonId, events, hasPastGames) {
  return function(dispatch) {
    dispatch(
      addSeasonEvents(teamId, seasonId, formatEvents(events), hasPastGames)
    );
  };
}

export function removeEventsFromSeason(teamId, seasonStr) {
  return function(dispatch) {
    dispatch(removeSeasonEvents(teamId, seasonStr));
  };
}

function getSeasonLookups(seasons, state = {}) {
  let lookups = {};
  seasons.forEach(season => {
    const seasonStr = stringToURL(season.name);
    lookups[seasonStr] = {
      name: season.name,
      seasonStr,
      created: season.created,
      teamId: season.teamId,
      seasonId: season.seasonId,
      eventsLoaded: get(state, `${seasonStr}.eventsLoaded`, false),
      events: state[seasonStr] ? state[seasonStr].events : undefined,
      hasPastGames: state[seasonStr]
        ? state[seasonStr].hasPastGames
        : undefined,
    };
  });
  return lookups;
}

const teamInitialState = {
  seasons: [],
  lastUpdated: 0,
};

function teamSeasons(state = teamInitialState, action) {
  switch (action.type) {
    case UPDATING_SEASONS:
      return {
        ...state,
        seasons: action.seasons,
        lookups: getSeasonLookups(action.seasons, state.lookups),
        lastUpdated: action.lastUpdated,
        selectedSeason: action.seasons.length
          ? state.selectedSeason
          : undefined,
        selectedSeasonStr: action.seasons.length
          ? state.selectedSeasonStr
          : undefined,
      };
    case UPDATING_SELECTED_SEASON:
      let season = find(state.seasons, o => o.seasonId === action.seasonId);
      return {
        ...state,
        selectedSeason: action.seasonId,
        selectedSeasonStr: stringToURL(season.name),
      };
    case ADD_SEASON_EVENTS:
      let currentSeason = find(
        state.seasons,
        o => o.seasonId === action.seasonId
      );
      if (!currentSeason) return state;
      let seasonName = stringToURL(currentSeason.name);
      return {
        ...state,
        lookups: {
          ...state.lookups,
          [seasonName]: {
            ...state.lookups[seasonName],
            events: action.events,
            eventsLoaded: true,
            hasPastGames: action.hasPastGames,
          },
        },
      };
    case REMOVE_SEASON_EVENTS:
      return {
        ...state,
        lookups: {
          ...state.lookups,
          [action.seasonStr]: {
            ...state.lookups[action.seasonStr],
            events: undefined,
            eventsLoaded: false,
          },
        },
      };
    case REMOVE_SEASON_EVENT:
      const { [action.eventStr]: removedEvent, ...events } = state.lookups[
        action.seasonStr
      ].events;
      return {
        ...state,
        lookups: {
          ...state.lookups,
          [action.seasonStr]: {
            ...state.lookups[action.seasonStr],
            events,
          },
        },
      };
    default:
      return state;
  }
}

export default function seasons(state = { isFetching: true }, action) {
  switch (action.type) {
    case UPDATING_SEASONS:
    case UPDATING_SELECTED_SEASON:
    case ADD_SEASON_EVENTS:
    case REMOVE_SEASON_EVENTS:
    case REMOVE_SEASON_EVENT:
      return {
        ...state,
        isFetching: false,
        [action.teamId]: teamSeasons(state[action.teamId], action),
      };
    default:
      return state;
  }
}
