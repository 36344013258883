import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Avatar } from 'components/base';
import { fetchFile } from 'redux/modules/authedFiles';

class PlayerAvatar extends React.Component {
  constructor(props) {
    super(props);
    if (!props.img && props.profilePic) {
      props.fetchFile(props.profilePic);
    }
  }
  render() {
    const { img } = this.props;
    if (img) {
      return <Avatar src={img} size={35} />;
    } else {
      return null;
    }
  }
}

PlayerAvatar.propTypes = {
  profilePic: PropTypes.string.isRequired,
  img: PropTypes.string,
};

function mapStateToProps({ authedFiles: { files } }, { profilePic }) {
  return {
    img: files[profilePic],
    profilePic,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchFile,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerAvatar);
