import { db } from '../config/constants';
import { omitBy, isNil } from 'lodash';

const removeNils = obj => omitBy(obj, isNil);

export function saveEvent(event) {
  event = removeNils(event);
  if (!event.eventId) {
    return addEvent(event);
  } else {
    // if moving event from one season to another
    if (event.oldSeasonId && event.oldSeasonId !== event.seasonId) {
      var batch = db.batch();

      // Delete event from current season
      var oldRef = db.doc(
        `teams/${event.teamId}/seasons/${event.oldSeasonId}/events/${event.eventId}`
      );
      batch.delete(oldRef);

      // Add event to new season
      var newRef = db
        .collection(`teams/${event.teamId}/seasons/${event.seasonId}/events`)
        .doc();
      batch.set(newRef, event);

      return batch.commit().then(() => {
        return {
          ...event,
          eventId: newRef.id,
        };
      });
    }
    // else update current event
    else {
      return updateEvent(event);
    }
  }
}

function addEvent(event) {
  return db
    .collection(`teams/${event.teamId}/seasons/${event.seasonId}/events`)
    .add(event)
    .then(docRef => ({
      ...event,
      eventId: docRef.id,
    }))
    .catch(error => {
      console.log('error adding event', error);
    });
}

function updateEvent(event) {
  return db
    .doc(
      `teams/${event.teamId}/seasons/${event.seasonId}/events/${event.eventId}`
    )
    .update(event)
    .then(() => event)
    .catch(error => {
      console.log('error updating event', error);
    });
}

export function updateRSVP(teamId, seasonId, eventId, uid, status) {
  return Promise.all([
    db
      .doc(`teams/${teamId}/seasons/${seasonId}/events/${eventId}/RSVPs/${uid}`)
      .update({
        rsvp: status,
      }),
    db.doc(`users/${uid}/notices/${eventId}`).update({ status }),
  ]);
}

export function addRSVP(
  teamId,
  seasonId,
  eventId,
  uid,
  status,
  userInfo,
  teamName,
  opponent,
  timestamp,
  timeZoneId
) {
  return Promise.all([
    db
      .doc(`teams/${teamId}/seasons/${seasonId}/events/${eventId}/RSVPs/${uid}`)
      .set({
        ...userInfo,
        rsvp: status,
      }),
    db.doc(`users/${uid}/notices/${eventId}`).set({
      teamName,
      opponent,
      timestamp,
      timeZoneId,
      eventId,
      seasonId,
      status,
      teamId,
      player: userInfo,
    }),
  ]);
}

export function getEvents(teamId, seasonId) {
  return db
    .collection(`teams/${teamId}/seasons/${seasonId}/events`)
    .orderBy('timestamp')
    .get();
}

export function deleteEvent(teamId, seasonId, eventId) {
  return db
    .doc(`teams/${teamId}/seasons/${seasonId}/events/${eventId}`)
    .delete();
}
