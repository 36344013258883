import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import { Field } from 'redux-form';

const styles = theme => ({
  fieldset: {
    padding: 0,
    border: 0,
    margin: 0,
    minWidth: 0,
  },
  inputLabel: {
    '&$cssFocused': {
      color: theme.palette.primary.main,
    },
    '&$cssError': {
      color: theme.palette.error.main,
    },
  },
  listGroup: {
    padding: 0,
    listStyle: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: -8,
    marginLeft: -8,
    marginBottom: 0,
  },
  listItem: {
    margin: '0 8px 24px',
  },
  radioLabel: {
    position: 'relative',
    display: 'block',
    backgroundColor: 'transparent',
    width: 30,
    height: 30,
    border: '2px solid transparent',
    borderRadius: 4,
    boxShadow: '0 3px 6px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.23)',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      boxShadow: '0 6px 8px rgba(0,0,0,0.20), 0 8px 8px rgba(0,0,0,0.22)',
      transform: 'translateY(-2px)',
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
  radioInput: {
    opacity: 0,
    position: 'fixed',
    width: 0,
    '&:checked + label, &:focus + label': {
      border: '2px solid white',
      boxShadow:
        '0 3px 6px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.23), 0 0 0 2px rgba(56, 56, 56, 1)',
      opacity: 0.9,
    },
  },
  radioText: {
    opacity: 0,
    visibility: 'hidden',
  },
  cssFocused: {},
  cssError: {},
});

function RadioColorButtons({
  label,
  name,
  options,
  disabled,
  classes,
  ...restOfProps
}) {
  const radioButtons = options.map(({ value, label, id, ...restOfProps }) => (
    <li className={classes.listItem} key={`${name}-${value}`}>
      <Field
        value={value}
        name={name}
        component="input"
        type="radio"
        id={`${name}-${id}`}
        className={classes.radioInput}
        disabled={disabled}
        {...restOfProps}
      ></Field>
      <label
        className={classes.radioLabel}
        htmlFor={`${name}-${id}`}
        style={{ backgroundColor: value, color: value }}
      >
        <span className={classes.radioText}>{label}</span>
      </label>
    </li>
  ));

  return (
    <fieldset className={classes.fieldset}>
      <FormLabel
        component="legend"
        classes={{
          root: classes.inputLabel,
          focused: classes.cssFocused,
          error: classes.cssError,
        }}
      >
        {label}
      </FormLabel>
      <ul className={classes.listGroup}>{radioButtons}</ul>
    </fieldset>
  );
}

RadioColorButtons.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
};

export default withStyles(styles)(RadioColorButtons);
