import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SendCode, VerifyCode, PhoneAuth } from '../../../components';
import * as phoneAuthActionCreators from '../../../redux/modules/phoneAuth';
import { updateUserPhone } from '../../../redux/modules/users/userInfo';
import { formatDashedPhone } from '../../../helpers/utils';

class PhoneAuthContainer extends React.Component {
  handleSendCode({ phone }) {
    return this.props.requestVerificationCode(phone);
  }
  handleResendCode(e) {
    e.preventDefault();
    return this.props.resendVerificationCode(this.props.phone);
  }
  handleAuth({ code }) {
    this.props.clearErrorMsg();
    if (this.props.handleAuth) {
      return this.props.confirmCodeAndHandleAuth(this.props.phone, code);
    } else {
      this.props.confirmCode(this.props.phone, code).then(({ phone }) => {
        if (!phone) {
          return null;
        }

        return this.props
          .updateUserPhone(this.props.authedId, phone)
          .then(() =>
            this.props.checkForExistingPhoneUser(this.props.authedId, phone)
          );
      });
    }
  }
  render() {
    let {
      codeSent,
      isResendingCode,
      codeConfirmed,
      phone,
      error,
      editPhoneNumber,
    } = this.props;
    return (
      <div>
        {!codeSent && !codeConfirmed && (
          <SendCode handleSendCode={this.handleSendCode.bind(this)} />
        )}
        {codeSent && !codeConfirmed && (
          <VerifyCode
            handleAuth={this.handleAuth.bind(this)}
            onResendCode={this.handleResendCode.bind(this)}
            isResendingCode={isResendingCode}
            onEditPhone={editPhoneNumber}
            phone={phone}
          />
        )}
        {error && <PhoneAuth error={error} />}
      </div>
    );
  }
}

PhoneAuthContainer.propTypes = {
  error: PropTypes.string.isRequired,
  codeSent: PropTypes.bool.isRequired,
  isResendingCode: PropTypes.bool.isRequired,
  codeConfirmed: PropTypes.bool.isRequired,
  requestVerificationCode: PropTypes.func.isRequired,
  resendVerificationCode: PropTypes.func.isRequired,
  editPhoneNumber: PropTypes.func.isRequired,
  confirmCodeAndHandleAuth: PropTypes.func.isRequired,
  checkForExistingPhoneUser: PropTypes.func.isRequired,
  confirmCode: PropTypes.func.isRequired,
  updateUserPhone: PropTypes.func.isRequired,
  clearErrorMsg: PropTypes.func.isRequired,
  handleAuth: PropTypes.bool.isRequired,
};

PhoneAuthContainer.defaultProps = {
  handleAuth: true,
};

function mapStateToProps({ users, phoneAuth }) {
  const phoneVerified =
    users.authedId && users[users.authedId].info.phone ? true : false;
  const userPhone = phoneVerified
    ? formatDashedPhone(users[users.authedId].info.phone)
    : null;
  return {
    error: phoneAuth.error,
    codeSent: phoneAuth.codeSent,
    authedId: users.authedId ? users.authedId : null,
    isResendingCode: phoneAuth.isResendingCode,
    codeConfirmed: phoneVerified ? true : phoneAuth.codeConfirmed,
    phone: userPhone || phoneAuth.phone,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...phoneAuthActionCreators,
      updateUserPhone,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PhoneAuthContainer);
