import React from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import deepOrange from '@material-ui/core/colors/deepOrange';
import orange from '@material-ui/core/colors/orange';
import purple from '@material-ui/core/colors/purple';
import pink from '@material-ui/core/colors/pink';
import { Avatar } from 'components/base';

import {
  playerCard,
  playerInfo,
  playerName,
  playerSubtitle,
} from './styles.module.css';

const styles = {
  avatar: {
    margin: '10px 15px 10px 5px',
  },
  orangeAvatar: {
    color: orange[200],
    backgroundColor: pink[400],
  },
  purpleAvatar: {
    color: deepOrange[300],
    backgroundColor: purple[500],
  },
};

function PlayerCard({
  src,
  firstName,
  lastName,
  subtitle,
  avatarColor,
  classes,
}) {
  return (
    <div className={playerCard}>
      <Avatar
        src={src}
        size={50}
        className={cx(classes.avatar, {
          [classes.orangeAvatar]: avatarColor === 'orange',
          [classes.purpleAvatar]: avatarColor === 'purple',
        })}
      >
        {!src && `${firstName.charAt(0)}${lastName.charAt(0)}`}
      </Avatar>
      <div className={playerInfo}>
        <div className={playerName}>
          {firstName} {lastName}
        </div>
        {subtitle && <div className={playerSubtitle}>{subtitle}</div>}
      </div>
    </div>
  );
}

export default withStyles(styles)(PlayerCard);
