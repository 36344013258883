import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ProfileSetup, ProfileLinkFb } from 'components';
import { updateUserProfile } from 'redux/modules/users/userInfo';
import { fetchProviderInfo } from 'redux/modules/users';
import { linkFB } from 'redux/modules/users/providerInfo';
import { formatDashedPhone } from 'helpers/utils';
import { geocodeLocation, setUserLocation } from 'redux/modules/geoFire';

class ProfileSetupContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isUsingProviderInfo: true,
    };

    this.handleNonProviderInfo = this.handleNonProviderInfo.bind(this);
    this.handleUpdateProfile = this.handleUpdateProfile.bind(this);
  }
  componentDidMount() {
    // fetch provider info if not available
    if (
      !this.props.profileComplete &&
      this.props.accessToken &&
      this.props.fbId
    ) {
      this.props.fetchProviderInfo(this.props.fbId, this.props.accessToken);
    }
  }
  handleNonProviderInfo() {
    this.setState({
      isUsingProviderInfo: false,
    });
  }
  handleUpdateProfile(values) {
    return this.props
      .geocodeLocation(values.zip)
      .then(location => this.props.setUserLocation(location))
      .then(() => this.props.updateUserProfile(values, this.props.phone))
      .catch(error => {
        // show error in form
        console.log('error', error);
      });
  }
  render() {
    let screen;
    if (this.props.fbId || !this.state.isUsingProviderInfo) {
      screen = <ProfileSetup onUpdateProfile={this.handleUpdateProfile} />;
    } else {
      screen = (
        <ProfileLinkFb
          onLinkFB={this.props.linkFB}
          onNonProviderInfo={this.handleNonProviderInfo}
          phone={formatDashedPhone(this.props.phone)}
        />
      );
    }
    return <div>{screen}</div>;
  }
}

ProfileSetupContainer.propTypes = {
  updateUserProfile: PropTypes.func.isRequired,
  fetchProviderInfo: PropTypes.func.isRequired,
  linkFB: PropTypes.func.isRequired,
  geocodeLocation: PropTypes.func.isRequired,
  setUserLocation: PropTypes.func.isRequired,
  accessToken: PropTypes.string,
  fbId: PropTypes.string,
  profileComplete: PropTypes.bool.isRequired,
  phone: PropTypes.string,
};

function mapStateToProps({ users }) {
  const userInfo = users[users.authedId].info;
  return {
    accessToken: userInfo.accessToken,
    fbId: userInfo.fbId,
    profileComplete: userInfo.profileComplete,
    phone: userInfo.phone,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateUserProfile,
      fetchProviderInfo,
      linkFB,
      geocodeLocation,
      setUserLocation,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileSetupContainer);
