import React from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import InputLabel from '@material-ui/core/InputLabel';
import useStyles from './styles';

const TeamRosterFilters = ({
  isCoed,
  onStatusFilter,
  onGenderFilter,
  statusFilters,
  genderFilters,
}) => {
  const classes = useStyles();
  return (
    <thead className={classes.thead}>
      <tr>
        <th className={classes.labelColumn}>
          <InputLabel>Filters:</InputLabel>
        </th>
        <th colSpan="5" className={classes.filterColumn}>
          {isCoed && (
            <ToggleButtonGroup
              size="small"
              value={genderFilters}
              onChange={onGenderFilter}
              className={classes.toggleButtons}
            >
              <ToggleButton value={1} aria-label="female player filter">
                Female
              </ToggleButton>
              <ToggleButton value={0} aria-label="male player filter">
                Male
              </ToggleButton>
            </ToggleButtonGroup>
          )}
          <ToggleButtonGroup
            size="small"
            value={statusFilters}
            onChange={onStatusFilter}
            className={classes.toggleButtons}
          >
            <ToggleButton value={1} aria-label="active player filter">
              Active
            </ToggleButton>
            <ToggleButton value={2} aria-label="reserve player filter">
              Reserve
            </ToggleButton>
            <ToggleButton value={0} aria-label="invited player filter">
              Invited
            </ToggleButton>
          </ToggleButtonGroup>
        </th>
      </tr>
    </thead>
  );
};

TeamRosterFilters.propTypes = {
  isCoed: PropTypes.bool.isRequired,
  onStatusFilter: PropTypes.func.isRequired,
  onGenderFilter: PropTypes.func.isRequired,
  statusFilters: PropTypes.array.isRequired,
  genderFilters: PropTypes.array.isRequired,
};

export default TeamRosterFilters;
