import axios from 'axios';
import { getUserByPhone, saveInvitedUser } from 'helpers/auth';
import { addPlayerToTeam } from 'helpers/api';
const lambdaURL = 'https://us-central1-coach-cheetah-b6dcf.cloudfunctions.net';

function sendInvite(player) {
  return axios.post(`${lambdaURL}/inviteUser`, {
    ...player,
  });
}

export function inviteUser({
  phone,
  firstName,
  lastName,
  gender,
  invitedBy,
  invitedById,
  teamData,
}) {
  return getUserByPhone(phone).then(user => {
    const uid = user ? user.uid : null;
    console.log('uid', uid);
    teamData.uid = user ? user.uid : phone;
    const rosterData = user
      ? {
          avatar: user.avatar ? user.avatar : '',
          firstName: user.firstName,
          lastName: user.lastName,
          gender: user.gender,
          phone: user.phone,
          uid,
          invitedById,
          invitedBy,
        }
      : {
          avatar: '',
          firstName,
          lastName,
          gender,
          phone,
          uid: phone,
          invitedById,
          invitedBy,
        };
    console.log('rosterData', rosterData);
    return new Promise((resolve, reject) => {
      if (uid) {
        return resolve();
      } else {
        return saveInvitedUser(rosterData).then(() => resolve());
      }
    })
      .then(() =>
        addPlayerToTeam(teamData.teamId, rosterData, 'invite', teamData)
      )
      .then(() =>
        sendInvite({
          teamName: teamData.name,
          phone: rosterData.phone,
          sport: teamData.sport,
          invitedBy,
        })
      )
      .catch(error => console.log('error', error));
  });
}
