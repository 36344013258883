import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import validate from '../../../helpers/formValidate/addSeason';
import { Title, TextField, TextButton, PrimaryButton } from 'components/base';
import styles from 'sharedStyles/styles.module.css';

let AddSeason = ({
  handleSubmit,
  submitting,
  teamName,
  teamNameStr,
  valid,
  onSubmit,
}) => {
  return (
    <div className={`${styles.contentWrapper}`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title title="Add Season" subtitle={`Add a season for ${teamName}`} />
        <Field
          name="name"
          component={TextField}
          label="Season Name"
          helperText="i.e. Summer 1986"
          disabled={submitting}
        />
        <Field
          name="teamId"
          component={TextField}
          label="Team ID"
          disabled={submitting}
          className={styles.hiddenField}
        />
        <div className={styles.formActions}>
          <PrimaryButton type="submit" disabled={!valid || submitting}>
            Submit
          </PrimaryButton>
          <TextButton
            to={{
              pathname: `/${teamNameStr}/schedule`,
              query: { name: teamName },
            }}
          >
            Cancel
          </TextButton>
        </div>
      </form>
    </div>
  );
};

AddSeason.propTypes = {
  teamName: PropTypes.string.isRequired,
  valid: PropTypes.bool.isRequired,
  teamId: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

AddSeason = reduxForm({
  form: 'addSeason',
  validate,
})(AddSeason);

AddSeason = connect((state, ownProps) => {
  return {
    initialValues: {
      teamId: ownProps.teamId,
    },
  };
})(AddSeason);

export default AddSeason;
