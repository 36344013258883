import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { filter, map, sortBy, get } from 'lodash';
import { navToEventEdit } from 'redux/modules/events';
import { setNavItem } from 'redux/modules/usersTeams';
import { Schedule } from 'components';

class ScheduleContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showPastGames: false,
      isLoaded: false,
    };

    this.handleMenuTouch = this.handleMenuTouch.bind(this);
    this.handleTogglePast = this.handleTogglePast.bind(this);
  }
  componentDidMount() {
    this.props.setNavItem('schedule');
  }
  componentDidUpdate(prevProps) {
    if (this.props.selectedSeason !== prevProps.selectedSeason) {
      this.setState({ showPastGames: false });
    }
  }
  sortByTime(a, b) {
    if (a.timestamp < b.timestamp) return -1;
    if (a.timestamp > b.timestamp) return 1;
    return 0;
  }
  handleMenuTouch(event, child) {
    const eventId = child.props['data-event-id'];
    const action = child.props['data-action'];
    switch (action) {
      case 'edit':
        this.props.navToEventEdit(
          eventId,
          this.props.teamId,
          this.props.selectedSeason
        );
        break;
      case 'delete':
        break;
      default:
        return;
    }
  }
  getEvents() {
    const timestamp = new Date().getTime();
    return this.state.showPastGames
      ? this.props.events
      : filter(this.props.events, n => n.timestamp > timestamp);
  }
  handleTogglePast() {
    this.setState({
      showPastGames: !this.state.showPastGames,
    });
  }
  render() {
    return (
      <Schedule
        events={this.getEvents()}
        numberOfEvents={this.props.events.length}
        showPastGames={this.state.showPastGames}
        hasPastGames={this.props.hasPastGames}
        onTogglePast={this.handleTogglePast}
        teamId={this.props.teamId}
        teamNameStr={this.props.teamNameStr}
        isManager={this.props.isManager}
        selectedSeason={this.props.selectedSeason}
        selectedSeasonStr={this.props.selectedSeasonStr}
        isLoaded={this.props.eventsLoaded}
        onMenuTouch={this.handleMenuTouch}
        history={this.props.history}
      />
    );
  }
}

ScheduleContainer.propTypes = {
  selectedSeason: PropTypes.string,
  teamId: PropTypes.string.isRequired,
  teamNameStr: PropTypes.string.isRequired,
  isManager: PropTypes.bool.isRequired,
  navToEventEdit: PropTypes.func.isRequired,
  setNavItem: PropTypes.func.isRequired,
  eventsLoaded: PropTypes.bool.isRequired,
  selectedSeasonStr: PropTypes.string,
};

function mapStateToProps(
  { seasons, users, teams },
  { teamId, isManager, teamNameStr }
) {
  const teamSeasons = seasons[teamId] || {};
  const { selectedSeason, selectedSeasonStr } = teamSeasons;
  const events = sortBy(
    map(
      get(teamSeasons, `lookups.${selectedSeasonStr}.events`, {}),
      (val, key) => {
        return {
          ...val,
          name: key,
        };
      }
    ),
    ['timestamp']
  );
  const eventsLoaded = get(
    teamSeasons,
    `lookups.${selectedSeasonStr}.eventsLoaded`,
    false
  );
  return {
    teamId,
    teamNameStr,
    selectedSeason,
    selectedSeasonStr,
    isManager,
    events,
    eventsLoaded,
    hasPastGames: get(
      teamSeasons,
      `lookups.${selectedSeasonStr}.hasPastGames`,
      false
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      navToEventEdit,
      setNavItem,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleContainer);
