import { reduxForm } from 'redux-form';
import EventForm from './EventForm';
import validate from '../../helpers/formValidate/event';

// TODO: Do we need React and PropTypes?

let EventEdit = reduxForm({
  form: 'eventEdit',
  validate,
})(EventForm);

export default EventEdit;
