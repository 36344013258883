import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => {
  return {
    root: {
      background: theme.palette.primary.main,
      borderRadius: 0,
      border: 0,
      height: 36,
      lineHeight: '36px',
      textAlign: 'center',
      padding: '0 16px 0 16px',
      '&:hover': {
        background: 'rgba(238, 169, 4, 0.6)',
      },
      '&.Mui-disabled': {
        opacity: 0.65,
      },
    },
    sizeLarge: {
      height: 42,
      padding: '0 20px 0 20px',
    },
    label: {
      color: '#fff',
    },
  };
};

const StyledButton = withStyles(styles)(Button);

const PrimaryButton = ({ children, to, ...restOfProps }) => {
  return (
    <StyledButton component={to ? Link : 'button'} to={to} {...restOfProps}>
      {children}
    </StyledButton>
  );
};

PrimaryButton.displayName = 'PrimaryButton';
export default PrimaryButton;
