import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { InviteForm } from 'components';
import { resetInviteForm } from 'redux/modules/invites';
import { formatPhone } from 'helpers/utils';
import { addAlert } from 'redux/modules/alerts';
import { inviteUser } from 'helpers/invite';
import { addPlayer } from 'redux/modules/rosters';

class InviteFormContainer extends React.Component {
  handleInvite({ status, firstName, lastName, gender, phone }) {
    const formattedPhone = formatPhone(phone);
    // check if formatted phone is already on the roster
    let isActive = false;
    this.props.active.forEach((activePlayer, index) => {
      if (formattedPhone === activePlayer.phone) {
        isActive = true;
      }
    });
    if (isActive) {
      return this.props.addAlert(
        `${formattedPhone} is already linked to an active player.`
      );
    }
    let isReserve = false;
    this.props.reserve.forEach((reservePlayer, index) => {
      if (formattedPhone === reservePlayer.phone) {
        isReserve = true;
      }
    });
    if (isReserve) {
      return this.props.addAlert(
        `${formattedPhone} is already linked to a reserve player.`
      );
    }
    let isInvited = false;
    this.props.invites.forEach((invitedPlayer, index) => {
      if (formattedPhone === invitedPlayer.phone) {
        isInvited = true;
      }
    });
    if (isInvited) {
      return this.props.addAlert(`${formattedPhone} has already been invited.`);
    }

    const teamData = {
      gender: this.props.gender,
      name: this.props.teamName,
      sport: this.props.sport,
      teamId: this.props.teamId,
      status,
    };

    return inviteUser({
      phone: formattedPhone,
      firstName,
      lastName,
      gender: gender ? gender : this.props.gender,
      invitedBy: this.props.invitedBy,
      invitedById: this.props.uid,
      teamData,
    })
      .then(() => {
        this.props.addAlert(`Invite sent to ${firstName}.`);
        this.props.resetInviteForm();
      })
      .catch(() =>
        this.props.addAlert('Unable to send invite. Please try again later.')
      );
  }
  render() {
    return (
      <InviteForm
        onInvite={this.handleInvite.bind(this)}
        teamId={this.props.teamId}
        isCoed={this.props.isCoed}
        isManager={this.props.isManager}
      />
    );
  }
}

InviteFormContainer.propTypes = {
  teamId: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  gender: PropTypes.string.isRequired,
  isCoed: PropTypes.bool.isRequired,
  sport: PropTypes.string.isRequired,
  isManager: PropTypes.bool.isRequired,
  uid: PropTypes.string.isRequired,
  invitedBy: PropTypes.string.isRequired,
  invites: PropTypes.array.isRequired,
  active: PropTypes.array.isRequired,
  reserve: PropTypes.array.isRequired,
  resetInviteForm: PropTypes.func.isRequired,
  addAlert: PropTypes.func.isRequired,
  addPlayer: PropTypes.func.isRequired,
};

function mapStateToProps({ teams, users, rosters, invites }, ownProps) {
  let active = [];
  let reserve = [];
  let invited = [];
  if (rosters[ownProps.teamId]) {
    if (rosters[ownProps.teamId].active) {
      active = rosters[ownProps.teamId].active;
    }
    if (rosters[ownProps.teamId].reserve) {
      reserve = rosters[ownProps.teamId].reserve;
    }
    if (rosters[ownProps.teamId].invites) {
      invited = rosters[ownProps.teamId].invites;
    }
  }
  const lastInitial = users[users.authedId].info.lastName
    .substring(0, 1)
    .toUpperCase();
  return {
    teamId: ownProps.teamId,
    teamName: teams[ownProps.teamId].name,
    gender: teams[ownProps.teamId].gender,
    isCoed: teams[ownProps.teamId].gender === 'coed',
    sport: teams[ownProps.teamId].sport,
    isManager: users.authedId === teams[ownProps.teamId].uid,
    uid: users.authedId,
    invitedBy: `${users[users.authedId].info.firstName} ${lastInitial}.`,
    invites: invited,
    active: active,
    reserve: reserve,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetInviteForm,
      addAlert,
      addPlayer,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteFormContainer);
