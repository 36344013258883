import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { find } from 'lodash';
import { updateRSVP, addRSVP } from '../../../helpers/apiEvents';

const withRSVP = WrappedComponent =>
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        rsvpStatus: props.rsvpStatus,
      };
    }

    static getDerivedStateFromProps = (props, state) => {
      if (props.rsvpStatus !== state.rsvpStatus) {
        return {
          rsvpStatus: props.rsvpStatus,
        };
      }
      return null;
    };

    handleRSVP = (_event, rsvpStatus) => {
      if (rsvpStatus !== null) {
        // optimistic update
        this.setState({ rsvpStatus });
        // update status
        const handler = this.props.rsvpStatus ? updateRSVP : addRSVP;
        const {
          teamId,
          eventId,
          seasonId,
          userInfo,
          uid,
          teamName,
          opponent,
          timestamp,
          timeZoneId,
        } = this.props;
        handler(
          teamId,
          seasonId,
          eventId,
          uid,
          rsvpStatus,
          userInfo,
          teamName,
          opponent,
          timestamp,
          timeZoneId
        );
      }
    };

    render() {
      const { rsvpStatus } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          rsvpStatus={
            rsvpStatus !== undefined ? rsvpStatus : this.props.rsvpStatus
          }
          handleRSVP={this.handleRSVP}
        />
      );
    }
  };

const mapStateToProps = (
  { events, users, rosters, teams },
  { teamId, eventId }
) => {
  // get user info
  const uid = users.authedId;
  const { avatar, firstName, lastName, gender, phone, profilePic } = users[
    uid
  ].info;
  // get active roster IDs (for status)
  const { [teamId]: { active = [] } = {} } = rosters;
  const activeIds = active.map(player => player.uid);
  // get RSVP info
  const {
    [eventId]: { RSVPs = [], opponent, timestamp, timeZoneId } = {},
  } = events;
  const { rsvp: rsvpStatus } = find(RSVPs, ['uid', uid]) || {};
  // get team info
  const { name } = teams[teamId] || {};

  return {
    rsvpStatus,
    uid,
    teamName: name,
    opponent,
    timestamp,
    timeZoneId,
    userInfo: {
      avatar,
      firstName,
      lastName,
      gender,
      phone,
      profilePic,
      uid,
      status: activeIds.includes(uid) ? 'active' : 'reserve',
    },
  };
};

const composedWithRSVP = compose(connect(mapStateToProps, null), withRSVP);

export default composedWithRSVP;
