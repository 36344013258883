import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { renderInviteFields } from './InviteForm';
import validate from 'helpers/formValidate/invite';
import { formatDashedPhone } from 'helpers/utils';
import { inviteEditSubmit } from 'containers/Team/Roster/InviteEditContainer';

let InviteEdit = ({ handleSubmit, submitting, isCoed, isManager }) => {
  return (
    <form onSubmit={handleSubmit}>
      {renderInviteFields(submitting, isManager, isCoed)}
    </form>
  );
};

InviteEdit = reduxForm({
  form: 'inviteEdit',
  validate,
  onSubmit: inviteEditSubmit,
})(InviteEdit);

InviteEdit = connect((state, ownProps) => {
  return {
    initialValues: {
      firstName: ownProps.invite.rosterData.firstName,
      lastName: ownProps.invite.rosterData.lastName,
      gender: ownProps.invite.rosterData.gender,
      status: ownProps.invite.status,
      isCoed: ownProps.invite.userTeamsData.gender === 'coed',
      teamId: ownProps.invite.teamId,
      inviteId: ownProps.invite.inviteId,
      phone: formatDashedPhone(ownProps.invite.rosterData.phone),
      oldPhone: ownProps.invite.rosterData.phone,
    },
  };
})(InviteEdit);

export default InviteEdit;
