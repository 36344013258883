import { filter } from 'lodash';
import { getUsersTeams } from 'helpers/api';
import { stringToURL } from 'helpers/strings';

const FETCHING_USERS_TEAMS = 'FETCHING_USERS_TEAMS';
const FETCHING_USERS_TEAMS_ERROR = 'FETCHING_USERS_TEAMS_ERROR';
const FETCHING_USERS_TEAMS_SUCCESS = 'FETCHING_USERS_TEAMS_SUCCESS';
const ADD_SINGLE_USERS_TEAM = 'ADD_SINGLE_USERS_TEAM';
const REMOVE_SINGLE_USERS_TEAM = 'REMOVE_SINGLE_USERS_TEAM';
const UPDATE_SINGLE_USERS_TEAM = 'UPDATE_SINGLE_USERS_TEAM';
const UPDATE_NAV_ITEM = 'UPDATE_NAV_ITEM';

function fetchingUsersTeams(uid) {
  return {
    type: FETCHING_USERS_TEAMS,
    uid,
  };
}

function fetchingUsersTeamsError(error) {
  return {
    type: FETCHING_USERS_TEAMS_ERROR,
    error: 'Error fetching Users Team Ids',
  };
}

function fetchingUsersTeamsSuccess(uid, teams, lastUpdated) {
  let lookups = {};
  teams.forEach(team => {
    lookups[stringToURL(team[1])] = {
      id: team[0],
      name: team[1],
      isManager: team[2],
      sport: team[3],
    };
  });
  return {
    type: FETCHING_USERS_TEAMS_SUCCESS,
    uid,
    teams,
    lookups,
    lastUpdated,
  };
}

function updateNavItem(navItem) {
  return {
    type: UPDATE_NAV_ITEM,
    navItem,
  };
}

export function addSingleUsersTeam(uid, teamId, teamName, sport, lastUpdated) {
  return {
    type: ADD_SINGLE_USERS_TEAM,
    uid,
    teamId,
    teamName,
    sport,
    lastUpdated,
  };
}

export function removeSingleUsersTeam(uid, teamId, lastUpdated) {
  return {
    type: REMOVE_SINGLE_USERS_TEAM,
    uid,
    teamId,
    lastUpdated,
  };
}

export function updateSingleUsersTeam(uid, { name, sport }, teamId) {
  return {
    type: UPDATE_SINGLE_USERS_TEAM,
    uid,
    name,
    sport,
    teamId,
  };
}

export function setNavItem(navItem) {
  return function(dispatch) {
    dispatch(updateNavItem(navItem));
  };
}

export function fetchUsersTeams(uid) {
  return function(dispatch) {
    dispatch(fetchingUsersTeams(uid));

    return getUsersTeams(uid)
      .then(querySnapshot => {
        var res = [];
        querySnapshot.forEach(doc => {
          let teamData = doc.data();
          let isManager = uid === teamData.uid;
          res.push([teamData.teamId, teamData.name, isManager, teamData.sport]);
        });
        dispatch(fetchingUsersTeamsSuccess(uid, res, Date.now()));
      })
      .catch(error => {
        console.log('error', error);
        dispatch(fetchingUsersTeamsError(error));
      });
  };
}

const initialUsersTeamState = {
  lastUpdated: 0,
  teamIds: [],
};

function usersTeam(state = initialUsersTeamState, action) {
  switch (action.type) {
    case ADD_SINGLE_USERS_TEAM:
      return {
        ...state,
        teams: state.teams.concat([
          [action.teamId, action.teamName, true, action.sport],
        ]),
        lookups: {
          [stringToURL(action.teamName)]: {
            id: action.teamId,
            name: action.teamName,
            isManager: true,
            sport: action.sport,
          },
          ...state.lookups,
        },
        lastUpdated: action.lastUpdated,
      };
    case REMOVE_SINGLE_USERS_TEAM:
      return {
        ...state,
        teams: state.teams.filter(team => {
          return team[0] !== action.teamId;
        }),
        lookups: filter(state.lookups, o => {
          return o.id !== action.teamId;
        }),
        lastUpdated: action.lastUpdated,
      };
    case UPDATE_SINGLE_USERS_TEAM:
      let oldName;
      const teams = state.teams.map(team => {
        if (team[0] === action.teamId) {
          oldName = team[1];
          return [team[0], action.name, team[2], action.sport];
        } else {
          return team;
        }
      });
      return {
        ...state,
        teams,
        lookups: {
          [stringToURL(action.name)]: {
            ...state.lookups[stringToURL(oldName)],
            name: action.name,
            sport: action.sport,
          },
          [stringToURL(oldName)]: {
            ...state.lookups[stringToURL(oldName)],
            name: action.name,
            sport: action.sport,
          },
          ...state.lookups,
        },
      };
    default:
      return state;
  }
}

const initialState = {
  isFetching: true,
  error: '',
  navItem: null,
};

export default function usersTeams(state = initialState, action) {
  switch (action.type) {
    case FETCHING_USERS_TEAMS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCHING_USERS_TEAMS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case FETCHING_USERS_TEAMS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: '',
        [action.uid]: {
          lastUpdated: action.lastUpdated,
          teams: action.teams,
          lookups: action.lookups,
        },
      };
    case ADD_SINGLE_USERS_TEAM:
    case REMOVE_SINGLE_USERS_TEAM:
    case UPDATE_SINGLE_USERS_TEAM:
      return typeof state[action.uid] === 'undefined'
        ? state
        : {
            ...state,
            isFetching: false,
            error: '',
            [action.uid]: usersTeam(state[action.uid], action),
          };
    case UPDATE_NAV_ITEM:
      return {
        ...state,
        navItem: action.navItem,
      };
    default:
      return state;
  }
}
