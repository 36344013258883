import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    paddingTop: 6,
    width: 64,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  month: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: 12,
    lineHeight: 1,
  },
  day: {
    fontSize: 24,
    lineHeight: '1.75rem',
    fontWeight: 100,
  },
});

const EventDate = ({ startMoment, classes }) =>
  startMoment ? (
    <div className={classes.root}>
      <span className={classes.month}>
        {startMoment && startMoment.format('MMM')}
      </span>
      <span className={classes.day}>
        {startMoment && startMoment.format('D')}
      </span>
    </div>
  ) : null;

EventDate.propTypes = {
  classes: PropTypes.object.isRequired,
};

const eventDate = withStyles(styles)(EventDate);
eventDate.displayName = 'EventDate';

export default eventDate;
