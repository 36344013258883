const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Required';
  }
  if (!values.sport) {
    errors.sport = 'Required';
  }
  if (!values.gender) {
    errors.gender = 'Required';
  }
  return errors;
};

export default validate;
