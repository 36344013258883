import { GeoFire } from 'geofire';
import { database, ref, firebaseAuth } from '../config/constants';

const usersFirebaseRef = database.ref('geoFire/users');
const locationsFirebaseRef = database.ref('geoFire/locations');

const geoFire = new GeoFire(usersFirebaseRef);
const locationsGeoFire = new GeoFire(locationsFirebaseRef);

export function saveUserLocation(location) {
  const uid = firebaseAuth().currentUser.uid;
  return geoFire.set(uid, [location.lat, location.lng]).then(() => {
    return {
      uid,
      location,
    };
  });
}

export function getUserLatLong() {
  const uid = firebaseAuth().currentUser.uid;
  return ref
    .child(`geoFire/users/${uid}/l`)
    .once('value')
    .then(snapshot => {
      const location = snapshot.val();
      return {
        uid,
        location: {
          lat: location[0],
          lng: location[1],
        },
      };
    });
}

export function getLocations(center, radius = 100) {
  return locationsGeoFire.query({
    center: center,
    radius: radius,
  });
}
