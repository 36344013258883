import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Toolbar, ToolbarButtons, ToolbarPicker } from 'components/base';

const styles = theme => ({
  container: {
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
  toolbar: {
    [theme.breakpoints.down('sm')]: {
      margin: '0 -20px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
});

function Seasons({
  classes,
  teamId,
  teamNameStr,
  isManager,
  seasons,
  isSeasons,
  selectedSeason,
  selectedSeasonStr,
  onChangeSeason,
  showPastGames,
  hasPastGames,
  onTogglePast,
  onAddSeason,
}) {
  let menuItems = [];
  if (hasPastGames) {
    menuItems.push({
      text: showPastGames ? 'Hide Past Games' : 'Show Past Games',
      handler: onTogglePast,
    });
  }
  if (isManager) {
    menuItems.push({
      text: 'Add Season',
      handler: onAddSeason,
    });
  }

  let options = seasons.map(({ seasonId, name }) => [seasonId, name]);

  if (isManager) {
    options.push([1, 'Add season!']);
  }

  return (
    <div className={classes.container}>
      <Toolbar className={classes.toolbar}>
        {isSeasons && selectedSeason ? (
          <React.Fragment>
            <ToolbarPicker
              title="Seasons:"
              options={options}
              selectProps={{
                value: selectedSeason,
                onChange: onChangeSeason,
              }}
            />
            <ToolbarButtons
              buttonText={isManager ? 'Add Game' : null}
              buttonProps={{
                disabled: !selectedSeason,
                to: {
                  pathname: `/${teamNameStr}/${selectedSeasonStr}/addEvent`,
                },
              }}
              menuItems={menuItems}
              alignRight={true}
            />
          </React.Fragment>
        ) : null}
        {isSeasons && !selectedSeason ? (
          <React.Fragment>
            <ToolbarPicker title="No seasons added" />
            <ToolbarButtons
              buttonText={isManager ? 'Add Season' : null}
              buttonProps={{
                to: { pathname: `/${teamNameStr}/addSeason` },
              }}
            />
          </React.Fragment>
        ) : null}
      </Toolbar>
    </div>
  );
}

Seasons.defaultProps = {
  hasPastGames: false,
};

export default withStyles(styles)(Seasons);
