import axios from 'axios';
const fbGraphURL = 'https://graph.facebook.com/v2.7';
const fbGraphURL3 = 'https://graph.facebook.com/v3.2';

export function getProfileInfo(fbId, accessToken) {
  return axios.get(
    `${fbGraphURL}/${fbId}?fields=email,location,first_name,last_name,gender&access_token=${accessToken}`
  );
}

export function getPicture(fbId, accessToken) {
  return axios.get(
    `${fbGraphURL3}/${fbId}/picture?type=large&redirect=false&access_token=${accessToken}`
  );
}
