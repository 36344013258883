import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TeamDelete } from 'components';
import { deleteTeam } from 'redux/modules/teams';
import { setNavItem } from 'redux/modules/usersTeams';

class TeamDeleteContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isModalOpen: false,
      isDeleting: false,
    };

    this.handleDeleteTeam = this.handleDeleteTeam.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  handleOpenModal() {
    this.setState({ isModalOpen: true });
  }
  handleCloseModal() {
    this.setState({ isModalOpen: false });
  }
  handleDeleteTeam() {
    this.setState({ isDeleting: true });
    this.props.deleteTeam(this.props.teamId, this.props.uid).then(() => {
      this.props.history.push('/feed');
    });
  }
  render() {
    return (
      <TeamDelete
        onDeleteTeam={this.handleDeleteTeam}
        onOpenModal={this.handleOpenModal}
        onCloseModal={this.handleCloseModal}
        teamName={this.props.teamName}
        isModalOpen={this.state.isModalOpen}
        isDeleting={this.state.isDeleting}
      />
    );
  }
}

TeamDeleteContainer.propTypes = {
  teamId: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  deleteTeam: PropTypes.func.isRequired,
  setNavItem: PropTypes.func.isRequired,
};

function mapStateToProps({ teams, users }, { teamId, teamName }) {
  return {
    teamId,
    teamName,
    uid: users.authedId,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteTeam,
      setNavItem,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamDeleteContainer);
