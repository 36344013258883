const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (!values.lastName) {
    errors.lastName = 'Required';
  }
  if (!values.status) {
    errors.status = 'Required';
  }
  if (!values.phone) {
    errors.phone = 'Required';
  }
  if (values.isCoed && !values.gender) {
    errors.gender = 'Required';
  }
  if (values.phone && values.phone.toString().length !== 12) {
    errors.phone = 'Must be a valid phone number';
  }
  return errors;
};

export default validate;
