import React from 'react';
import { Field } from 'redux-form';
import { RadioButtons, RangeField } from 'components/base';

export default function RosterFields({ submitting, isCoed }) {
  return (
    <React.Fragment>
      <Field
        name="gender"
        label="Gender"
        options={[
          {
            value: 'coed',
            label: 'Coed',
          },
          {
            value: 'female',
            label: 'Female',
          },
          {
            value: 'male',
            label: 'Male',
          },
        ]}
        disabled={submitting}
        component={RadioButtons}
      />
      {isCoed ? (
        <React.Fragment>
          <Field
            component={RangeField}
            label="Number of female players"
            name="femalePlayers"
            max={12}
            initialValue={[3, 7]}
            getAriaLabel={number =>
              number ? 'Maximum female players' : 'Minimum female players'
            }
            getAriaValueText={(value, index) =>
              index
                ? `${value} maximum female players`
                : `${value} minimum female players`
            }
            helperText={'Desired min and max female players'}
            disabled={submitting}
          />
          <Field
            component={RangeField}
            label="Number of male players"
            name="malePlayers"
            max={12}
            initialValue={[3, 7]}
            getAriaLabel={number =>
              number ? 'Maximum male players' : 'Minimum male players'
            }
            getAriaValueText={(value, index) =>
              index
                ? `${value} maximum male players`
                : `${value} minimum male players`
            }
            helperText={'Desired min and max male players'}
            disabled={submitting}
          />
        </React.Fragment>
      ) : (
        <Field
          component={RangeField}
          label="Number of players"
          name="players"
          max={25}
          initialValue={[5, 13]}
          getAriaLabel={number =>
            number ? 'Maximum players' : 'Minimum players'
          }
          getAriaValueText={(value, index) =>
            index ? `${value} maximum players` : `${value} minimum players`
          }
          helperText={'Desired min and max players'}
          disabled={submitting}
        />
      )}
    </React.Fragment>
  );
}
