import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Feed } from 'components';

class FeedContainer extends React.Component {
  render() {
    return <Feed teams={this.props.teams} isIOS={this.props.isIOS} />;
  }
}

FeedContainer.propTypes = {
  teams: PropTypes.array.isRequired,
};

function mapStateToProps({ users, usersTeams, viewport }) {
  return {
    teams: usersTeams[users.authedId] ? usersTeams[users.authedId].teams : [],
    isIOS: viewport.iOS,
  };
}

export default connect(mapStateToProps, null)(FeedContainer);
