import React from 'react';
import { Home } from 'components';

// TODO: Do we need a container?

class HomeContainer extends React.Component {
  render() {
    return <Home />;
  }
}

export default HomeContainer;
