import React from 'react';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  EventTime,
  EventOpponent,
  EventLocation,
  EventHeadcount,
  EventRSVP,
} from 'components/base';
import {
  eventPreview,
  eventOpponentLocation,
  eventPreviewContent,
  eventRSVPs,
  eventTimeContainer,
} from './eventPreview.module.css';

export default function EventPreview({
  teamId,
  eventId,
  eventName,
  teamNameStr,
  selectedSeasonStr,
  seasonId,
  startMoment,
  isHome,
  opponent,
  address,
  location,
  locationId,
  isRSVPedYes,
  isRSVPedNo,
  totalRSVPs,
  maleRSVPs,
  femaleRSVPs,
  teamGender,
  onToggleRSVP,
}) {
  const isThisWeek =
    startMoment.isAfter(moment()) && startMoment.isBefore(moment().add(6, 'd'));
  return (
    <Paper
      className={eventPreview}
      component={Link}
      to={{
        pathname: `/${teamNameStr}/${selectedSeasonStr}/${eventName}`,
        query: { seasonId: seasonId },
      }}
    >
      <div className={eventPreviewContent}>
        <div className={eventTimeContainer}>
          <EventTime startMoment={startMoment} isThisWeek={isThisWeek} />
        </div>
        <div className={eventOpponentLocation}>
          <EventOpponent isHome={isHome} opponent={opponent} />
          <EventLocation address={location || address} placeId={locationId} />
        </div>
        <div className={eventRSVPs}>
          <EventRSVP
            isRSVPedYes={isRSVPedYes}
            isRSVPedNo={isRSVPedNo}
            onToggleRSVP={onToggleRSVP}
            isThisWeek={isThisWeek}
          />
          <EventHeadcount
            totalRSVPs={totalRSVPs}
            maleRSVPs={maleRSVPs}
            femaleRSVPs={femaleRSVPs}
            teamGender={teamGender}
            isThisWeek={isThisWeek}
          />
        </div>
      </div>
    </Paper>
  );
}
