import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TeamCreate } from 'components';
import { setTeam } from 'redux/modules/teams';
import { addPlayer } from 'redux/modules/rosters';
import { get } from 'lodash';
import { stringToURL } from 'helpers/strings';

function formatMinMax(gender, players, malePlayers, femalePlayers) {
  if (gender === 'coed') {
    const [minMale, maxMale] = malePlayers.split(',');
    const [minFemale, maxFemale] = femalePlayers.split(',');
    return {
      minMale,
      maxMale,
      minFemale,
      maxFemale,
    };
  } else {
    const [minPlayer, maxPlayer] = players.split(',');
    return {
      minPlayer,
      maxPlayer,
    };
  }
}

class TeamCreateContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleTeamCreate = this.handleTeamCreate.bind(this);
  }
  handleTeamCreate({ players, malePlayers, femalePlayers, ...values }) {
    let teamInfo = {
      uid: this.props.uid,
      ...formatMinMax(values.gender, players, malePlayers, femalePlayers),
      ...values,
    };
    return this.props
      .setTeam(teamInfo)
      .then(teamId => {
        this.props.addPlayer(teamId, this.props.manager, 'active', {
          ...teamInfo,
          teamId,
        });
        return teamId;
      })
      .then(() => {
        return this.props.history.push(`${stringToURL(values.name)}/schedule`);
      });
  }
  render() {
    return (
      <TeamCreate
        onTeamCreate={this.handleTeamCreate}
        onGenderChange={this.handleGenderChange}
        isCoed={this.props.isCoed}
      />
    );
  }
}

TeamCreateContainer.propTypes = {
  setTeam: PropTypes.func.isRequired,
  addPlayer: PropTypes.func.isRequired,
  uid: PropTypes.string.isRequired,
};

function mapStateToProps({ users, form }) {
  let userInfo = users[users.authedId].info;
  const teamGender = get(form, 'teamCreate.values.gender', false);
  return {
    uid: users.authedId,
    manager: {
      profilePic: userInfo.profilePic,
      phone: userInfo.phone,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      gender: userInfo.gender,
      uid: userInfo.uid,
    },
    isCoed: teamGender === 'coed',
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setTeam,
      addPlayer,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamCreateContainer);
