import React from 'react';
import PropTypes from 'prop-types';
import { DateTimePicker as MuiDateTimePicker } from '@material-ui/pickers';
import { getTimeZoneName, getTimeZoneAbbreviation } from 'helpers/dateTime';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Today from '@material-ui/icons/Today';
import TextField from './TextField';

const InputEndAdorment = ({ disabled }) => (
  <InputAdornment position="end">
    <IconButton aria-label="Select an event start time." disabled={disabled}>
      <Today />
    </IconButton>
  </InputAdornment>
);

class DateTimePicker extends React.Component {
  constructor(props) {
    super(props);

    const { initial } = props.meta;
    const timeZone = getTimeZoneName();
    const timeZoneAbbr = getTimeZoneAbbreviation();

    this.state = {
      shrink: !!initial,
      timeZone,
      timeZoneAbbr,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = dateTime => {
    this.props.input.onChange(dateTime.valueOf());
    this.setState({
      dateTime,
      shrink: true,
    });
  };

  handleOpen = ev => {
    this.props.input.onFocus();
    this.setState({
      shrink: true,
    });
  };

  handleClose = () => {
    this.props.input.onBlur();
    this.setState(state => {
      return {
        shrink: state.dateTime !== null,
      };
    });
  };

  render() {
    const {
      label,
      disablePast,
      input: { value },
      disabled,
      ...restOfProps
    } = this.props;
    const { timeZone, timeZoneAbbr, shrink } = this.state;
    const {
      meta: { error, touched },
    } = restOfProps;
    const isError = touched && error && error.length > 0;
    return (
      <MuiDateTimePicker
        autoOk={true}
        format="LLL do h:mm a"
        value={value ? new Date(value) : null}
        disablePast={disablePast}
        error={isError}
        disabled={disabled}
        label={label}
        onChange={this.handleChange}
        helperText={`${timeZone} (${timeZoneAbbr})`}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        TextFieldComponent={TextField}
        InputLabelProps={{
          shrink,
        }}
        InputProps={{
          endAdornment: <InputEndAdorment disabled={disabled} />,
        }}
      />
    );
  }
}

DateTimePicker.defaultProps = {
  meta: {},
  input: {
    onChange: () => true,
    onBlur: () => true,
    onFocus: () => true,
  },
  disablePast: true,
};

DateTimePicker.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  disablePast: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DateTimePicker;
