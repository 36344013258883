import { ref, firebaseAuth, database, db } from '../config/constants';

export function saveAccessToken(accessToken) {
  const uid = firebaseAuth().currentUser.uid;
  return db
    .collection('users')
    .doc(uid)
    .set(
      {
        accessToken: accessToken,
      },
      { merge: true }
    )
    .then(() => {
      return {
        uid: uid,
        accessToken: accessToken,
      };
    });
}

export function savePhoneLookup(phone) {
  const uid = firebaseAuth().currentUser.uid;
  return db
    .collection('phoneLookup')
    .doc(phone)
    .set({
      uid: uid,
    })
    .then(() => {
      return {
        uid,
        phone,
      };
    });
}

export function savePhone(phone) {
  const uid = firebaseAuth().currentUser.uid;
  return db
    .collection('users')
    .doc(uid)
    .set(
      {
        phone: phone,
      },
      { merge: true }
    )
    .then(() => {
      return {
        uid,
        phone,
      };
    });
}

export function updateUserProfile(
  firstName,
  lastName,
  gender,
  zip,
  email,
  phone
) {
  const uid = firebaseAuth().currentUser.uid;

  const profileData = {
    firstName,
    lastName,
    gender,
    zip,
    email,
    profileComplete: true,
  };

  return (
    db
      .doc(`users/${uid}`)
      .set(profileData, { merge: true })
      .then(() => {
        // FB FIRESTORE TODO
        return ref
          .child(`usersTeams/${uid}`)
          .once('value')
          .then(snapshot => {
            var updates = {};
            snapshot.forEach(function(team) {
              var teamData = team.val();
              var basePath = `rosters/${teamData.teamId}/${teamData.status}/${uid}`;
              updates[`${basePath}/firstName`] = firstName;
              updates[`${basePath}/lastName`] = lastName;
              updates[`${basePath}/gender`] = gender;
            });
            return ref.update(updates);
          });
      })
      // FB FIRESTORE TODO
      .then(() => {
        return ref
          .child(`invites/phoneLookup/${phone}`)
          .once('value')
          .then(snapshot => {
            var updates = {};
            snapshot.forEach(function(invite) {
              var inviteData = invite.val();
              var basePath = `invites/${inviteData.teamId}/${inviteData.inviteId}/rosterData`;
              updates[`${basePath}/firstName`] = firstName;
              updates[`${basePath}/lastName`] = lastName;
              updates[`${basePath}/gender`] = gender;
            });
            return ref.update(updates);
          });
      })
      .then(() => {
        return {
          uid,
          firstName,
          lastName,
          gender,
          zip,
          email,
          profileComplete: true,
        };
      })
      .catch(error => {
        console.log('error updating profile', error);
      })
  );
}

// TODO: Do we need these commented sections?

// function saveToUsersTeams (team, teamId) {
//   return db.doc(`users/${uid}/teams/teamId`).set({...team, teamId});
// }

// function saveMinToRoster (team, teamId) {
//   let data = {};
//   if(team.minPlayer) {
//     data.minPlayer = team.minPlayer;
//   } else {
//     data.minMale = team.minMale;
//     data.minFemale = team.minFemale;
//   }
//   return ref.child(`rosters/${teamId}`).set(data);
// }

export function saveTeam(team) {
  const teamRef = db.collection('teams');
  return teamRef.add(team).then(docRef => {
    return {
      ...team,
      teamId: docRef.id,
    };
  });
}

export function getUsersTeams(uid) {
  return db.collection(`users/${uid}/teams`).get();
}

export function getTeam(teamId) {
  return db
    .doc(`teams/${teamId}`)
    .get()
    .then(doc => {
      if (doc.exists) {
        const teamData = doc.data();
        return {
          ...teamData,
          teamId: doc.id,
        };
      }
    });
}

export function removeTeam(teamId, uid) {
  return db
    .collection('teams')
    .doc(teamId)
    .delete();
}

export function addPlayerToTeam(teamId, player, status, teamData) {
  const type = status;
  var batch = db.batch();
  let rosterRef = db.doc(`teams/${teamId}/roster/${player.uid}`);
  batch.set(rosterRef, { ...player, type });
  const collection = status === 'invite' ? 'invites' : 'teams';
  let userTeamRef = db.doc(`users/${player.uid}/${collection}/${teamId}`);
  batch.set(userTeamRef, { ...teamData, teamId });
  return batch.commit();
}

export function deletePlayerFromTeam(teamId, uid) {
  return db.doc(`teams/${teamId}/roster/${uid}`).delete();
}

export function invitePlayer(inviteData) {
  const inviteRef = database.ref(`invites/${inviteData.teamId}`).push();
  const inviteId = inviteRef.path.o[2];
  return inviteRef
    .set({
      ...inviteData,
      inviteId,
    })
    .then(response => {
      ref
        .child(
          `invites/phoneLookup/${inviteData.rosterData.phone}/${inviteData.teamId}`
        )
        .set({
          teamId: inviteData.teamId,
          teamName: inviteData.userTeamsData.name,
          inviteId,
        });
    })
    .then(response => {
      return {
        ...inviteData,
        inviteId,
      };
    });
}

export function saveInviteData(inviteData, uid) {
  const inviteRef = db.collection(`users/${uid}/invites`);
  return inviteRef.add(inviteData).then(docRef => {
    return {
      ...inviteData,
      inviteId: docRef.id,
    };
  });
}

export function updateInvitedPlayer(player) {
  return ref
    .child(`invites/${player.teamId}/${player.inviteId}/rosterData`)
    .update({
      ...player.rosterData,
    })
    .then(() => {
      return ref
        .child(`invites/${player.teamId}/${player.inviteId}/status`)
        .set(player.status);
    });
}

export function updateInvitedPhoneLookup(player) {
  return ref
    .child(`invites/phoneLookup/${player.oldPhone}`)
    .remove()
    .then(() => {
      return ref.child(`invites/phoneLookup/${player.phone}`).set({
        teamId: player.teamId,
        inviteId: player.inviteId,
      });
    });
}

export function deleteInvite(invite) {
  return ref
    .child(`invites/${invite.teamId}/${invite.inviteId}`)
    .remove()
    .then(() =>
      ref.child(`invites/phoneLookup/${invite.rosterData.phone}`).remove()
    );
}
