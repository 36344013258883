import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { Title, PrimaryButton } from 'components/base';
import InfoFields from './InfoFields';
import RosterFields from './RosterFields';
import styles from 'sharedStyles/styles.module.css';

const useStyles = makeStyles(theme => ({
  divider: {
    marginBottom: theme.spacing(2),
  },
  fields: {
    maxWidth: 300,
  },
  actions: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  rightFields: {
    paddingTop: 3,
  },
}));

export default function TeamInfoForm({
  isCoed,
  submitting,
  pristine,
  valid,
  handleSubmit,
  onTeamUpdate,
  initialValues: { name },
}) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Title title="Info" subtitle={`Update the team info for ${name}`} />
      <Divider className={classes.divider} />
      <form
        className={styles.formWrapper}
        onSubmit={handleSubmit(onTeamUpdate)}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <div className={classes.fields}>
              <InfoFields submitting={submitting} isEdit />
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.rightFields}>
            <div className={classes.fields}>
              <RosterFields submitting={submitting} isCoed={isCoed} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.actions}>
              <PrimaryButton
                disabled={pristine || !valid || submitting}
                type="submit"
              >
                Update {name}
              </PrimaryButton>
            </div>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}
