import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import EventForm from './EventForm';
import validate from 'helpers/formValidate/event';

// TODO: Do we need PropTypes?

let EventCreate = reduxForm({
  form: 'eventCreate',
  validate,
  enableReinitialize: true,
})(EventForm);

EventCreate = connect((props, { seasonId }) => {
  return {
    initialValues: {
      seasonId,
      date: null,
    },
  };
})(EventCreate);

export default EventCreate;
