import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import { AuthedNavigation, UnauthedNavigation } from 'components';
import { logoutAndUnauth } from 'redux/modules/users';

class NavigationContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      open: false,
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.handleRequestChange = this.handleRequestChange.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }
  handleToggle() {
    this.setState({ open: !this.state.open });
  }
  handleRequestChange(open) {
    this.setState({ open });
  }
  handleLogout() {
    this.handleToggle();
    return this.props.logoutAndUnauth();
  }
  render() {
    return this.props.isAuthed === true ? (
      <AuthedNavigation
        onLogout={this.handleLogout}
        onToggle={this.handleToggle}
        onRequestChange={this.handleRequestChange}
        isOpen={this.state.open}
        firstName={this.props.firstName}
        profilePic={this.props.profilePic}
      />
    ) : (
      <UnauthedNavigation />
    );
  }
}

NavigationContainer.propTypes = {
  logoutAndUnauth: PropTypes.func.isRequired,
  isAuthed: PropTypes.bool.isRequired,
  firstName: PropTypes.string,
};

NavigationContainer.contextTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

function mapStateToProps({ users, authedFiles }) {
  return {
    isAuthed: users.isAuthed,
    firstName: get(users, `${users.authedId}.info.firstName`, ''),
    profilePic:
      authedFiles.files[get(users, `${users.authedId}.info.profilePic`)],
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logoutAndUnauth,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationContainer);
