import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { EventDetail } from 'components';
import { updateWeather } from 'helpers/weather';
import { updateEventLocation, updateEventRSVPs } from 'redux/modules/events';
import withRSVP from './RSVP/withRSVP';

class EventDetailContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMap: false,
    };
  }
  componentDidUpdate() {
    const {
      weather,
      weatherTimestamp,
      eventId,
      seasonId,
      teamId,
      timestamp,
      lat,
      lng,
    } = this.props;
    var d = new Date();
    d.setHours(d.getHours() - 1);
    if (
      lat &&
      lng &&
      (!weather || !weatherTimestamp || weatherTimestamp < d.getTime())
    ) {
      updateWeather({
        lat,
        lng,
        eventId,
        seasonId,
        teamId,
        timestamp,
      });
    }
  }
  toggleShowMap = () => {
    this.setState(state => ({
      showMap: !state.showMap,
    }));
  };
  getStatus = () => {
    const { isShortPlayers, msgStatus } = this.props;

    if (msgStatus === 0) {
      return 'Invites pending';
    }

    if (isShortPlayers) {
      return 'Short players';
    }

    return 'Full team';
  };
  getInviteStatus = () => {
    const { totalRSVPs, msgStatus } = this.props;

    if (!msgStatus || msgStatus === 0) {
      return 'Invites pending';
    }

    if (totalRSVPs && totalRSVPs > 0) {
      return `${totalRSVPs} playing`;
    } else {
      return '0 playing';
    }
  };
  getInviteSubtitle = () => {
    const {
      teamGender,
      totalRSVPs,
      maleRSVPs,
      femaleRSVPs,
      msgStatus,
      startMoment,
    } = this.props;
    if (totalRSVPs && totalRSVPs > 0 && teamGender === 'coed') {
      const femalesPlaying =
        femaleRSVPs !== 1 ? `${femaleRSVPs} females` : `${femaleRSVPs} female`;
      const malesPlaying =
        maleRSVPs !== 1 ? `${maleRSVPs} males` : `${maleRSVPs} male`;
      return `${femalesPlaying} and ${malesPlaying} playing`;
    }

    if (msgStatus === 0 && startMoment) {
      const eventTime = moment(startMoment);
      eventTime.subtract(6, 'days');
      if (eventTime.isAfter()) {
        return `Game invites will be sent ${eventTime.format(
          'dddd, MMMM Do YYYY'
        )}`;
      } else {
        return `Game invites will be sent shortly.`;
      }
    }

    return null;
  };
  render() {
    if (!this.props.startMoment) {
      return null;
    }
    return (
      <EventDetail
        opponent={this.props.opponent}
        isHome={this.props.isHome}
        startMoment={this.props.startMoment}
        isUpcoming={this.props.isUpcoming}
        address={this.props.address}
        weather={this.props.weather}
        showMap={this.state.showMap}
        toggleShowMap={this.toggleShowMap}
        inviteStatus={this.getInviteStatus()}
        inviteSubtitle={this.getInviteSubtitle()}
        status={this.getStatus()}
        lat={this.props.lat}
        lng={this.props.lng}
        uniformColor={this.props.uniformColor}
        location={this.props.location}
        directions={this.props.directions}
        rsvpStatus={this.props.rsvpStatus}
        handleRSVP={this.props.handleRSVP}
        isManager={this.props.isManager}
        pathname={this.props.pathname}
      />
    );
  }
}

EventDetailContainer.propTypes = {
  eventId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  seasonId: PropTypes.string.isRequired,
  opponent: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
  startMoment: PropTypes.object.isRequired,
  isUpcoming: PropTypes.bool.isRequired,
  address: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  lat: PropTypes.number,
  lng: PropTypes.number,
  location: PropTypes.string,
  totalRSVPs: PropTypes.number,
  maleRSVPs: PropTypes.number,
  femaleRSVPs: PropTypes.number,
  teamGender: PropTypes.string.isRequired,
  msgStatus: PropTypes.number,
  isShortPlayers: PropTypes.bool,
  weather: PropTypes.string,
  updateEventLocation: PropTypes.func.isRequired,
  updateEventRSVPs: PropTypes.func.isRequired,
  isManager: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
};

function mapStateToProps(
  { events, teams, users: { authedId } },
  { eventId, seasonId, teamId, pathname }
) {
  const event = events[eventId] ? events[eventId] : {};
  const {
    isHome,
    minPlayer,
    minFemale,
    minMale,
    totalRSVPs,
    femaleRSVPs,
    maleRSVPs,
    timestamp,
  } = event;

  const team = teams[teamId] ? teams[teamId] : {};
  const { teamHomeColor, teamAwayColor } = team;
  let uniformColor = isHome ? teamHomeColor : teamAwayColor;
  let isShortPlayers;
  if (minPlayer) {
    isShortPlayers = totalRSVPs < minPlayer;
  }
  if (minFemale && minMale) {
    isShortPlayers = maleRSVPs < minMale || femaleRSVPs < minFemale;
  }

  let startMoment, isUpcoming;
  if (timestamp) {
    startMoment = moment(timestamp);
    const now = new Date();
    const future = new Date();
    future.setDate(future.getDate() + 5);
    isUpcoming = startMoment.isBetween(now, future);
  }

  return {
    ...event,
    pathname,
    uniformColor,
    isShortPlayers,
    startMoment,
    isUpcoming,
    isManager: authedId === team.uid,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEventLocation,
      updateEventRSVPs,
    },
    dispatch
  );
}

export default withRSVP(
  connect(mapStateToProps, mapDispatchToProps)(EventDetailContainer)
);
