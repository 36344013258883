import React from 'react';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as reducers from 'redux/modules';
import { MainContainer } from 'containers';

export const store = createStore(
  combineReducers(reducers),
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__({
          reducers,
          serialize: true,
          trace: true,
        })
      : f => f
  )
);

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#eea904',
    },
    secondary: {
      main: '#383838',
    },
    contrastThreshold: 3,
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiPaper: {
      rounded: {
        overflow: 'hidden',
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottomColor: '#eea904',
        },
        '&:before': {
          borderBottomColor: '#383838',
        },
        '&:hover:before': {
          borderBottomColor: '#383838',
        },
      },
    },
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: '#eea904',
        },
        '&$error': {
          color: '#f44336',
        },
      },
    },
    MuiSlider: {
      root: {
        height: 8,
        paddingTop: '38px !important',
        '&$disabled': {
          '& $thumb': {
            width: 24,
            height: 24,
            marginTop: -8,
            marginLeft: -12,
          },
        },
      },
      track: {
        height: 8,
        borderRadius: 4,
      },
      rail: {
        height: 8,
        borderRadius: 4,
      },
      valueLabel: {
        left: 'calc(-50% + 3px)',
        top: -22,
        '& *': {
          background: 'transparent',
          fontSize: 15,
        },
      },
      thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
      },
      active: {},
      disabled: {},
    },
    MuiTable: {
      root: {
        overflow: 'hidden',
      },
    },
    MuiTableRow: {
      root: {
        height: 53,
        '&$selected': {
          pointerEvents: 'none',
        },
        '& .MuiTableRow-root': {
          height: 36,
          '& .MuiButtonBase-root': {
            padding: 3,
          },
          '& h6': {
            fontSize: '0.875rem',
          },
          '& th .MuiButtonBase-root': {
            padding: 0,
          },
        },
        '& .MuiTypography-caption': {
          minWidth: 70,
        },
        '& .MuiTablePagination-caption': {
          minWidth: 70,
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: `0 16px`,
        '& h6': {
          fontWeight: 300,
          fontSize: '1rem',
          paddingLeft: 16,
        },
      },
      head: {
        backgroundColor: 'transparent !important',
      },
    },
    MuiToggleButton: {
      sizeSmall: {
        height: 33,
      },
    },
  },
});

class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MainContainer />
          </MuiPickersUtilsProvider>
        </Provider>
      </ThemeProvider>
    );
  }
}

export default App;
