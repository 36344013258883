import React from 'react';
import PropTypes from 'prop-types';
import { PhoneAuthContainer } from 'containers';
import { FacebookButton } from 'components/base';
import { orLine, orText, fbDisclaimer } from './styles.module.css';
import { contentWrapper } from 'sharedStyles/styles.module.css';

Authenticate.propTypes = {
  error: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onAuth: PropTypes.func.isRequired,
};

export default function Authenticate({ error, isFetching, onAuth }) {
  return (
    <div className={contentWrapper}>
      <h2>Login</h2>
      <div>
        <div>
          <FacebookButton onClick={onAuth} />
          <p className={fbDisclaimer}>
            We will never post or share anything without your permission.
          </p>
        </div>
        <div className={orLine}>
          <span className={orText}>OR</span> authenticate via SMS
        </div>
        <div>
          <PhoneAuthContainer />
        </div>
      </div>
      {error ? <p>{error}</p> : null}
    </div>
  );
}
