import { fetchAuthedFile } from 'helpers/firebaseStorage';

const FETCHING_FILE = 'FETCHING_FILE';
const FETCHING_FILE_SUCCESS = 'FETCHING_FILE_SUCCESS';
const FETCHING_FILE_FAILURE = 'FETCHING_FILE_FAILURE';

function fetchingFile() {
  return {
    type: FETCHING_FILE,
  };
}

function fetchingFileSuccess(fileRef, fileURL) {
  return {
    type: FETCHING_FILE_SUCCESS,
    fileURL,
    fileRef,
  };
}

function fetchingFileFailure(error) {
  return {
    type: FETCHING_FILE_FAILURE,
    error,
  };
}

export function fetchFile(fileRef) {
  return function(dispatch) {
    dispatch(fetchingFile());
    return fetchAuthedFile(fileRef)
      .then(fileURL => dispatch(fetchingFileSuccess(fileRef, fileURL)))
      .catch(err => dispatch(fetchingFileFailure(err)));
  };
}

function files(state = {}, action) {
  switch (action.type) {
    case FETCHING_FILE_SUCCESS:
      return {
        ...state,
        [action.fileRef]: action.fileURL,
      };
    default:
      return state;
  }
}

const initialState = {
  fetchingFile: false,
  files: {},
};

export default function authedFiles(state = initialState, action) {
  switch (action.type) {
    case FETCHING_FILE:
      return {
        ...state,
        fetchingFile: true,
      };
    case FETCHING_FILE_SUCCESS:
      return {
        ...state,
        fetchingFile: false,
        files: files(state.files, action),
      };
    case FETCHING_FILE_FAILURE:
      return {
        ...state,
        fetchingFile: false,
      };
    default:
      return state;
  }
}
