import React from 'react';
import PropTypes from 'prop-types';
import TeamCard from './TeamCard';
import CreateTeamCard from './CreateTeamCard';
import styles from './styles.module.css';

export default function TeamNav({ teams, isIOS }) {
  const teamLinks = teams.map(function(team, index) {
    return (
      <TeamCard
        teamId={team[0]}
        teamName={team[1]}
        sport={team[3]}
        key={index}
      />
    );
  });
  return (
    <div className={styles.teamsWrapper}>
      <div
        className={
          isIOS
            ? `${styles.teamsContainer} ${styles.teamsContainerIOS}`
            : styles.teamsContainer
        }
      >
        {teamLinks}
        <CreateTeamCard />
      </div>
    </div>
  );
}

TeamNav.propTypes = {
  teams: PropTypes.array,
  isIOS: PropTypes.bool.isRequired,
};
