import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import MUITextField from '@material-ui/core/TextField';

const styles = theme => {
  return {
    root: {
      minHeight: 68,
      marginBottom: theme.spacing(1),
      minWidth: 220,
    },
    fullWidth: {
      width: '100%',
    },
    inputRoot: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };
};

const TextField = ({
  classes,
  input,
  helperText,
  InputProps,
  InputLabelProps,
  fullWidth,
  value,
  meta: { touched, error, initial, visited },
  ...restOfProps
}) => {
  const isError = touched && error && error.length > 0;
  return (
    <MUITextField
      className={cx(classes.root, {
        [classes.fullWidth]: fullWidth,
      })}
      error={isError}
      helperText={isError ? error : helperText}
      InputProps={{
        classes: {
          underline: classes.inputUnderline,
          input: classes.inputRoot,
        },
        ...InputProps,
      }}
      InputLabelProps={{
        shrink: !visited && initial ? true : undefined,
        ...InputLabelProps,
      }}
      value={initial || value}
      {...input}
      {...restOfProps}
    />
  );
};

TextField.propTypes = {
  classes: PropTypes.object.isRequired,
};

const textField = withStyles(styles)(TextField);

textField.displayName = 'TextField';

textField.defaultProps = {
  meta: {},
  disabled: false,
  fullWidth: false,
};

textField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export default textField;
