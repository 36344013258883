import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isDirty } from 'redux-form';
import { format } from 'date-fns';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import LinearProgress from '@material-ui/core/LinearProgress';
import { EventCreate, EventEdit } from 'components';
import { savingEvent } from 'redux/modules/events';
import { validateLocation } from 'redux/modules/locations';
import {
  updateSelectedSeason,
  removeEventsFromSeason,
} from 'redux/modules/seasons';
import sharedStyles from 'sharedStyles/styles.module.css';
import { colorTextMap } from 'config/constants';

class EventFormContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      /*
       * Store event data in component state
       * so we don't lose reference to it when
       * updating
       */
      eventData: props.eventData,
    };

    this.handleUpdateEvent = this.handleUpdateEvent.bind(this);
    this.getUniformColor = this.getUniformColor.bind(this);
    this.handleCancelUpdate = this.handleCancelUpdate.bind(this);
  }
  componentDidUpdate(prevProps) {
    const { teamId, seasonId, selectedSeason, eventData } = this.props;

    // Set event data to state if not present on mount
    if (!prevProps.eventData && eventData) {
      this.setState({ eventData });
    }

    /*
     * If the selected season is not the event's season
     * then let's update selected season to the event's season
     * so we fetch events in the team container
     */
    if (teamId && seasonId && selectedSeason && seasonId !== selectedSeason) {
      this.props.updateSelectedSeason(seasonId, teamId);
    }
  }
  getUniformColor(isHome) {
    let uniformColor = '';
    if (this.props.teamHomeColor && isHome === 'home') {
      uniformColor = colorTextMap[this.props.teamHomeColor];
    } else if (this.props.teamAwayColor && isHome === 'away') {
      uniformColor = colorTextMap[this.props.teamAwayColor];
    }
    return uniformColor;
  }
  handleUpdateEvent(data) {
    const {
      eventId,
      teamId,
      seasonStr,
      teamName,
      teamNameStr,
      teamGender,
      minPlayer,
      minFemale,
      minMale,
      maxPlayer,
      maxMale,
      maxFemale,
      isEdit,
      isDirty,
      pathname,
      history,
      removeEventsFromSeason,
      savingEvent,
      redirect,
      validateLocation,
      selectedSeason,
    } = this.props;

    /*
     * If editing game and no changes made,
     * navigate to event page
     */
    if (isEdit && !isDirty) {
      return history.push(pathname.replace('/edit', ''));
    }

    /*
     * If editing game, let's remove event lookups
     * on season data. It will repopulate on update
     * and we can reference the updated data after.
     */
    isEdit && removeEventsFromSeason(teamId, seasonStr);

    return validateLocation(data.locationId, data.dateTime / 1000)
      .then(location => {
        var dateObj = new Date(data.dateTime);
        let min = format(dateObj, 'mm');
        min = min === '00' ? '' : `:${min}`;
        return savingEvent(
          Object.assign({}, data, {
            isHome: data.isHome === 'home' ? true : false,
            eventId,
            teamId,
            teamName,
            teamGender,
            minPlayer,
            minFemale,
            minMale,
            maxPlayer,
            maxMale,
            maxFemale,
            uniformColor: this.getUniformColor(data.isHome),
            msgStatus: 0,
            msgTimestamp: 0,
            msgRSVP: 0,
            msgReserve: 0,
            msgMarket: 0,
            shortTime: format(dateObj, `EEEE 'at' h'${min}' a '('M'/'d')'`),
            startTime: format(dateObj, 'EEEE, MMMM Do, h:mm a'),
            timestamp: data.dateTime,
            locationId: location.uid,
            address: location.address,
            timeZoneId: location.timeZoneId,
            location: location.name,
            lat: location.lat,
            lng: location.lng,
          }),
          teamId,
          selectedSeason
        );
      })
      .then(({ seasonId, eventId }) => {
        // If url has rd param, redirect to that path
        if (redirect) {
          return history.push(redirect);
        }
        /*
         * If editing event, return to event page.
         * Otherwise, redirect to schedule page.
         */
        const route = isEdit
          ? `/${teamNameStr}/rd/${seasonId}/${eventId}`
          : `/${teamNameStr}/schedule`;
        history.push(route);
      });
  }
  handleCancelUpdate() {
    const { pathname, redirect, history } = this.props;
    // If url has rd param, redirect to that path
    if (redirect) {
      return history.push(redirect);
    }
    // Return to event detail page
    return history.push(pathname.replace('/edit', ''));
  }
  render() {
    if (this.props.isEdit) {
      if (this.state.eventData) {
        const {
          seasonId,
          opponent,
          isHome,
          timestamp,
          address,
          location,
          locationId,
          directions,
        } = this.state.eventData;
        return (
          <div className={`${sharedStyles.contentWrapper}`}>
            <EventEdit
              onUpdateEvent={this.handleUpdateEvent}
              onCancelUpdate={this.handleCancelUpdate}
              seasons={this.props.seasons}
              initialValues={{
                seasonId,
                opponent,
                oldSeasonId: seasonId,
                isHome: isHome ? 'home' : 'away',
                dateTime: timestamp,
                locationId,
                location: location ? `${location}, ${address}` : address,
                directions,
              }}
              teamName={this.props.teamName}
              teamHomeColor={this.props.teamHomeColor}
              teamAwayColor={this.props.teamAwayColor}
              isEdit
            />
          </div>
        );
      } else {
        return (
          <div className={`${sharedStyles.contentWrapper}`}>
            <LinearProgress variant="indeterminate" style={{ marginTop: 20 }} />
          </div>
        );
      }
    } else {
      // if we don't have a season id, let's wait so the form renders correctly
      if (!this.props.seasonId) return <div />;
      return (
        <div className={`${sharedStyles.contentWrapper}`}>
          <EventCreate
            onUpdateEvent={this.handleUpdateEvent}
            seasons={this.props.seasons}
            seasonId={this.props.seasonId}
            teamName={this.props.teamName}
            teamHomeColor={this.props.teamHomeColor}
            teamAwayColor={this.props.teamAwayColor}
          />
        </div>
      );
    }
  }
}

EventFormContainer.propTypes = {
  teamId: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  teamNameStr: PropTypes.string.isRequired,
  seasons: PropTypes.array.isRequired,
  seasonStr: PropTypes.string.isRequired,
  seasonId: PropTypes.string.isRequired,
  savingEvent: PropTypes.func.isRequired,
  minPlayer: PropTypes.number,
  minFemale: PropTypes.number,
  minMale: PropTypes.number,
  teamHomeColor: PropTypes.string,
  teamAwayColor: PropTypes.string,
  validateLocation: PropTypes.func.isRequired,
  eventId: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  eventData: PropTypes.object,
  updateSelectedSeason: PropTypes.func.isRequired,
  removeEventsFromSeason: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  redirect: PropTypes.string,
};

function mapStateToProps(
  { seasons, events, teams, form },
  { teamId, gender, teamNameStr, location: { pathname, search } }
) {
  const redirect = new URLSearchParams(search).get('rd');
  const paths = pathname.split('/');
  const seasonStr = paths[2];
  const teamSeasons = get(seasons, `${teamId}.seasons`, []);
  let seasonsList = [];
  teamSeasons.forEach(function(teamSeason, index) {
    seasonsList.push({
      value: teamSeason.seasonId,
      label: teamSeason.name,
    });
  });
  const team = teams[teamId];
  const teamName = team.name;

  const seasonId = get(seasons, `${teamId}.lookups.${seasonStr}.seasonId`, '');
  const selectedSeason = get(seasons, `${teamId}.selectedSeason`);
  const eventStr = paths[3];
  const eventId = get(
    seasons,
    `${teamId}.lookups.${seasonStr}.events.${eventStr}.eventId`,
    ''
  );

  const isEdit = !!paths[4];

  return {
    seasons: seasonsList,
    seasonId,
    selectedSeason,
    seasonStr,
    eventId,
    isEdit,
    pathname,
    eventData: events[eventId],
    teamId,
    teamName,
    teamNameStr,
    redirect,
    teamGender: gender,
    teamHomeColor: team.teamHomeColor != null ? team.teamHomeColor : null,
    teamAwayColor: team.teamAwayColor != null ? team.teamAwayColor : null,
    minPlayer: team.minPlayer ? parseInt(team.minPlayer) : 0,
    minMale: team.minMale ? parseInt(team.minMale) : 0,
    minFemale: team.minFemale ? parseInt(team.minFemale) : 0,
    maxPlayer: team.maxPlayer ? parseInt(team.maxPlayer) : 0,
    maxMale: team.maxMale ? parseInt(team.maxMale) : 0,
    maxFemale: team.maxFemale ? parseInt(team.maxFemale) : 0,
    isDirty: isDirty(isEdit ? 'eventEdit' : 'eventCreate')({ form }),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      savingEvent,
      validateLocation,
      updateSelectedSeason,
      removeEventsFromSeason,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EventFormContainer);
