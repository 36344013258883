import axios from 'axios';
const lambdaURL = 'https://us-central1-coach-cheetah-b6dcf.cloudfunctions.net';

export const updateWeather = ({
  lat,
  lng,
  timestamp,
  eventId,
  seasonId,
  teamId,
}) => {
  return axios.post(`${lambdaURL}/getWeather`, {
    lat,
    lng,
    timestamp,
    eventId,
    seasonId,
    teamId,
  });
};
