import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MUIIconButton from '@material-ui/core/IconButton';

const IconButton = ({ Icon, to, color, ...restOfProps }) => (
  <MUIIconButton
    color={color}
    component={to ? Link : undefined}
    to={to}
    {...restOfProps}
  >
    <Icon />
  </MUIIconButton>
);

IconButton.defaultProps = {
  color: 'secondary',
};

IconButton.propTypes = {
  Icon: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'secondary']),
};

export default IconButton;
