import { firebaseStorage } from 'config/constants';

export function saveUserPic(userId, userPicData, format = 'jpeg') {
  var userPicRef = firebaseStorage.child(`profilePictures/${userId}.${format}`);
  return userPicRef
    .putString(userPicData, 'data_url')
    .then(snapshot => snapshot.metadata.fullPath);
}

export function fetchAuthedFile(refString) {
  var ref = firebaseStorage.child(refString);
  return ref.getDownloadURL();
}

export function convertImgURLToDataURL(imgURL) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', imgURL);
    xhr.responseType = 'blob';
    xhr.send();
  });
}
