import React from 'react';
import { PrimaryButton } from 'components/base';
import styles from './styles.module.css';

export default function CreateTeamCard() {
  return (
    <PrimaryButton
      variant="contained"
      size="large"
      to="/createTeam"
      className={styles.teamLink}
    >
      Create a Team
    </PrimaryButton>
  );
}
