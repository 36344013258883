import { reduxForm } from 'redux-form';
import TeamForm from '../Form/TeamForm';
import validate from 'helpers/formValidate/team';

// TODO: Do we need React?

let TeamCreate = reduxForm({
  form: 'teamCreate',
  validate,
})(TeamForm);

export default TeamCreate;
