import firebase from 'firebase/app';
// Required for side-effects
require('firebase/firestore');
require('firebase/auth');
require('firebase/database');
require('firebase/storage');

const config = {
  apiKey: 'AIzaSyAm2vObQyxyo_GYX6RVsDLEE5cY9wqpecU',
  authDomain: 'coach-cheetah-b6dcf.firebaseapp.com',
  databaseURL: 'https://coach-cheetah-b6dcf.firebaseio.com',
  projectId: 'coach-cheetah-b6dcf',
  storageBucket: 'coach-cheetah-b6dcf.appspot.com',
  messagingSenderId: '599671206565',
  timestampsInSnapshots: true,
};

firebase.initializeApp(config);

export const database = firebase.database();
export const ref = firebase.database().ref();
export const firebaseAuth = firebase.auth;
export const firebaseStorage = firebase.storage().ref();

const firestore = firebase.firestore();
export const db = firestore;
export const FieldValue = firebase.firestore.FieldValue;

export const sports = [
  'Basketball',
  'Soccer',
  'Flag Football',
  'Baseball',
  'Softball',
  'Kickball',
  'Volleyball',
  'Dodgeball',
  'Bowling',
];
export const teamColors = [
  {
    value: '#ffffff',
    label: 'white',
    id: 'white',
  },
  {
    value: '#383838',
    label: 'black',
    id: 'black',
  },
  {
    value: '#790822',
    label: 'maroon',
    id: 'maroon',
  },
  {
    value: '#ff1e1e',
    label: 'red',
    id: 'red',
  },
  {
    value: '#ff8b00',
    label: 'orange',
    id: 'orange,',
  },
  {
    value: '#ffdd00',
    label: 'yellow',
    id: 'yellow',
  },
  {
    value: '#245900',
    label: 'forest green',
    id: 'forest',
  },
  {
    value: '#3e9601',
    label: 'kelly green',
    id: 'kelly',
  },
  {
    value: '#53bcef',
    label: 'sky blue',
    id: 'sky',
  },
  {
    value: '#0b5abf',
    label: 'royal blue',
    id: 'royal',
  },
  {
    value: '#0c3970',
    label: 'navy blue',
    id: 'navy',
  },
  {
    value: '#611296',
    label: 'purple',
    id: 'purple',
  },
];
export const colorTextMap = {
  '#ffffff': 'white',
  '#383838': 'black',
  '#790822': 'maroon',
  '#ff1e1e': 'red',
  '#ff8b00': 'orange',
  '#ffdd00': 'yellow',
  '#245900': 'green',
  '#3e9601': 'green',
  '#53bcef': 'blue',
  '#0b5abf': 'blue',
  '#0c3970': 'navy',
  '#611296': 'purple',
};
