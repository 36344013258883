import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Slider from '@material-ui/core/Slider';
import cx from 'classnames';

const styles = theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    minWidth: 260,
    paddingBottom: theme.spacing(2),
  },
  inputLabel: {
    '&$cssFocused': {
      color: theme.palette.primary.main,
    },
    '&$cssError': {
      color: theme.palette.error.main,
    },
  },
  activeLabel: {
    color: theme.palette.primary.main,
    '&$cssFocused': {
      color: theme.palette.primary.main,
    },
    '&$cssError': {
      color: theme.palette.error.main,
    },
  },
  cssFocused: {},
  cssError: {},
});

class RangeField extends React.Component {
  constructor(props) {
    super(props);
    const initial = get(
      props,
      'meta.initial',
      get(props, 'initialValue', [5, 8]).join(',')
    );

    this.props.input.onChange(initial);
  }

  handleChange = (event, value) => {
    this.props.input.onChange(`${value[0]},${value[1]}`);
    this.props.input.onFocus();
  };

  handleChangeCommitted = () => {
    this.props.input.onBlur();
  };

  render() {
    const {
      classes,
      label,
      input,
      name,
      disabled,
      className,
      required,
      helperText,
      getAriaLabel,
      getAriaValueText,
      sliderColor,
      min,
      max,
      meta: { error, touched, active },
      initialValue,
      defaultValue,
    } = this.props;

    const isError = touched && error && error.length > 0;

    const value =
      input.value && input.value.split(',').map(num => parseInt(num));

    return (
      <FormControl
        component="fieldset"
        disabled={disabled}
        className={cx(classes.root, className)}
      >
        <FormLabel
          component="legend"
          error={isError}
          classes={{
            root: active ? classes.activeLabel : classes.inputLabel,
            focused: classes.cssFocused,
            error: classes.cssError,
          }}
          required={required}
          id={name}
        >
          {label}
        </FormLabel>
        <Slider
          value={value || initialValue}
          defaultValue={defaultValue}
          valueLabelDisplay="on"
          getAriaLabel={getAriaLabel}
          getAriaValueText={getAriaValueText}
          color={sliderColor}
          min={min}
          max={max}
          aria-labelledby={name}
          disabled={disabled}
          onChange={this.handleChange}
          onChangeCommitted={this.handleChangeCommitted}
          name={name}
        />
        {helperText || isError ? (
          <FormHelperText error={isError} className={classes.helperText}>
            {isError ? error : helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    );
  }
}

RangeField.propTypes = {
  classes: PropTypes.object.isRequired,
};

const rangeField = withStyles(styles)(RangeField);

rangeField.defaultProps = {
  meta: {},
  input: {
    onChange: () => true,
    onFocus: () => true,
    onBlur: () => true,
  },
  sliderColor: 'secondary',
  min: 1,
  max: 25,
};

rangeField.propTypes = {
  /** Label shown above radio inputs */
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  sliderColor: PropTypes.oneOf(['primary', 'secondary']),
  disabled: PropTypes.bool,
  initialValue: PropTypes.array,
  defaultValue: PropTypes.array,
  getAriaValueText: PropTypes.func.isRequired,
  getAriaLabel: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  input: PropTypes.object,
  meta: PropTypes.object,
  min: PropTypes.number,
  max: PropTypes.number,
};

rangeField.displayName = 'RangeField';
export default rangeField;
