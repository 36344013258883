import React from 'react';
import { FeedContainer } from 'containers';

class FeedRoute extends React.Component {
  render() {
    return <FeedContainer />;
  }
}

export default FeedRoute;
