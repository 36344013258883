import { getPlaceDetails } from '../../helpers/googleMaps';
import { getLocations } from '../../helpers/locations';

const REVERSE_GEOCODING_LOCATION = 'REVERSE_GEOCODING_LOCATION';
const REVERSE_GEOCODING_LOCATION_FAILURE = 'REVERSE_GEOCODING_LOCATION_FAILURE';
const REVERSE_GEOCODING_LOCATION_SUCCESS = 'REVERSE_GEOCODING_LOCATION_SUCCESS';

const ADDING_LOCATIONS = 'ADDING_LOCATIONS';
const ADDING_LOCATIONS_FAILURE = 'ADDING_LOCATIONS_FAILURE';
const ADDING_LOCATIONS_SUCCESS = 'ADDING_LOCATIONS_SUCCESS';

const ADDING_GOOGLE_PLACE_SUCCESS = 'ADDING_GOOGLE_PLACE_SUCCESS';

function reverseGecodingLocation() {
  return {
    type: REVERSE_GEOCODING_LOCATION,
  };
}

function reverseGeocodingLocationFailure(error) {
  return {
    type: REVERSE_GEOCODING_LOCATION_FAILURE,
    error: 'Unable to reverse geocode location.',
  };
}

function reverseGeocodingLocationSuccess() {
  return {
    type: REVERSE_GEOCODING_LOCATION_SUCCESS,
  };
}

export function validateLocation(id, startTime) {
  return function(dispatch) {
    // check if location exists in local cache

    // if not, reverse geocode location and save data
    dispatch(reverseGecodingLocation());
    return getPlaceDetails(id, startTime)
      .then(res => {
        dispatch(reverseGeocodingLocationSuccess(res));
        return res.data;
      })
      .catch(error => {
        dispatch(reverseGeocodingLocationFailure(error));
      });
  };
}

function addingLocations() {
  return {
    type: ADDING_LOCATIONS,
  };
}

function addingLocationsFailure(error) {
  return {
    type: ADDING_LOCATIONS_FAILURE,
  };
}

function addingLocationsSuccess(locations) {
  return {
    type: ADDING_LOCATIONS_SUCCESS,
    locations: locations,
  };
}

export function addLocations(keys) {
  return function(dispatch) {
    dispatch(addingLocations());
    return getLocations(keys)
      .then(({ data }) => dispatch(addingLocationsSuccess(data)))
      .catch(error => dispatch(addingLocationsFailure(error)));
  };
}

function addingGooglePlaceSuccess(places, searchText) {
  return {
    type: ADDING_GOOGLE_PLACE_SUCCESS,
    places,
    searchText,
  };
}

export function addGooglePlace(places, searchText) {
  return function(dispatch) {
    dispatch(addingGooglePlaceSuccess(places, searchText));
  };
}

const initialState = {
  isPrefetched: false,
  isReverseGeocoding: false,
  isAddingLocation: false,
  locations: [],
  keys: [],
  googleKeys: [],
  googleLocations: [],
  searchTerms: [],
};

export default function locations(state = initialState, action) {
  switch (action.type) {
    case REVERSE_GEOCODING_LOCATION:
      return {
        ...state,
        isReverseGeocoding: true,
      };
    case REVERSE_GEOCODING_LOCATION_FAILURE:
      return {
        ...state,
        isReverseGeocoding: false,
      };
    case REVERSE_GEOCODING_LOCATION_SUCCESS:
      return {
        ...state,
        isReverseGeocoding: false,
      };
    case ADDING_LOCATIONS:
      return {
        ...state,
        isAddingLocation: true,
      };
    case ADDING_LOCATIONS_FAILURE:
      return {
        ...state,
        isAddingLocation: false,
      };
    case ADDING_LOCATIONS_SUCCESS:
      return {
        ...state,
        isPrefetched: true,
        isAddingLocation: false,
        locations: [].concat(
          state.locations,
          action.locations.map(({ uid, address, name, lat, lng }) => ({
            id: uid,
            description: address,
            name,
            lat,
            lng,
          }))
        ),
        keys: [].concat(
          state.keys,
          action.locations.map(({ uid }) => uid)
        ),
      };
    case ADDING_GOOGLE_PLACE_SUCCESS:
      if (state.searchTerms.includes(action.searchText) || !action.places) {
        return {
          ...state,
        };
      } else {
        let places = [];
        let keys = [];
        for (let i = 0; i < action.places.length; i++) {
          if (
            !state.googleKeys.includes(action.places[i].id) &&
            !state[action.places[i].id]
          ) {
            keys.push(action.places[i].id);
            places.push(action.places[i]);
          }
        }
        return {
          ...state,
          googleKeys: [].concat(state.googleKeys, keys),
          googleLocations: [].concat(state.googleLocations, places),
          searchTerms: [].concat(state.searchTerms, [action.searchText]),
        };
      }

    default:
      return state;
  }
}
