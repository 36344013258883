import React from 'react';
import { Field } from 'redux-form';
import { sports, teamColors } from 'config/constants';
import { TextField, RadioColorButtons, AutoComplete } from 'components/base';

export default function InfoField({ submitting, isEdit }) {
  return (
    <React.Fragment>
      <Field
        name="name"
        component={TextField}
        label="Team Name"
        helperText={isEdit ? 'Edit your team name' : 'Give your team a name'}
        disabled={submitting}
        fullWidth
      />
      <Field
        name="sport"
        component={AutoComplete}
        label="Sport"
        placeholder="Enter your activity"
        disabled={submitting}
        suggestions={sports.map(sport => ({ value: sport, label: sport }))}
        minChar={0}
      />
      <RadioColorButtons
        label="Home Color"
        name="teamHomeColor"
        options={teamColors}
        disabled={submitting}
      />
      <RadioColorButtons
        label="Away Color"
        name="teamAwayColor"
        options={teamColors}
        disabled={submitting}
      />
    </React.Fragment>
  );
}
