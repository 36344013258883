import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { TeamInfo } from 'components';
import { updateTeam } from 'redux/modules/teams';
import { addAlert } from 'redux/modules/alerts';

class TeamInfoContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleTeamUpdate = this.handleTeamUpdate.bind(this);
  }
  handleTeamUpdate(values) {
    return this.props
      .updateTeam(values, this.props.teamId, this.props.uid)
      .then(() => this.props.addAlert(`${values.name} was updated.`));
  }
  render() {
    const {
      name,
      sport,
      gender,
      selectedGender,
      teamHomeColor,
      teamAwayColor,
      femalePlayers,
      malePlayers,
      players,
    } = this.props;
    return (
      <TeamInfo
        initialValues={{
          name,
          sport,
          gender,
          teamHomeColor,
          teamAwayColor,
          femalePlayers,
          malePlayers,
          players,
        }}
        isCoed={selectedGender === 'coed'}
        onTeamUpdate={this.handleTeamUpdate}
      />
    );
  }
}

TeamInfoContainer.propTypes = {
  teamId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sport: PropTypes.string.isRequired,
  gender: PropTypes.string.isRequired,
  teamHomeColor: PropTypes.string,
  teamAwayColor: PropTypes.string,
  updateTeam: PropTypes.func.isRequired,
  addAlert: PropTypes.func.isRequired,
  uid: PropTypes.string.isRequired,
};

function mapStateToProps({ teams, form }, { teamId, teamName }) {
  const {
    sport,
    gender,
    teamHomeColor,
    teamAwayColor,
    minFemale,
    maxFemale,
    minMale,
    maxMale,
    minPlayer,
    maxPlayer,
    uid,
  } = teams[teamId] || {};
  return {
    teamId,
    name: teamName,
    sport,
    gender,
    selectedGender: get(form, 'teamInfo.values.gender', gender),
    teamHomeColor,
    teamAwayColor,
    femalePlayers: `${minFemale || 5},${maxFemale || 8}`,
    malePlayers: `${minMale || 5},${maxMale || 8}`,
    players: `${minPlayer || 8},${maxPlayer || 13}`,
    uid,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateTeam,
      addAlert,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamInfoContainer);
