import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual, filter, find } from 'lodash';
import { TeamRoster } from 'components';
import { deletePlayer, updatePlayerStatus } from 'redux/modules/rosters';
import { addAlert } from 'redux/modules/alerts';
import { formatPhone } from 'helpers/utils';
import { inviteUser } from 'helpers/invite';

const statusMap = {
  active: 1,
  reserve: 2,
  invite: 0,
};

const statusNameMap = {
  1: 'active',
  2: 'reserve',
  0: 'invite',
};

const filterData = (data, statusFilters, genderFilters) =>
  data.length > 10
    ? filter(
        data,
        o =>
          statusFilters.includes(o.status) && genderFilters.includes(o.gender)
      )
    : data;

class TeamRosterContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    const statusFilters = props.isXS ? [0, 1, 2] : [1];
    const genderFilters = [0, 1];
    this.state = {
      data: props.roster,
      filteredData: filterData(props.roster, statusFilters, genderFilters),
      statusFilters,
      genderFilters,
      isFetching: props.isFetching,
      savingData: false,
    };

    this.onRowAdd = this.onRowAdd.bind(this);
    this.onRowUpdate = this.onRowUpdate.bind(this);
    this.onRowDelete = this.onRowDelete.bind(this);
    this.onStatusFilter = this.onStatusFilter.bind(this);
    this.onGenderFilter = this.onGenderFilter.bind(this);
  }
  componentDidUpdate(prevProps) {
    const current = this.state.data.map(({ tableData, ...data }) => data);
    const next = this.props.roster.map(({ tableData, ...data }) => data);
    if (
      (prevProps.isFetching && !this.props.isFetching) ||
      !isEqual(current, next)
    ) {
      const { statusFilters, genderFilters } = this.state;
      this.setState({
        isFetching: false,
        data: this.props.roster,
        filteredData: filterData(
          this.props.roster,
          statusFilters,
          genderFilters
        ),
      });
    }
  }
  onRowAdd({ name, phone, status, gender }) {
    const { addAlert, isCoed, roster, teamData } = this.props;

    if (!name) {
      addAlert('Enter a name for the new player.');
      return Promise.reject();
    }

    if (isCoed && !gender) {
      addAlert('Select a gender for the player.');
      return Promise.reject();
    }

    if (!phone) {
      addAlert('Enter a phone number for the new player.');
      return Promise.reject();
    }

    const phoneLength = phone.match(/\d/g).length;
    if (phoneLength !== 10 && phoneLength !== 11) {
      addAlert('Enter a 10 or 11 digit phone number for the new player.');
      return Promise.reject();
    }

    if (phoneLength === 11 && phone[0] !== '1') {
      addAlert('We currently only support US numbers.');
      return Promise.reject();
    }

    const formattedPhone = formatPhone(phone);
    const onRoster = find(roster, o => o.phone === formattedPhone);
    if (onRoster) {
      addAlert('That phone number belongs to a player on the roster.');
      return Promise.reject();
    }

    if (!status) {
      addAlert('Enter a status for the new player.');
      return Promise.reject();
    }

    this.setState({ savingData: true });
    return inviteUser({
      phone: formattedPhone,
      firstName: name.substr(0, name.indexOf(' ')),
      lastName: name.substr(name.indexOf(' ') + 1),
      gender: isCoed ? gender : teamData.gender,
      invitedBy: this.props.invitedBy,
      invitedById: this.props.uid,
      teamData: {
        ...teamData,
        status: status === '1' ? 'active' : 'reserve',
      },
    }).then(() => {
      addAlert(`Invite sent to ${name}`);
      this.setState({ savingData: false });
    });
  }
  onRowUpdate({ status, uid, ...rest }, { status: oldStatus }) {
    const { addAlert, teamId, updatePlayerStatus } = this.props;
    if (status === oldStatus) {
      addAlert('No updates were made.');
      return Promise.reject();
    }
    return updatePlayerStatus(teamId, uid, statusNameMap[status]).then(() =>
      addAlert('Player status updated.')
    );
  }
  onRowDelete({ uid }) {
    const { teamId, deletePlayer, addAlert } = this.props;
    this.setState({ savingData: this.state.filteredData });
    return deletePlayer(teamId, uid).then(() => {
      addAlert('Player deleted.');
      this.setState({ savingData: false });
    });
  }
  onGenderFilter(ev, genderFilters) {
    const { data, statusFilters } = this.state;
    this.setState({
      genderFilters,
      filteredData: filterData(data, statusFilters, genderFilters),
    });
  }
  onStatusFilter(ev, statusFilters) {
    const { data, genderFilters } = this.state;
    this.setState({
      statusFilters,
      filteredData: filterData(data, statusFilters, genderFilters),
    });
  }
  render() {
    const { name, managerId, isCoed, isSmall, isXS } = this.props;
    const {
      statusFilters,
      genderFilters,
      isFetching,
      filteredData,
      savingData,
      data,
    } = this.state;
    if (isFetching) return null;
    return (
      <TeamRoster
        data={savingData ? savingData : filteredData}
        name={name}
        isCoed={isCoed}
        editable={{
          isEditable: data => data.status !== 0,
          isDeletable: data => data.uid !== managerId,
          onRowUpdate: this.onRowUpdate,
          onRowAdd: this.onRowAdd,
          onRowDelete: this.onRowDelete,
        }}
        filterProps={{
          onStatusFilter: this.onStatusFilter,
          onGenderFilter: this.onGenderFilter,
          statusFilters,
          genderFilters,
          isCoed,
        }}
        isFiltered={data.length > 10}
        inlineCaption={!isSmall}
        isSmall={isSmall}
        isXS={isXS}
      />
    );
  }
}

TeamRosterContainer.propTypes = {
  teamId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isCoed: PropTypes.bool.isRequired,
  sport: PropTypes.string.isRequired,
  teamHomeColor: PropTypes.string,
  teamAwayColor: PropTypes.string,
  roster: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      status: PropTypes.number.isRequired,
      avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      phone: PropTypes.string.isRequired,
      gender: PropTypes.number.isRequired,
      uid: PropTypes.string.isRequired,
    })
  ),
  isFetching: PropTypes.bool.isRequired,
  deletePlayer: PropTypes.func.isRequired,
  addAlert: PropTypes.func.isRequired,
  isSmall: PropTypes.bool.isRequired,
  isXS: PropTypes.bool.isRequired,
};

function mapStateToProps(
  { teams, rosters, users, viewport: { width } },
  { teamId, teamName }
) {
  const team = teams[teamId] || {};
  const teamRosters = rosters[teamId];
  const roster = teamRosters
    ? [
        ...teamRosters.active,
        ...teamRosters.reserve,
        ...teamRosters.invites,
      ].map(player => ({
        name: `${player.firstName} ${player.lastName}`,
        status: statusMap[player.type],
        avatar: player.profilePic,
        phone: player.phone,
        gender: player.gender === 'male' ? 0 : 1,
        uid: player.uid,
      }))
    : [];
  const lastInitial = users[users.authedId].info.lastName
    .substring(0, 1)
    .toUpperCase();
  return {
    teamId,
    roster,
    managerId: teams[teamId].uid,
    isFetching: !teamRosters,
    name: teamName,
    teamData: {
      name: teamName,
      gender: team.gender,
      sport: team.sport,
      teamId,
    },
    isCoed: team.gender === 'coed',
    sport: team.sport,
    teamHomeColor: team.teamHomeColor,
    teamAwayColor: team.teamAwayColor,
    uid: users.authedId,
    invitedBy: `${users[users.authedId].info.firstName} ${lastInitial}.`,
    isSmall: width <= 790,
    isXS: width < 667,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deletePlayer,
      updatePlayerStatus,
      addAlert,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamRosterContainer);
