import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { normalizePhone } from '../../../helpers/formNormalize';
import { PrimaryButton, TextField } from 'components/base';

const validate = values => {
  const errors = {};
  if (!values.phone) {
    errors.phone = 'Required';
  }
  if (values.phone && values.phone.toString().length !== 12) {
    errors.phone = 'Must be a valid phone number';
  }
  return errors;
};

function SendCode({
  handleSubmit,
  submitFailed,
  submitting,
  valid,
  handleSendCode,
}) {
  return (
    <form onSubmit={handleSubmit(handleSendCode)}>
      <Field
        name="phone"
        component={TextField}
        label="Phone"
        disabled={submitting}
        normalize={normalizePhone}
        type={'tel'}
      />
      <br />
      <PrimaryButton
        style={{ marginRight: 20 }}
        type="submit"
        disabled={submitting || !valid}
      >
        Submit
      </PrimaryButton>
      {submitting && 'Sending verification code...'}
      {submitFailed &&
        '<p>There was an error sending your request. Please refresh and try again.</p>'}
    </form>
  );
}

const SendCodeRedux = reduxForm({
  form: 'phoneAuth',
  destroyOnUnmount: false,
  validate,
})(SendCode);

export default SendCodeRedux;
