import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';

// TODO: Do we need PropTypes?

const style = theme => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    padding: '50px 0 25px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  footer: {
    maxWidth: 1200,
    padding: '0 20px',
    margin: '0 auto',
  },
  footerFlex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  text: {
    color: theme.palette.common.white,
    fontWeight: 300,
  },
  social: {
    fontSize: 20,
  },
  copy: {
    fontSize: 18,
  },
  socialLinks: {
    padding: '25px 0',
    height: 93,
  },
  socialIcon: {
    display: 'block',
    float: 'left',
    width: 44,
    height: 43,
    textIndent: -9999,
  },
  fbIcon: {
    marginRight: 20,
    backgroundImage:
      'url(https://s3.amazonaws.com/coachcheetah-assets/facebook.png)',
    '&:hover': {
      backgroundImage:
        'url(https://s3.amazonaws.com/coachcheetah-assets/facebook-hover.png)',
    },
  },
  twitterIcon: {
    backgroundImage:
      'url(https://s3.amazonaws.com/coachcheetah-assets/twitter.png)',
    '&:hover': {
      backgroundImage:
        'url(https://s3.amazonaws.com/coachcheetah-assets/twitter-hover.png)',
    },
  },
});

function Footer({ classes }) {
  return (
    <div className={classes.container}>
      <div className={classes.footer}>
        <div className={classes.footerFlex}>
          <div>
            <span className={cx(classes.text, classes.social)}>
              Follow Coach Cheetah
            </span>
            <div className={classes.socialLinks}>
              <a
                href="https://www.facebook.com/CoachCheetah"
                target="_blank"
                rel="noopener noreferrer"
                className={cx(classes.socialIcon, classes.fbIcon)}
              >
                Like Coach Cheetah
              </a>
              <a
                href="https://twitter.com/CoachCheetah"
                target="_blank"
                rel="noopener noreferrer"
                className={cx(classes.socialIcon, classes.twitterIcon)}
              >
                Follow Coach Cheetah
              </a>
            </div>
          </div>
          <span className={cx(classes.text, classes.copy)}>
            Copyright &copy; {new Date().getFullYear()} Coach Cheetah
          </span>
        </div>
      </div>
    </div>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Footer);
