import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Table, Title } from 'components/base';
import { TeamEventsContainer } from 'containers';

const TeamSeasons = ({ data, name, editable, deviceSize, inlineCaption }) => {
  const isSmall = deviceSize === 'xs';
  return (
    <Table
      title={
        <Title title="Seasons" subtitle={`Update the seasons for ${name}`} />
      }
      columns={[
        {
          title: 'Name',
          field: 'name',
        },
        {
          title: 'Created',
          field: 'created',
          render: rowData =>
            rowData ? rowData.date : format(new Date(), 'MMMM do, yyyy'),
          editable: 'never',
          cellStyle: { width: isSmall ? 100 : 250 },
        },
      ]}
      caption={`Seasons created for the team ${name}`}
      pageSize={5}
      data={data}
      search={!isSmall}
      editable={isSmall ? undefined : editable}
      detailPanel={rowData => <TeamEventsContainer {...rowData} />}
      localization={{
        body: {
          editRow: {
            deleteText:
              'Are you sure you want to delete this season? All events will be lost.',
          },
          addTooltip: 'Add Season',
          emptyDataSourceMessage: 'No seasons to display',
        },
      }}
      inlineCaption={inlineCaption}
    />
  );
};

TeamSeasons.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      created: PropTypes.number.isRequired,
      seasonId: PropTypes.string.isRequired,
    })
  ),
  editable: PropTypes.shape({
    onRowAdd: PropTypes.func.isRequired,
    onRowUpdate: PropTypes.func.isRequired,
    onRowDelete: PropTypes.func.isRequired,
  }),
};

export default TeamSeasons;
