import React from 'react';
import { AuthenticateContainer } from 'containers';

class AuthRoute extends React.Component {
  render() {
    return <AuthenticateContainer />;
  }
}

export default AuthRoute;
