import React from 'react';
import { ProfilePhone } from 'components';

// TODO: Do we need a container?

class ProfilePhoneContainer extends React.Component {
  render() {
    return <ProfilePhone />;
  }
}

export default ProfilePhoneContainer;
