const normalizeCode = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.toString().length > 5) {
    return onlyNums.slice(0, 5);
  }
  return onlyNums;
};

export default normalizeCode;
