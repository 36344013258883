import React from 'react';
import { uniqueId } from 'lodash';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default class PopoverMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      anchorId: uniqueId(),
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.renderMenuItems = this.renderMenuItems.bind(this);
    this.callHandler = this.callHandler.bind(this);
  }
  handleOpen(ev) {
    this.setState({
      anchorEl: ev.currentTarget,
    });
    ev.preventDefault();
    ev.stopPropagation();
  }
  handleClose(ev) {
    this.setState({
      anchorEl: null,
    });
    ev.preventDefault();
    ev.stopPropagation();
  }
  callHandler(ev, handler) {
    this.handleClose(ev);
    if (typeof handler === 'function') {
      handler();
    }
  }
  renderMenuItems() {
    const { menuItems } = this.props;
    const { anchorId } = this.state;
    if (React.isValidElement(menuItems)) {
      if (menuItems.props.children) {
        return React.Children.map(menuItems.props.children, child =>
          React.cloneElement(child, {
            onClick: ev => {
              this.handleClose(ev);
              child.props.onClick && child.props.onClick();
            },
          })
        );
      }
      return menuItems;
    }
    return menuItems.map(({ text, handler }) => (
      <MenuItem
        onClick={ev => this.callHandler(ev, handler)}
        key={`${anchorId}-${text}`}
      >
        {text}
      </MenuItem>
    ));
  }
  render() {
    const { anchorEl, anchorId } = this.state;

    const { children, menuMargin } = this.props;

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, {
        onClick: this.handleOpen,
        'aria-owns': anchorEl ? anchorId : undefined,
        'aria-haspopup': 'true',
      })
    );

    return (
      <React.Fragment>
        {childrenWithProps}
        <Menu
          id={anchorId}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          style={{
            // TODO: Theme styles
            margin: menuMargin ? menuMargin : 0,
            padding: 0,
          }}
          marginThreshold={30}
          MenuListProps={{
            // TODO: Theme styles
            style: {
              padding: 0,
            },
          }}
        >
          {this.renderMenuItems()}
        </Menu>
      </React.Fragment>
    );
  }
}
