import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ProfileEdit } from 'components';
import {
  updateUserProfile,
  updateProfilePicture,
} from 'redux/modules/users/userInfo';
import { geocodeLocation, setUserLocation } from 'redux/modules/geoFire';
import { addAlert } from 'redux/modules/alerts';

class ProfileEditContainer extends React.Component {
  handleUpdateProfile(values) {
    const {
      profilePic: { dataURL },
      ...formInputs
    } = values;
    let isValuesUpdated = false;
    Object.keys(formInputs).forEach(key => {
      if (formInputs[key] !== this.props.userInfo[key]) {
        isValuesUpdated = true;
      }
    });
    if (isValuesUpdated || dataURL) {
      // TODO: Only geocode if zip changed
      return this.props
        .geocodeLocation(formInputs.zip)
        .then(location => this.props.setUserLocation(location))
        .then(() =>
          this.props.updateUserProfile(formInputs, this.props.userInfo.phone)
        )
        .then(() =>
          dataURL
            ? this.props.updateProfilePicture(this.props.uid, dataURL)
            : false
        )
        .then(() => {
          this.props.addAlert('Your profile was updated.');
          return;
        })
        .catch(error => {
          // show error in form
          console.log('error', error);
        });
    } else {
      this.props.addAlert('No profile values updated.');
    }
  }
  render() {
    return (
      <ProfileEdit onUpdateProfile={this.handleUpdateProfile.bind(this)} />
    );
  }
}

ProfileEditContainer.propTypes = {
  updateUserProfile: PropTypes.func.isRequired,
  updateProfilePicture: PropTypes.func.isRequired,
  geocodeLocation: PropTypes.func.isRequired,
  setUserLocation: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  uid: PropTypes.string.isRequired,
  addAlert: PropTypes.func.isRequired,
};

function mapStateToProps({ users }) {
  const userInfo = users[users.authedId].info;
  return {
    userInfo: {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      gender: userInfo.gender,
      email: userInfo.email,
      zip: userInfo.zip,
      phone: userInfo.phone,
    },
    uid: userInfo.uid,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateUserProfile,
      updateProfilePicture,
      geocodeLocation,
      setUserLocation,
      addAlert,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileEditContainer);
