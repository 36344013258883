import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PlayingIcon from '@material-ui/icons/Check';
import NotPlayingIcon from '@material-ui/icons/Close';
import RSVPIcon from '@material-ui/icons/KeyboardArrowDown';
import { withStyles } from '@material-ui/core/styles';
import { PopoverMenu, TextButton } from 'components/base';
import { eventRSVP, eventSoon } from './eventRSVP.module.css';

// TODO: Move to theme styles?
const styles = theme => ({
  primary: {},
  icon: {
    marginRight: 0,
  },
  selected: {
    backgroundColor: theme.palette.primary.main + ' !important',
    '& $primary, & $icon': {
      color: theme.palette.common.white,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main + ' !important',
      '& $primary, & $icon': {
        color: theme.palette.common.white,
      },
    },
  },
  button: {
    margin: 0,
    marginLeft: -4,
  },
});

function EventRSVP({
  isRSVPedYes,
  isRSVPedNo,
  isThisWeek,
  onToggleRSVP,
  classes,
}) {
  let label, icon;
  if (isRSVPedYes) {
    label = 'Playing';
    icon = <PlayingIcon />;
  } else if (isRSVPedNo) {
    label = 'Not Playing';
    icon = <NotPlayingIcon />;
  } else {
    label = 'RSVP';
    icon = <RSVPIcon />;
  }
  return (
    <div className={`${eventRSVP} ${isThisWeek ? eventSoon : null}`}>
      <PopoverMenu
        menuItems={
          <React.Fragment>
            <MenuItem
              selected={isRSVPedNo}
              onClick={() => onToggleRSVP(0)}
              classes={{ selected: classes.selected }}
            >
              <ListItemIcon className={classes.icon}>
                <NotPlayingIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.primary }}
                inset
                primary="Not Playing"
              />
            </MenuItem>
            <MenuItem
              selected={isRSVPedYes}
              onClick={() => onToggleRSVP(1)}
              classes={{ selected: classes.selected }}
            >
              <ListItemIcon className={classes.icon}>
                <PlayingIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.primary }}
                inset
                primary="Playing"
              />
            </MenuItem>
          </React.Fragment>
        }
      >
        <TextButton icon={icon} className={classes.button}>
          {label}
        </TextButton>
      </PopoverMenu>
    </div>
  );
}

export default withStyles(styles)(EventRSVP);
