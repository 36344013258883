import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { InviteEdit } from 'components';
import { formatPhone } from 'helpers/utils';
import { updateInvitedPlayer, updateInvitedPhoneLookup } from 'helpers/api';
import { addAlert } from 'redux/modules/alerts';
import { store } from 'App';

class InviteEditContainer extends React.Component {
  render() {
    return (
      <InviteEdit
        isManager={this.props.isManager}
        isCoed={this.props.isCoed}
        invite={this.props.invite}
      />
    );
  }
}

InviteEditContainer.propTypes = {
  teamId: PropTypes.string.isRequired,
  isManager: PropTypes.bool.isRequired,
  isCoed: PropTypes.bool.isRequired,
  invite: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

function mapStateToProps({ teams, users }, ownProps) {
  return {
    teamId: ownProps.teamId,
    isManager: users.authedId === teams[ownProps.teamId].uid,
    isCoed: teams[ownProps.teamId].gender === 'coed',
    invite: ownProps.invite,
    onUpdate: ownProps.onUpdate,
  };
}

export function inviteEditSubmit(values) {
  const player = {
    status: values.status,
    teamId: values.teamId,
    inviteId: values.inviteId,
    rosterData: {
      phone: formatPhone(values.phone),
      gender: values.gender,
      firstName: values.firstName,
      lastName: values.lastName,
    },
  };

  return updateInvitedPlayer(player)
    .then(() => {
      if (formatPhone(values.phone) !== values.oldPhone) {
        return updateInvitedPhoneLookup({
          phone: formatPhone(values.phone),
          teamId: values.teamId,
          inviteId: values.inviteId,
          oldPhone: values.oldPhone,
        });
      }
    })
    .then(() => {
      store.dispatch(addAlert('Invite updated!'));
    })
    .catch(error => {
      store.dispatch(
        addAlert('Error updating invite. Please try again later!')
      );
    });
}

export default connect(mapStateToProps, null)(InviteEditContainer);
