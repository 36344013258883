/**
 * The phoneAuth reducer.
 * @namespace phoneAuth
 */
import auth, {
  sendVerificationCodeRequest,
  confirmVerificationCodeRequest,
  getPhoneUser,
  deletePhoneUser,
  syncTeamData,
  syncInviteData,
} from '../../helpers/phoneAuth';
import { fetchingUser, fetchingUserFailure } from './users';
import { updateUserProfile } from './users/userInfo';

const FETCHING_VERIFICATION_CODE = 'FETCHING_VERIFICATION_CODE';
const FETCHING_VERIFICATION_CODE_FAILURE = 'FETCHING_VERIFICATION_CODE_FAILURE';
const FETCHING_VERIFICATION_CODE_SUCCESS = 'FETCHING_VERIFICATION_CODE_SUCCESS';
const RESENDING_VERIFICATION_CODE = 'RESENDING_VERIFICATION_CODE';
const RESENDING_VERIFICATION_CODE_FAILURE =
  'RESENDING_VERIFICATION_CODE_FAILURE';
const RESENDING_VERIFICATION_CODE_SUCCESS =
  'RESENDING_VERIFICATION_CODE_SUCCESS';
const EDIT_PHONE_NUMBER = 'EDIT_PHONE_NUMBER';
const FETCHING_CODE_CONFIRMATION = 'FETCHING_CODE_CONFIRMATION';
const FETCHING_CODE_CONFIRMATION_FAILURE = 'FETCHING_CODE_CONFIRMATION_FAILURE';
const FETCHING_CODE_CONFIRMATION_SUCCESS = 'FETCHING_CODE_CONFIRMATION_SUCCESS';
const UNAUTH_PHONE_USER = 'UNAUTH_PHONE_USER';
const GETTING_PHONE_USER = 'GETTING_PHONE_USER';
const GETTING_PHONE_USER_FAILURE = 'GETTING_PHONE_USER_FAILURE';
const GETTING_PHONE_USER_SUCCESS = 'GETTING_PHONE_USER_SUCCESS';
const CLEAR_ERROR_MSG = 'CLEAR_ERROR_MSG';

/**
 * Action creators for the phoneAuth reducer
 * @namespace phoneAuth.actionCreators
 */

/**
 * Action to renter a phone number after a verification code is sent.
 * @memberof phoneAuth.actionCreators
 * @method editPhoneNumber
 */
export function editPhoneNumber(e) {
  e.preventDefault();
  return {
    type: EDIT_PHONE_NUMBER,
  };
}
/**
 * Action to indicate we are sending a request to SMS the user a verification code.
 * @memberof phoneAuth.actionCreators
 * @method fetchingVerificationCode
 */
function fetchingVerificationCode() {
  return {
    type: FETCHING_VERIFICATION_CODE,
  };
}
/**
 * Action to resent a new verification code (if the user didn't receive the first one).
 * @memberof phoneAuth.actionCreators
 * @method resendingVerificationCode
 */
function resendingVerificationCode() {
  return {
    type: RESENDING_VERIFICATION_CODE,
  };
}
/**
 * Action to indicate we were unable to SMS the user a verification code.
 * @memberof phoneAuth.actionCreators
 * @method fetchingVerificationCodeFailure
 * @param {string} error - The error message returned from our request.
 */
function fetchingVerificationCodeFailure(error) {
  return {
    type: FETCHING_VERIFICATION_CODE_FAILURE,
    error: 'Error sending verification code. Please try again.',
  };
}
/**
 * Action to indicate we were unable to resend SMS the user a verification code.
 * @memberof phoneAuth.actionCreators
 * @method resendingVerificationCodeFailure
 * @param {string} error - The error message returned from our request.
 */
function resendingVerificationCodeFailure(error) {
  return {
    type: RESENDING_VERIFICATION_CODE_FAILURE,
    error: 'Error sending verification code. Please try again.',
  };
}
/**
 * Action to indicate the verification code was successfuly sent via SMS.
 * @memberof phoneAuth.actionCreators
 * @method fetchingVerificationCodeSuccess
 */
function fetchingVerificationCodeSuccess(phone) {
  return {
    type: FETCHING_VERIFICATION_CODE_SUCCESS,
    phone,
  };
}
/**
 * Action to indicate the verification code was successfuly resent via SMS.
 * @memberof phoneAuth.actionCreators
 * @method resendingVerificationCodeSuccess
 */
function resendingVerificationCodeSuccess(phone) {
  return {
    type: RESENDING_VERIFICATION_CODE_SUCCESS,
    phone,
  };
}
/**
 * phoneAuth reducer's thunks
 * @namespace phoneAuth.thunks
 */
/**
 * Send verification code and dispatch actions accordingly.
 * @memberof phoneAuth.thunks
 * @method requestVerificationCode
 * @param {string} phone - The phone number the user entered. '+1' prefix is optional.
 */
export function requestVerificationCode(phone) {
  return function(dispatch) {
    dispatch(fetchingVerificationCode());
    return sendVerificationCodeRequest(phone)
      .then(({ data }) => {
        return data.sent
          ? dispatch(fetchingVerificationCodeSuccess(data.phone))
          : dispatch(fetchingVerificationCodeFailure());
      })
      .catch(error => {
        dispatch(fetchingVerificationCodeFailure(error));
      });
  };
}
/**
 * Resend verification code and dispatch actions accordingly.
 * @memberof phoneAuth.thunks
 * @method resendVerificationCode
 * @param {string} phone - The phone number the user entered. '+1' prefix is optional.
 */
export function resendVerificationCode(phone) {
  return function(dispatch) {
    dispatch(resendingVerificationCode());
    return sendVerificationCodeRequest(phone)
      .then(({ data }) => {
        return data.sent
          ? dispatch(resendingVerificationCodeSuccess(data.phone))
          : dispatch(resendingVerificationCodeFailure());
      })
      .catch(error => dispatch(fetchingVerificationCodeFailure(error)));
  };
}

function fetchingCodeConfirmation() {
  return {
    type: FETCHING_CODE_CONFIRMATION,
  };
}

function fetchingCodeConfirmationFailure(error) {
  return {
    type: FETCHING_CODE_CONFIRMATION_FAILURE,
    error: error || 'Error confirming verification code. Please try again.',
  };
}

function fetchingCodeConfirmationSuccess() {
  return {
    type: FETCHING_CODE_CONFIRMATION_SUCCESS,
  };
}
/**
 * Action to remove local phone authed data
 * @method unauthPhoneUser
 * @memberof phoneAuth.actionCreators
 */
export function unauthPhoneUser() {
  return {
    type: UNAUTH_PHONE_USER,
  };
}
/**
 * Clear error messages (i.e. resubmitting form)
 * @method clearErrorMsg
 * @memberof phoneAuth.actionCreators
 */
export function clearErrorMsg() {
  return {
    type: CLEAR_ERROR_MSG,
  };
}

function handleConfirmCodeResponse(dispatch, data) {
  if (!data.token || !data.phone) {
    return dispatch(fetchingCodeConfirmationFailure(data.errorMessage));
  }
  dispatch(fetchingCodeConfirmationSuccess());
  return data;
}

export function confirmCode(phone, code) {
  return function(dispatch) {
    return confirmVerificationCodeRequest(phone, code)
      .then(({ data }) => handleConfirmCodeResponse(dispatch, data))
      .catch(error => console.log(error));
  };
}
/**
 * Querying firebase for a phone user
 * @method gettingPhoneUser
 * @memberof phoneAuth.actionCreators
 */
function gettingPhoneUser() {
  return {
    type: GETTING_PHONE_USER,
  };
}
/**
 * Querying firebase for a phone user was successful
 * @method gettingPhoneUserSuccess
 * @memberof phoneAuth.actionCreators
 */
function gettingPhoneUserSuccess() {
  return {
    type: GETTING_PHONE_USER_SUCCESS,
  };
}
/**
 * Querying firebase for a phone user failed
 * @method gettingPhoneUserFailure
 * @memberof phoneAuth.actionCreators
 */
function gettingPhoneUserFailure() {
  return {
    type: GETTING_PHONE_USER_FAILURE,
  };
}

/**
 * Checks if there is a phone authed user with a certain phone number. This
 * thunk is used to check if there is a phone auth user that matches a fb auth
 * users number. If so, we merge the two accounts.
 * @memberof phoneAuth.thunks
 * @method checkForExistingPhoneUser
 * @param {string} phone - The phone number to search for. Format should
 * match +12069148313.
 */
export function checkForExistingPhoneUser(authId, phone) {
  return function(dispatch) {
    dispatch(gettingPhoneUser());
    getPhoneUser(phone)
      .then(user => {
        if (user && user.profileComplete) {
          return dispatch(updateUserProfile(user, phone))
            .then(() => syncTeamData(phone, user, authId))
            .then(() => syncInviteData(phone, user, authId))
            .then(() => deletePhoneUser(phone))
            .then(() => dispatch(gettingPhoneUserSuccess()))
            .then(() => phone)
            .catch(error => {
              console.log('error', error);
            });
        }
        if (user) {
          return syncTeamData(phone, user, authId)
            .then(() => syncInviteData(phone, user, authId))
            .then(() => deletePhoneUser(phone))
            .then(() => dispatch(gettingPhoneUserSuccess()))
            .then(() => phone)
            .catch(error => {
              console.log('error', error);
            });
        }
        dispatch(gettingPhoneUserSuccess());
        return phone;
      })
      .catch(error => dispatch(gettingPhoneUserFailure()));
  };
}

export function confirmCodeAndHandleAuth(phone, code) {
  return function(dispatch) {
    dispatch(fetchingCodeConfirmation());
    return confirmVerificationCodeRequest(phone, code)
      .then(({ data }) => handleConfirmCodeResponse(dispatch, data))
      .then(({ token }) => {
        // if no token returned, thus an error occured
        if (!token) {
          return null;
        }
        // dispatch fetching user, but don't handle success here
        // we will handle success once user is authed.
        dispatch(fetchingUser());
        return auth(token).catch(error => dispatch(fetchingUserFailure()));
      })
      .catch(error => dispatch(fetchingCodeConfirmationFailure()));
  };
}

const initialState = {
  phoneText: '',
  error: '',
  isFetchingCode: false,
  isResendingCode: false,
  codeSent: false,
  codeText: '',
  isFetchingConfirmation: false,
  codeConfirmed: false,
  phone: '',
};

export default function phoneAuth(state = initialState, action) {
  switch (action.type) {
    case FETCHING_VERIFICATION_CODE:
      return {
        ...state,
        isFetchingCode: true,
      };
    case FETCHING_VERIFICATION_CODE_FAILURE:
      return {
        ...state,
        isFetchingCode: false,
        error: action.error,
      };
    case FETCHING_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        isFetchingCode: false,
        codeSent: true,
        error: '',
        phone: action.phone,
      };
    case RESENDING_VERIFICATION_CODE:
      return {
        ...state,
        isResendingCode: true,
      };
    case RESENDING_VERIFICATION_CODE_FAILURE:
      return {
        ...state,
        isResendingCode: false,
        error: action.error,
        codeSent: false,
      };
    case RESENDING_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        isResendingCode: false,
        codeSent: true,
      };
    case EDIT_PHONE_NUMBER:
      return {
        ...state,
        isFetchingCode: false,
        codeSent: false,
        codeConfirmed: false,
        codeText: '',
        error: '',
      };
    case FETCHING_CODE_CONFIRMATION:
      return {
        ...state,
        isFetchingConfirmation: true,
        error: '',
      };
    case FETCHING_CODE_CONFIRMATION_FAILURE:
      return {
        ...state,
        isFetchingConfirmation: false,
        codeConfirmed: false,
        error: action.error,
      };
    case FETCHING_CODE_CONFIRMATION_SUCCESS:
      return {
        ...state,
        isFetchingConfirmation: false,
        codeConfirmed: true,
        error: '',
      };
    case CLEAR_ERROR_MSG:
      return {
        ...state,
        error: '',
      };
    case UNAUTH_PHONE_USER:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
