import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => {
  return {
    root: {
      background: '#FFF',
      borderRadius: 0,
      border: 0,
      height: 36,
      padding: '0 16px 0 16px',
      '&:hover': {
        background: 'rgb(254, 248, 236)',
      },
    },
    sizeLarge: {
      height: 42,
      padding: '0 20px 0 20px',
    },
    label: {
      textTransform: 'capitalize',
      color: theme.palette.secondary.main,
    },
  };
};

const StyledButton = withStyles(styles)(Button);

const WhiteButton = ({ children, to, ...restOfProps }) => {
  return (
    <StyledButton component={to ? Link : 'button'} to={to} {...restOfProps}>
      {children}
    </StyledButton>
  );
};

WhiteButton.displayName = 'WhiteButton';
export default WhiteButton;
