import React from 'react';
import { titleWrapper, titleText, subtitleText } from './styles.module.css';

const Title = ({ title, subtitle }) => (
  <div className={titleWrapper}>
    <div className={titleText}>{title}</div>
    <div className={subtitleText}>{subtitle}</div>
  </div>
);

export default Title;
